import { parseJwt } from "../common/utils";

export default function authRoleAndPermissions() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token && new Date(user.expires_in) > Date.now()) {
    const identity = parseJwt(user.token);
    return {
      role: identity.role,
      permissions: identity.Permission,
    };
  } else {
    return null;
  }
}
