import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';
import { toISOStringWithTimezone } from '../../../../common/utils';

class AddMoneyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        cash: '',
        transactionDate: toISOStringWithTimezone(new Date()),
        note: ''
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    addMoney(e) {
        e.preventDefault();
        let { cash, transactionDate, note } = this.state;
        let addMoneyRequest = {
            portfolioId: this.props.portfolioId,
            userId: this.props.userId,
            transactionDate: transactionDate,
            cash: cash,
            note: note
        };
        this.props.handleSubmit(addMoneyRequest);
        this.setState(this.initialState);
    }

    render() {
        let { open, toggle, userName } = this.props;
        let { transactionDate, cash, note } = this.state;
        return (

            <Modal isOpen={open} fade={false} toggle={toggle}>
                <ModalHeader toggle={toggle}>Thêm Tiền</ModalHeader>
                <Form onSubmit={(e) => this.addMoney(e)}>
                    <ModalBody>
                        <FormGroup>
                            <Label>Người Dùng</Label>
                            <Input readOnly type="text" id="userName" name="userName" value={userName} />
                        </FormGroup>
                        <FormGroup>
                                <Label for="price">Giá Trị</Label>
                                <InputGroup>
                                    <Input onChange={this.handleInputChange} value={cash} type="number" id="cash" min="1" name="cash" />
                                    <InputGroupText>đ</InputGroupText>
                                </InputGroup>
                            </FormGroup>
                        <FormGroup>
                            <Label for="transactionDate">Ngày Giao Dịch</Label>
                            <Input onChange={this.handleInputChange} value={transactionDate} type="datetime-local" required step="1" id="transactionDate" name="transactionDate" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="note">Ghi Chú</Label>
                            <Input onChange={this.handleInputChange} value={note} type="textarea" name="note" id="note" />
                        </FormGroup>
                    </ModalBody >
                    <ModalFooter>
                        <button class="btn btn-primary mb-2 me-2" type="submit">Thêm</button>
                        <button class="btn btn-secondary mb-2 me-2" type="button" onClick={toggle}>Thoát</button>
                    </ModalFooter>
                </Form>
            </Modal >
        );
    }
}

export default AddMoneyModal;