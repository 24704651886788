import React, { Fragment } from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import CustomerPortfolioService from '../../../../services/customer.portfolio.service';
import {
    Row, Col, Card, CardBody, CardFooter, DropdownToggle,
    CardHeader, Button, UncontrolledButtonDropdown, DropdownMenu, DropdownItem
} from 'reactstrap';
import LineChart from '../../../Shared/Charts/LineChart';
import PieChart from '../../../Shared/Charts/PieChart';
import MultiLineChart from '../../../Shared/Charts/MultiLineChart';
import CensoredInfo from '../../../../common/censored-info';
import TimeLineChart from '../../../Shared/Charts/TimeLineChart';

class CustomerPortfolioOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navccqData: null,
            navccqTab: '',
            growthRateTab: '',
            phanBoTab: '',
            cophieuTab: '',
            growthRateData: null,
            allocationData: null,
            stockData: null,
            assetHistories: null,
            assetHistoriesTab: '2',
            assetHistoryTimes: null,
            stockPeriod: '',
            allocationPeriod: '',
            stockDropdown: '',
            allocationDropdown: '',
            error: "",
            totalDeposit: '',
            totalWithdraw: '',
            profitValue: '',
            profitRate: '',
            hasLoss: false,
            cash: '',
            totalValue: ''
        };
    }

    updateNAVCCQ(i) {
        new CustomerPortfolioService().navCCQChart(this.props.match.params.portfolioId, i).then(
            response => {
                if (response.data) {
                    this.setState({
                        navccqData: response.data,
                        navccqTab: i
                    });
                }
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    updateGrowthRate(i) {
        new CustomerPortfolioService().growthRateChart(this.props.match.params.portfolioId, i).then(
            response => {
                if (response.data) {
                    this.setState({
                        growthRateData: response.data,
                        growthRateTab: i
                    });
                }
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    updateAllocationChart(historyId) {
        new CustomerPortfolioService().allocationChart(this.props.match.params.portfolioId, historyId).then(
            response => {
                if (response.data) {
                    var allocationPeriod = historyId === '' ? "Hiện Tại" : this.state.assetHistoryTimes[historyId];
                    this.setState({
                        allocationData: response.data.item1,
                        allocationPeriod: allocationPeriod,
                        allocationDropdown: historyId
                    });
                }
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    updateStockChart(historyId) {
        new CustomerPortfolioService().allocationChart(this.props.match.params.portfolioId, historyId).then(
            response => {
                if (response.data) {
                    var stockPeriod = historyId === '' ? "Hiện Tại" : this.state.assetHistoryTimes[historyId];
                    this.setState({
                        stockData: response.data.item2,
                        stockPeriod: stockPeriod,
                        stockDropdown: historyId
                    });
                }
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    updateAssetHistories(i) {
        new CustomerPortfolioService().assetHistoriesChart(this.props.match.params.portfolioId, i).then(
            response => {
                if (response.data) {
                    this.setState({
                        assetHistories: response.data,
                        assetHistoriesTab: i
                    });
                }
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    componentDidMount() {
        new CustomerPortfolioService().overview(this.props.match.params.portfolioId).then(
            response => {
                if (response.data) {
                    var firstPeriod = response.data.assetHistoriesTime ? Object.values(response.data.assetHistoriesTime)[0] : 'Unknown';
                    var firstPeriodKey = response.data.assetHistoriesTime ? Object.keys(response.data.assetHistoriesTime)[0] : 'Unknown';
                    this.setState({
                        navccqData: response.data.navccqData,
                        allocationData: response.data.allocationData,
                        stockData: response.data.stockData,
                        growthRateData: response.data.growthRateData,
                        navccqTab: '1',
                        growthRateTab: '1',
                        stockPeriod: firstPeriod,
                        allocationDropdown: firstPeriodKey,
                        stockDropdown: firstPeriodKey,
                        allocationPeriod: firstPeriod,
                        assetHistoryTimes: response.data.assetHistoriesTime,
                        totalDeposit: response.data.totalDeposit,
                        totalWithdraw: response.data.totalWithdraw,
                        profitValue: response.data.profitValue,
                        profitRate: response.data.profitRate,
                        hasLoss: response.data.hasLoss,
                        cash: response.data.cash,
                        totalValue: response.data.totalValue,
                        assetHistories: response.data.assetHistoriesData,
                        assetHistoriesTab: '2'
                    });
                }
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    render() {
        let { censored } = this.props;
        let {
            navccqData,
            allocationData,
            stockData,
            growthRateData,
            navccqTab,
            growthRateTab,
            stockPeriod,
            allocationPeriod,
            assetHistoryTimes,
            stockDropdown,
            allocationDropdown,
            totalDeposit,
            totalWithdraw,
            cash,
            profitRate,
            profitValue,
            totalValue,
            assetHistories,
            assetHistoriesTab,
            hasLoss } = this.state;
        return (
            <Fragment>
                <TransitionGroup>
                    <CSSTransition
                        component="div"
                        className="TabsAnimation"
                        appear={true}
                        timeout={0}
                        enter={false}
                        exit={false}>
                        <Row>
                            <Col lg="6">
                                <Card className='widget-content bg-asteroid text-white mb-3'>
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Số tiền đã nạp</div>
                                            <div class="widget-heading">Số tiền đã rút</div>
                                            <div class="widget-heading">Số tiền có hiện tại</div>
                                        </div>
                                        <div class="widget-content-right">
                                            <div>
                                                <b><CensoredInfo censored={censored} content={totalDeposit} /></b>
                                            </div>
                                            <div>
                                                <b><CensoredInfo censored={censored} content={totalWithdraw} /></b>
                                            </div>
                                            <div>
                                                <b><CensoredInfo censored={censored} content={totalValue} /></b>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg="6">
                                <Card className={'widget-content text-white mb-3 ' + (hasLoss ? 'bg-love-kiss' : 'bg-grow-early')}>
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left">
                                            <div class="widget-heading" style={{ fontSize: '1.4rem' }}>Lợi Nhuận</div>
                                        </div>
                                        <div class="widget-content-right">
                                            <div style={{ fontSize: '1.8rem' }}>
                                                <b>
                                                    <CensoredInfo censored={censored} content={profitValue} />
                                                </b>
                                            </div>
                                            <div>
                                                <span><CensoredInfo censored={censored} content={profitRate} /></span>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            {assetHistories && (
                                <>
                                    <Col lg="12">
                                        <Card className="main-card mb-3">
                                            <CardHeader className="card-header-tab">
                                                <div className="card-header-title">
                                                    <i className="header-icon lnr-pie-chart icon-gradient bg-plum-plate"> </i>
                                                    Tài Sản
                                                </div>
                                            </CardHeader>
                                            <CardBody>
                                                {assetHistories &&
                                                    <TimeLineChart label={assetHistories.label} dataLabels={assetHistories.dataLabels.map(x => new Date(x))} values={assetHistories.values} notes={assetHistories.notes} />
                                                }
                                            </CardBody>
                                            <CardFooter>
                                                <div class="btn-actions-pane-right">
                                                    <Button outline
                                                        className={"border-0 btn-pill btn-wide btn-transition " + classnames({ active: assetHistoriesTab === '1' })}
                                                        color="primary" onClick={() => {
                                                            this.updateAssetHistories('1');
                                                        }}>3 tháng</Button>
                                                    <Button outline
                                                        className={"ms-1 btn-pill btn-wide border-0 btn-transition " + classnames({ active: assetHistoriesTab === '2' })}
                                                        color="primary" onClick={() => {
                                                            this.updateAssetHistories('2');
                                                        }}>1 năm</Button>
                                                    <Button outline
                                                        className={"ms-1 btn-pill btn-wide border-0 btn-transition " + classnames({ active: assetHistoriesTab === '3' })}
                                                        color="primary" onClick={() => {
                                                            this.updateAssetHistories('3');
                                                        }}>3 năm</Button>
                                                    <Button outline
                                                        className={"ms-1 btn-pill btn-wide border-0 btn-transition " + classnames({ active: assetHistoriesTab === '4' })}
                                                        color="primary" onClick={() => {
                                                            this.updateAssetHistories('4');
                                                        }}>Tất Cả</Button>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </>
                            )}
                            <Col lg="6">
                                <Card className="main-card mb-3">
                                    <CardHeader className="card-header-tab">
                                        <div className="card-header-title">
                                            <i className="header-icon lnr-chart-bars icon-gradient bg-grow-early"> </i>
                                            NAVCCQ
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        {navccqData &&
                                            <LineChart label={navccqData.label} dataLabels={navccqData.dataLabels} values={navccqData.values} />
                                        }
                                    </CardBody>
                                    <CardFooter>
                                        <div class="btn-actions-pane-right">
                                            <Button outline
                                                className={"border-0 btn-pill btn-wide btn-transition " + classnames({ active: navccqTab === '1' })}
                                                color="primary" onClick={() => {
                                                    this.updateNAVCCQ('1');
                                                }}>3 tháng</Button>
                                            <Button outline
                                                className={"ms-1 btn-pill btn-wide border-0 btn-transition " + classnames({ active: navccqTab === '2' })}
                                                color="primary" onClick={() => {
                                                    this.updateNAVCCQ('2');
                                                }}>1 năm</Button>
                                            <Button outline
                                                className={"ms-1 btn-pill btn-wide border-0 btn-transition " + classnames({ active: navccqTab === '3' })}
                                                color="primary" onClick={() => {
                                                    this.updateNAVCCQ('3');
                                                }}>3 năm</Button>
                                            <Button outline
                                                className={"ms-1 btn-pill btn-wide border-0 btn-transition " + classnames({ active: navccqTab === '4' })}
                                                color="primary" onClick={() => {
                                                    this.updateNAVCCQ('4');
                                                }}>Tất Cả</Button>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="6">
                                <Card className="main-card mb-3">
                                    <CardHeader>
                                        <div className="card-header-title">
                                            <i className="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                                            Tăng Trưởng
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        {growthRateData &&
                                            <MultiLineChart chartData={growthRateData} />
                                        }
                                    </CardBody>
                                    <CardFooter>
                                        <div class="btn-actions-pane-right">
                                            <Button outline
                                                className={"border-0 btn-pill btn-wide btn-transition " + classnames({ active: growthRateTab === '1' })}
                                                color="primary" onClick={() => {
                                                    this.updateGrowthRate('1');
                                                }}>3 tháng</Button>
                                            <Button outline
                                                className={"ms-1 btn-pill btn-wide border-0 btn-transition " + classnames({ active: growthRateTab === '2' })}
                                                color="primary" onClick={() => {
                                                    this.updateGrowthRate('2');
                                                }}>1 năm</Button>
                                            <Button outline
                                                className={"ms-1 btn-pill btn-wide border-0 btn-transition " + classnames({ active: growthRateTab === '3' })}
                                                color="primary" onClick={() => {
                                                    this.updateGrowthRate('3');
                                                }}>3 năm</Button>
                                            <Button outline
                                                className={"ms-1 btn-pill btn-wide border-0 btn-transition " + classnames({ active: growthRateTab === '4' })}
                                                color="primary" onClick={() => {
                                                    this.updateGrowthRate('4');
                                                }}>Tất Cả</Button>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="6">
                                <Card className="main-card mb-3">
                                    <CardHeader className="card-header-tab">
                                        <div className="card-header-title">
                                            <i className="header-icon lnr-briefcase icon-gradient bg-mixed-hopes"> </i>
                                            Phân Bổ
                                        </div>
                                        <div class="btn-actions-pane-right">
                                            <UncontrolledButtonDropdown className="mb-2 me-2">
                                                <DropdownToggle caret color="primary">
                                                    {allocationPeriod}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {assetHistoryTimes &&
                                                        Object.keys(assetHistoryTimes).map(key => (
                                                            <DropdownItem active={key === allocationDropdown} onClick={() => this.updateAllocationChart(key)}>{assetHistoryTimes[key]}</DropdownItem>
                                                        ))}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        {allocationData &&
                                            <PieChart dataLabels={allocationData.dataLabels} values={allocationData.values} />
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6">
                                <Card className="main-card mb-3">
                                    <CardHeader className="card-header-tab">
                                        <div className="card-header-title">
                                            <i className="header-icon lnr-pie-chart icon-gradient bg-plum-plate"> </i>
                                            Cổ Phiếu
                                        </div>
                                        <div class="btn-actions-pane-right">
                                            <UncontrolledButtonDropdown className="mb-2 me-2">
                                                <DropdownToggle caret color="primary">
                                                    {stockPeriod}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {assetHistoryTimes &&
                                                        Object.keys(assetHistoryTimes).map(key => (
                                                            <DropdownItem active={key === stockDropdown} onClick={() => this.updateStockChart(key)}>{assetHistoryTimes[key]}</DropdownItem>
                                                        ))}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        {stockData &&
                                            <PieChart dataLabels={stockData.dataLabels} values={stockData.values} />
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}

export default withRouter(CustomerPortfolioOverview);