import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, CardHeader, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import AppPagination from '../../../../Blocks/AppPagination';
import CustomerPortfolioService from '../../../../services/customer.portfolio.service';
import AddDepositRequestModal from './AddDepositRequestModal';

class CustomerPortfolioDepositRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requests: null,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false,
            imageModal: false,
            currentImageId: '',
            errorModalOpen: false,
            error: '',
            addDepositRequestModalOpen: false,
            isLoading: true
        };
        this.addRequest = this.addRequest.bind(this);
    }

    getUserToken() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token) {
            return user.token;
        }

        return '';
    }

    toggleErrorModal() {
        this.setState({
            errorModalOpen: !this.state.errorModalOpen
        });
    }

    toggleAddDepositRequestModal(){
        this.setState({
            addDepositRequestModalOpen: !this.state.addDepositRequestModalOpen
        });
    }

    openImageModal(imageId) {
        this.setState({
            currentImageId: imageId,
            imageModal: true
        });
    }

    toggleImageModal() {
        this.setState({
            imageModal: !this.state.imageModal
        });
    }

    componentDidMount() {
        this.loadDepositRequests();
    }

    loadDepositRequests() {
        let { match } = this.props;
        let page = parseInt(match.params.page) ?? 1;
        new CustomerPortfolioService().depositRequests(match.params.portfolioId, page).then(
            response => {
                this.setState({
                    isLoading: false,
                    requests: response.data.items,
                    totalPages: response.data.totalPages,
                    hasPreviousPage: response.data.hasPreviousPage,
                    hasNextPage: response.data.hasNextPage
                });
            },
            error => {
                this.setState({
                    isLoading: false,
                    error: error.message
                })
            }
        );
    }

    addRequest(request) {
        if (request){
            this.setState({
                isLoading: true
            });
            var formData = new FormData();
            formData.append('value', request.value);
            formData.append('currency', request.currency);
            formData.append('portfolioId', this.props.match.params.portfolioId);
            formData.append('depositDate', request.depositDate);
            formData.append('image', request.image);

            new CustomerPortfolioService().addDepositRequest(formData).then(
                response => {
                    if (response.data.success) {
                        this.props.updateOverview();
                        this.loadDepositRequests();
                        this.setState({
                            errorModalOpen: false,
                            addDepositRequestModalOpen: false,
                            isLoading: false
                        });
                    } else {
                        this.setState({
                            error: response.data.message,
                            errorModalOpen: true,
                            addDepositRequestModalOpen: false,
                            isLoading: false
                        });
                    }
                },
                error => {
                    this.setState({
                        error: error.message,
                        errorModalOpen: true,
                        addDepositRequestModalOpen: false,
                        isLoading: false
                    })
                }
            );
        }
    }

    render() {
        let { match } = this.props;
        let parentUrl = match.url.substring(0, match.url.lastIndexOf("/"));
        let page = parseInt(match.params.page) ?? 1;
        let { requests, hasNextPage, hasPreviousPage, totalPages, imageModal, currentImageId, isLoading, addDepositRequestModalOpen } = this.state;
        return (
            <Fragment>
                <TransitionGroup>
                    <CSSTransition
                        component="div"
                        className="TabsAnimation"
                        appear={true}
                        timeout={0}
                        enter={false}
                        exit={false}>
                        <Row>
                            { isLoading ? (
                                <div className="loader-container">
                                    <div className="loader-container-inner">
                                        <h6 className="mt-5">
                                            Hệ thống đang xử lý ...
                                        </h6>
                                    </div>
                                </div>
                            ) : (
                                <Col lg="12">
                                    <Card className="main-card mb-3">
                                        <CardHeader>
                                            <div className="card-header-title">
                                                <i className="header-icon lnr-enter-down icon-gradient bg-plum-plate"> </i>
                                                Yêu Cầu Xác Nhận Nạp Tiền
                                            </div>
                                            <div class="btn-actions-pane-right">
                                                <button class="btn btn-primary mb-2 me-2" onClick={() => this.toggleAddDepositRequestModal()}>Thêm Yêu Cầu</button>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <div class="table-responsive">
                                                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">Tiền Nạp</th>
                                                            <th class="text-center">Ngày Nạp</th>
                                                            <th class="text-center">Hình Ảnh</th>
                                                            <th class="text-center">Tình Trạng</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {requests && requests.map(r => (
                                                            <tr>
                                                                <td class="text-center">
                                                                    {r.value}
                                                                </td>
                                                                <td class="text-center">
                                                                    {r.date}
                                                                </td>
                                                                <td class="text-center">
                                                                    <a onClick={() => this.openImageModal(r.imageId)}>
                                                                        <img src={`/Image/Index/${r.imageId}?token=${this.getUserToken()}`} width="250" height="100" />
                                                                    </a>
                                                                </td>
                                                                <td class="text-center">
                                                                    <div className={'badge ' + r.badgeStatus}>
                                                                        {r.status}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                        <CardFooter className='justify-content-center'>
                                            <AppPagination totalPages={totalPages} parentUrl={parentUrl} page={page} hasNextPage={hasNextPage} hasPreviousPage={hasPreviousPage} />
                                        </CardFooter>
                                        <Modal isOpen={imageModal} toggle={() => this.toggleImageModal()}>
                                            <ModalHeader toggle={() => this.toggleImageModal()}>Hình Ảnh</ModalHeader>
                                            <ModalBody>
                                                <img className='img-fluid' src={`/Image/Index/${currentImageId}?token=${this.getUserToken()}`} />
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="link" onClick={() => this.toggleImageModal()}>Tắt</Button>
                                            </ModalFooter>
                                        </Modal>
                                        <AddDepositRequestModal handleSubmit={this.addRequest} open={addDepositRequestModalOpen} toggle={() => this.toggleAddDepositRequestModal()} />
                                        <Modal isOpen={this.state.errorModalOpen} fade={false} toggle={() => this.toggleErrorModal()}>
                                            <ModalHeader className='text-danger' toggle={() => this.toggleErrorModal()}><i class="lnr-warning">&nbsp;</i>Lỗi</ModalHeader>
                                            <ModalBody>
                                                {this.state.error}
                                            </ModalBody>
                                        </Modal>
                                    </Card>
                                </Col>)}
                        </Row>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}

export default withRouter(CustomerPortfolioDepositRequests);

