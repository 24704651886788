import React, { Fragment } from "react";
import { Route, withRouter } from "react-router-dom";

import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import AppFooter from "../../Layout/AppFooter";
import PrivateRoute from "../../common/private-route";
import LinksListing from "./Listing";

const Links = ({ match }) => (
  <Fragment>
    <AppHeader />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <PrivateRoute exact path={`${match.path}`} component={LinksListing} />
        </div>
        <AppFooter />
      </div>
    </div>
  </Fragment>
);

export default withRouter(Links);
