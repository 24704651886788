import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

class TimeLineChart extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.dataLabels && this.props.values) {
      let chartData = {
        labels: this.props.dataLabels,
        datasets: [
          {
            label: this.props.label,
            fill: true,
            backgroundColor: '#ffb1c1',
            borderColor: '#ed0f51',
            data: this.props.values,
            lineTension: 0.5
          }
        ]
      };
      const notes = this.props.notes;
      let timeScalesOption = {
        scales: {
          x: {
            type: "time",
            time: {
              unit: 'month',
              displayFormats: {
                month: 'MM/yyyy'
              },
              tooltipFormat: 'dd/MM/yyyy'
            }
          }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    footer: function(tooltipItems){
                        if (tooltipItems.length > 0) {
                            var tooltipItem = tooltipItems[0];
                            return notes[tooltipItem.dataIndex];
                        }
                        return null;
                    }
                }
            }
        }
      };

      return (
        <div>
          <Line data={chartData} options={timeScalesOption} />
        </div>
      );
    }

    return (
      <></>
    );
  }
}

export default TimeLineChart;