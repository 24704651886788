import React, { Fragment } from "react";
import { CardBody, Row, Col, Card, CardHeader } from "reactstrap";
import MarketService from "../../../../services/market.service";
import LineChart from "../../../Shared/Charts/LineChart";

class MarketChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: []
        }
    }

    componentDidMount() {
        this.loadChartData();
    }

    loadChartData() {
        new MarketService().chart(this.props.match.params.marketId).then(
            response => {
                if (response.data) {
                    this.setState({
                        chartData: response.data
                    })
                }
            },
            error => {
                alert(error);
            }
        )
    }

    render() {
        const { chartData } = this.state;
        return (
            <Fragment>
                <Row>
                    <Col lg="12">
                        <Card className="main-card mb-3">
                            <CardHeader className="card-header-tab">
                                <div className="card-header-title">
                                    Biểu đồ
                                </div>
                            </CardHeader>
                            <CardBody>
                                {chartData &&
                                    <LineChart label={chartData.label} dataLabels={chartData.dataLabels} values={chartData.values} />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default MarketChart;