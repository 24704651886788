import React, {Fragment} from 'react';
import { Route, withRouter } from 'react-router-dom';

import AppHeader from '../../Layout/AppHeader/';
import AppSidebar from '../../Layout/AppSidebar/';
import AppFooter from '../../Layout/AppFooter/';
import PortfolioListing from './Listing';
import PortfolioDetail from './Details';
import PrivateRoute from '../../common/private-route';
import { authRoles } from '../../utils/constants';

const Portfolio = ({match}) => (
    <Fragment>
        <AppHeader/>
        <div className="app-main">
            <AppSidebar/>  
            <div className="app-main__outer">
                <div className="app-main__inner">
                    <PrivateRoute roles={[authRoles.ADMIN]} exact path={`${match.path}`} component={PortfolioListing}/>
                    <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/:portfolioId`}>
                        <PortfolioDetail/>
                    </PrivateRoute>
                </div>
                <AppFooter/>
            </div>
        </div>
    </Fragment>
)

export default withRouter(Portfolio);