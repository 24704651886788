import React, { Fragment } from "react";
import { Route, Redirect, NavLink } from "react-router-dom";
import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Button,
  NavItem,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
} from "reactstrap";

import { toast, Bounce } from "react-toastify";

import {
  faCalendarAlt,
  faAngleDown,
  faBell,
  faMailForward,
  faBars,
  faClock,
  faLink,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-toastify/dist/ReactToastify.css";

import avatar1 from "../../../assets/utils/images/avatars/1.jpg";
import ContactUsService from "../../../services/contactus.service";
class SupportNotificationBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets: [],
    };

    this.loadTickets = this.loadTickets.bind(this);
  }

  componentDidMount() {
    this.loadTickets();
  }

  loadTickets() {
    new ContactUsService().listTopTicketWaitingForSupport("me").then(
      (response) => {
        this.setState({
          tickets: response.data,
        });
      },
      (error) => {
        this.setState({
          tickets: [],
          error: error.message,
        });
      }
    );
  }

  notify2 = () =>
    (this.toastId = toast(
      "You don't have any new items in your calendar for today! Go out and play!",
      {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "success",
      }
    ));

  convertToLocalTime = (utcDateTimeString) => {
    const date = new Date(utcDateTimeString);
    return this.formatDate(date);
  };

  formatDate(date) {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join("-") +
      " " +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  render() {
    let { tickets } = this.state;
    let count = tickets.rowCount > 99 ? "99+" : tickets.rowCount;
    return (
      <Fragment>
        <UncontrolledButtonDropdown>
          <DropdownToggle
            color="link"
            className="p-0 fa-stack"
            {...(tickets.rowCount > 0 && { "data-count": count })}
          >
            <FontAwesomeIcon className="ms-2 opacity-8" icon={faBell} />
          </DropdownToggle>
          <DropdownMenu end className="rm-pointers dropdown-menu-lg">
            <Nav vertical>
              {tickets &&
                tickets.results &&
                tickets.results.map((ticket, i) => {
                  return (
                    <NavItem>
                      <NavLink to={`/contactus/tickets/` + ticket.id}>
                        <p>
                          <FontAwesomeIcon
                            className="ms-2 opacity-8"
                            icon={faMailForward}
                          />{" "}
                          &nbsp;
                          {ticket.emailAddress}
                          <br />
                          <FontAwesomeIcon
                            className="ms-2 opacity-8"
                            icon={faBars}
                          />{" "}
                          &nbsp;
                          {ticket.content && (
                            <>{ticket.content.substring(0, 50)}</>
                          )}
                          <br />
                          <FontAwesomeIcon
                            className="ms-2 opacity-8"
                            icon={faClock}
                          />{" "}
                          &nbsp;
                          {ticket.createAt && (
                            <>{this.convertToLocalTime(ticket.createAt)}</>
                          )}
                        </p>
                        <hr />
                      </NavLink>
                    </NavItem>
                  );
                })}
              <NavItem>
                <NavLink to={`/contactus/tickets`}>
                  <FontAwesomeIcon className="ms-2 opacity-8" icon={faLink} />{" "}
                  Xem thêm
                </NavLink>
              </NavItem>
            </Nav>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </Fragment>
    );
  }
}

export default SupportNotificationBox;
