import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import AppHeader from '../../Layout/AppHeader/';
import AppSidebar from '../../Layout/AppSidebar/';
import AppFooter from '../../Layout/AppFooter/';
import PortfolioListing from './Listing';
import PortfolioDetail from './Details';
import PrivateRoute from '../../common/private-route';


const CustomerPortfolio = ({ match }) => (
    <Fragment>
        <AppHeader />
        <div className="app-main">
            <AppSidebar />
            <div className="app-main__outer">
                <div className="app-main__inner">
                    <PrivateRoute exact path={`${match.path}`} component={PortfolioListing}/>
                    <PrivateRoute path={`${match.path}/:portfolioId`}>
                        <PortfolioDetail/>
                    </PrivateRoute>
                </div>
                <AppFooter/>
            </div>
        </div>
    </Fragment>
)

export default withRouter(CustomerPortfolio);