import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import RoleService from "../../../services/role.service";

class ChangeRoleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.loadRoles = this.loadRoles.bind(this);
  }

  initialState = {
    userId: this.props.userIdToBeChangedRole,
    roleName: this.props.roleName,
  };

  componentDidMount() {
    this.loadRoles();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.roleName != this.props.roleName) {
      this.setState({
        roleName: this.props.roleName,
      });
    }
    if (prevState.userId != this.props.userIdToBeChangedRole) {
      this.setState({
        userId: this.props.userIdToBeChangedRole,
      });
    }
  }

  loadRoles() {
    new RoleService().list().then(
      (response) => {
        this.setState({
          roles: response.data,
          error: "",
        });
      },
      (error) => {
        this.setState({
          roles: [],
        });
      }
    );
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    console.log(this.state);
  }

  changeRole(e) {
    e.preventDefault();
    this.props.handleSubmit(this.state);
  }

  render() {
    let { open, toggle } = this.props;
    let { roles, roleName } = this.state;
    return (
      <>
        <Modal isOpen={open} fade={false} toggle={toggle}>
          <ModalHeader toggle={toggle}>Đổi chức vụ</ModalHeader>
          <ModalBody>
            <div class="row">
              <div class="col-md-12">
                <Form onSubmit={(e) => this.changeRole(e)}>
                  <FormGroup>
                    <Label>Chức vụ</Label>
                    <select
                      name="roleName"
                      class="form-control"
                      onChange={this.handleInputChange}
                    >
                      {roles &&
                        roles.map((i) =>
                          i.name == roleName ? (
                            <option value={i.name} selected>
                              {i.name}
                            </option>
                          ) : (
                            <option value={i.name}>{i.name}</option>
                          )
                        )}
                    </select>
                  </FormGroup>
                  <Button className="mb-2 me-2" type="submit">
                    Đồng ý
                  </Button>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default ChangeRoleModal;
