import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";

import MetisMenu from "react-metismenu";

import {
  ContactUsNav,
  AdminContactUsNav,
  UserProfileNav,
  LinksNav,
  ScheduledJobNav,
  SettingsNav,
} from "./NavItems";
import { AdminToolsNav } from "./NavItems";
import { UserToolsNav } from "./NavItems";
import { UsersNav } from "./NavItems";
import { authPermissions, authRoles } from "../../utils/constants";
import { parseJwt } from "../../common/utils";

class Nav extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let toolsNav = UserToolsNav;
    let contactUsNav = ContactUsNav;
    let user = JSON.parse(localStorage.user);
    let permissions = [];
    if (user && user.token) {
      const identity = parseJwt(user.token);
      permissions = identity.Permission;
    }

    return (
      <Fragment>
        {(permissions.includes(authPermissions.Portfolio.MyPortfolio) ||
          permissions.includes(authPermissions.Portfolio.List)) && (
          <>
            <h5 className="app-sidebar__heading">Công Cụ</h5>
          </>
        )}

        {permissions.includes(authPermissions.Portfolio.MyPortfolio) && (
          <>
            <MetisMenu
              content={toolsNav}
              activeLinkTo
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          </>
        )}

        {permissions.includes(authPermissions.Portfolio.List) && (
          <>
            <MetisMenu
              content={AdminToolsNav}
              activeLinkTo
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          </>
        )}

        {permissions.includes(authPermissions.User.List) && (
          <>
            <h5 className="app-sidebar__heading">Quản lý người dùng</h5>
            <MetisMenu
              content={UsersNav}
              activeLinkTo
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          </>
        )}

        {permissions.includes(authPermissions.User.MyProfile) && (
          <>
            <h5 className="app-sidebar__heading">Thông tin cá nhân</h5>
            <MetisMenu
              content={UserProfileNav}
              activeLinkTo
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          </>
        )}

        <h5 className="app-sidebar__heading">Về Chúng Tôi</h5>
        {permissions.includes(authPermissions.SupportTicket.SendEmail) && (
          <>
            <MetisMenu
              content={contactUsNav}
              activeLinkTo
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          </>
        )}

        {permissions.includes(authPermissions.LinksList.List) && (
          <>
            <MetisMenu
              content={LinksNav}
              activeLinkTo
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          </>
        )}

        {permissions.includes(authPermissions.SupportTicket.List) && (
          <>
            <MetisMenu
              content={AdminContactUsNav}
              activeLinkTo
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          </>
        )}

        {permissions.includes(authPermissions.ScheduledJob.List) && (
          <>
            <h5 className="app-sidebar__heading">Scheduled Job</h5>
            <MetisMenu
              content={ScheduledJobNav}
              activeLinkTo
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          </>
        )}

        {permissions.includes(authPermissions.Portfolio.List) && (
          <>
            <h5 className="app-sidebar__heading">Cài Đặt</h5>
            <MetisMenu
              content={SettingsNav}
              activeLinkTo
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          </>
        )}
      </Fragment>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

export default withRouter(Nav);
