import React, { Fragment } from "react";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { NavLink } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  ModalBody,
  Modal,
  ModalHeader,
  CardFooter,
  Input,
} from "reactstrap";
import RoleService from "../../../services/role.service";
import { ToastContainer, toast, Bounce } from "react-toastify";

class RoleDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roleDetail: null,
      errorModalOpen: false,
      permissions: null,
      error: "",
    };

    this.updateRolePermissions = this.updateRolePermissions.bind(this);
    this.toggleErrorModal = this.toggleErrorModal.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  notifySucess = (text) =>
    (this.toastId = toast(text, {
      transition: Bounce,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-center",
      type: "success",
    }));

  componentDidMount() {
    let { match } = this.props;
    new RoleService().detail(match.params.roleId).then(
      (response) => {
        this.setState({
          roleDetail: response.data,
          permissions: response.data.roleClaims,
        });
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
        });
      }
    );
  }

  updateRolePermissions() {
    let { match } = this.props;
    let { roleDetail, permissions } = this.state;
    roleDetail.roleClaims = permissions;
    new RoleService().update(match.params.roleId, roleDetail).then(
      (response) => {
        this.notifySucess("Update permissions successfully!");
        this.setState((prevState) => {
          let roleDetail = Object.assign({}, prevState.roleDetail);
          roleDetail.roleClaims = permissions;
          return { roleDetail };
        });
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
        });
      }
    );
  }

  toggleErrorModal() {
    this.setState({ errorModalOpen: !this.state.errorModalOpen });
  }

  onToggle(value, isSelected) {
    const nextPermissionList = this.state.permissions;
    const permissionToBeUpdated = nextPermissionList.find(
      (p) => p.value === value
    );

    permissionToBeUpdated.selected = isSelected;
    this.setState({ permissions: nextPermissionList });
  }

  render() {
    let { roleDetail } = this.state;
    return (
      <Fragment>
        <PageTitle
          heading="Thông tin quyền hạn"
          icon="pe-7s-portfolio icon-gradient bg-amy-crisp"
        />
        <Row>
          <Col className="col-12">
            <Card>
              <CardHeader>
                <div className="card-header-title">
                  Phân quyền cho {roleDetail != null ? roleDetail.roleName : ""}
                </div>
              </CardHeader>
              <CardBody>
                <div class="table-responsive">
                  <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                      <tr>
                        <th class="text-center">#</th>
                        <th>Tên</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {roleDetail &&
                        roleDetail.roleClaims &&
                        roleDetail.roleClaims.map((roleClaim, i) => {
                          return (
                            <tr>
                              <td class="text-center" scope="row">
                                {i + 1}
                              </td>
                              <td class="text-center">{roleClaim.value}</td>
                              <td class="text-center">
                                <Input
                                  type="checkbox"
                                  onChange={(e) => {
                                    this.onToggle(
                                      roleClaim.value,
                                      e.target.checked
                                    );
                                  }}
                                  checked={roleClaim.selected}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
              <CardFooter>
                <button
                  class="btn btn-primary mb-2 me-2"
                  onClick={() => this.updateRolePermissions()}
                >
                  Chỉnh sửa
                </button>
              </CardFooter>
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={this.state.errorModalOpen}
          fade={false}
          toggle={() => this.toggleErrorModal()}
        >
          <ModalHeader
            className="text-danger"
            toggle={() => this.toggleErrorModal()}
          >
            <i class="lnr-warning">&nbsp;</i>Lỗi
          </ModalHeader>
          <ModalBody>{this.state.error}</ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default RoleDetail;
