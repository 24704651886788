import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';

class UpdateDepositAndWithdrawModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        userId: '',
        totalDeposit: '',
        totalWithdraw: '',
        note: ''
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    updateDepositAndWithdraw(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            let { totalDeposit, totalWithdraw, note } = this.state;
            let updateDepositAndWithdrawRequest = {
                portfolioId: this.props.portfolioId,
                userId: this.props.userId,
                totalDeposit: totalDeposit,
                totalWithdraw: totalWithdraw,
                note: note
            };
            this.props.handleSubmit(updateDepositAndWithdrawRequest);
            this.setState(this.initialState);
        }
    }

    componentDidUpdate(prevProps){
        if (prevProps.totalDeposit != this.props.totalDeposit){
            this.setState({
                totalDeposit: this.props.totalDeposit
            })
        }
        if (prevProps.totalWithdraw != this.props.totalWithdraw){
            this.setState({
                totalWithdraw: this.props.totalWithdraw
            })
        }
    }

    render() {
        let { open, toggle, userName } = this.props;
        let { totalDeposit, totalWithdraw, note } = this.state;
        return (

            <Modal isOpen={open} fade={false} toggle={toggle}>
                <ModalHeader toggle={toggle}>Cập Nhật Tổng Nạp Và Tổng Rút</ModalHeader>
                <Form onSubmit={(e) => this.updateDepositAndWithdraw(e)}>
                    <ModalBody>
                        <FormGroup>
                            <Label>Người Dùng</Label>
                            <Input readOnly type="text" id="userName" name="userName" value={userName} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="totalDeposit">Tổng Nạp</Label>
                            <InputGroup>
                                <Input onChange={this.handleInputChange} value={totalDeposit} required type="number" id="totalDeposit" min="0" name="totalDeposit" />
                                <InputGroupText>đ</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label for="totalWithdraw">Tổng Rút</Label>
                            <InputGroup>
                                <Input onChange={this.handleInputChange} value={totalWithdraw} required type="number" id="totalWithdraw" min="0" name="totalWithdraw" />
                                <InputGroupText>đ</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label for="note">Ghi Chú</Label>
                            <Input onChange={this.handleInputChange} value={note} type="textarea" name="note" id="note" />
                        </FormGroup>
                    </ModalBody >
                    <ModalFooter>
                        <button class="btn btn-primary mb-2 me-2" type="submit">Cập Nhật</button>
                        <button class="btn btn-secondary mb-2 me-2" type="button" onClick={toggle}>Thoát</button>
                    </ModalFooter>
                </Form>
            </Modal >
        );
    }
}

export default UpdateDepositAndWithdrawModal;