import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';

class AddUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        userId: '',
        cash: '',
        note: ''
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    addUser(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            let { cash, note, userId } = this.state;
            let addUserRequest = {
                portfolioId: this.props.portfolioId,
                userId: userId,
                cash: cash,
                note: note
            };
            this.props.handleSubmit(addUserRequest);
            this.setState(this.initialState);
        }
    }

    render() {
        let { open, toggle, userInfos } = this.props;
        let { cash, note, userId } = this.state;
        return (

            <Modal isOpen={open} fade={false} toggle={toggle}>
                <ModalHeader toggle={toggle}>Thêm Người Dùng</ModalHeader>
                <Form onSubmit={(e) => this.addUser(e)}>
                    <ModalBody>
                        <FormGroup>
                            <Label>Người Dùng</Label>
                            <Input onChange={this.handleInputChange} required type="select" id="userId" name="userId" value={userId}>
                                <option value="">Xin chọn người dùng...</option>
                                {userInfos && userInfos.map(u => (
                                    <option value={u.userId}>{u.userName}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="price">Giá Trị</Label>
                            <InputGroup>
                                <Input onChange={this.handleInputChange} value={cash} type="number" id="cash" min="0" name="cash" />
                                <InputGroupText>đ</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label for="note">Ghi Chú</Label>
                            <Input onChange={this.handleInputChange} value={note} type="textarea" name="note" id="note" />
                        </FormGroup>
                    </ModalBody >
                    <ModalFooter>
                        <button class="btn btn-primary mb-2 me-2" type="submit">Thêm</button>
                        <button class="btn btn-secondary mb-2 me-2" type="button" onClick={toggle}>Thoát</button>
                    </ModalFooter>
                </Form>
            </Modal >
        );
    }
}

export default AddUserModal;