import React, { Fragment } from "react";
import { Route, withRouter } from "react-router-dom";

import AppHeader from "../../Layout/AppHeader/";
import AppSidebar from "../../Layout/AppSidebar/";
import AppFooter from "../../Layout/AppFooter/";
import UserListing from "./Listing";
import UserDetail from "./Details";
import PrivateRoute from "../../common/private-route";
import { authRoles } from "../../utils/constants";

const User = ({ match }) => (
  <Fragment>
    <AppHeader />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <PrivateRoute exact path={`${match.path}`} component={UserListing} />
          <Route
            path={`${match.path}/:userId`}
            render={(props) => (
              <UserDetail key={props.match.params.userId} {...props} />
            )}
          />
        </div>
        <AppFooter />
      </div>
    </div>
  </Fragment>
);

export default withRouter(User);
