import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "/api/scheduledjobs/";
export default class ScheduledJobService {
  list() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  detail(name) {
    return axios.get(API_URL + name, { headers: authHeader() });
  }

  update(name, request) {
    return axios.post(API_URL + name + "/update", request, {
      headers: authHeader(),
    });
  }

  startManually(name) {
    return axios.post(API_URL + name + "/startmanually", null, {
      headers: authHeader(),
    });
  }

  stop(name) {
    return axios.post(API_URL + name + "/stop", null, {
      headers: authHeader(),
    });
  }

  history(name, page, pageSize) {
    return axios.get(
      API_URL + name + "/histories?page=" + page + "&pageSize=" + pageSize,
      { headers: authHeader() }
    );
  }

  notification(name) {
    return axios.get(API_URL + name + "/notification", {
      headers: authHeader(),
    });
  }

  clearAllHistory(name) {
    return axios.post(API_URL + name + "/histories/clear", null, {
      headers: authHeader(),
    });
  }
}
