import React, { Fragment } from "react";
import { Route, withRouter } from "react-router-dom";

import AppHeader from "../../Layout/AppHeader/";
import AppSidebar from "../../Layout/AppSidebar/";
import AppFooter from "../../Layout/AppFooter/";
import SendEmailContactUs from "./SendEmailContactUs";
import ContactUsTicketListing from "./Listing";
import ContactUsTicketDetail from "./Detail";

const ContactUs = ({ match }) => (
  <Fragment>
    <AppHeader />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Route exact path={`${match.path}`} component={SendEmailContactUs} />
          <Route
            exact
            path={`${match.path}/tickets`}
            component={ContactUsTicketListing}
          />
          <Route
            path={`${match.path}/tickets/:ticketId`}
            render={(props) => (
              <ContactUsTicketDetail
                key={props.match.params.ticketId}
                {...props}
              />
            )}
          />
        </div>
        <AppFooter />
      </div>
    </div>
  </Fragment>
);

export default withRouter(ContactUs);
