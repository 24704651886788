import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const CensoredInfo = ({ censored, content }) => (
    <>
        {censored ? (
            <>
                <FontAwesomeIcon icon={faCircle} size="2xs" />&nbsp;
                <FontAwesomeIcon icon={faCircle} size="2xs" />&nbsp;
                <FontAwesomeIcon icon={faCircle} size="2xs" />
            </>
        ) : (
            <>
                {content}
            </>
        )}
    </>
)

export default CensoredInfo;