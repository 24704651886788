import React, { Fragment } from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';

class AddMarketModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        name: ''
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    addMarket(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            this.props.handleSubmit(this.state);
            this.setState(this.initialState);
        }
    }

    render() {
        let { open, toggle } = this.props;
        let { name } = this.state;
        return (
            <Fragment>
                <Modal isOpen={open} fade={false} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Thêm Market</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={(e) => this.addMarket(e)}>
                            <FormGroup>
                                <Label for="name">Tên</Label>
                                <Input onChange={this.handleInputChange} type="text" required value={name} id="name" name="name" />
                            </FormGroup>
                            <Button color='primary' className='me-1' type="submit">Thêm</Button>
                            <Button color='secondary' type="button" onClick={toggle}>Thoát</Button>
                        </Form>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

export default AddMarketModal;