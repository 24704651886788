import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "/api/roles/";
export default class RoleService {
  list() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  detail(id) {
    return axios.get(API_URL + id, { headers: authHeader() });
  }

  delete(id) {
    return axios.post(API_URL + id + "/delete", null, {
      headers: authHeader(),
    });
  }

  create(roleName) {
    return axios.post(API_URL + roleName + "/create", null, {
      headers: authHeader(),
    });
  }

  update(id, request) {
    return axios.post(API_URL + id + "/update", request, {
      headers: authHeader(),
    });
  }
}
