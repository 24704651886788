import axios from 'axios';
import authHeader from './auth-header';
const API_URL = "/api/portfolio/";
export default class PortfolioTransactionService {
    buyAsset(request){
        return axios.post(API_URL + 'buyasset', request, {
            headers: authHeader()
        });
    }

    sellAsset(request){
        return axios.post(API_URL + 'sellAsset', request, {
            headers: authHeader()
        });
    }

    addCashSource(request){
        return axios.post(API_URL + 'addcashsource', request, {
            headers: authHeader()
        });
    }

    transferCashSource(request){
        return axios.post(API_URL + 'transfercashsource', request, {
            headers: authHeader()
        });
    }

    deleteCashSource(request){
        return axios.post(API_URL + 'deletecashsource', request, {
            headers: authHeader()
        });
    }

    addMoneyForUser(request){
        return axios.post(API_URL + 'addmoneyforuser', request, {
            headers: authHeader()
        });
    }

    buyCertificate(request){
        return axios.post(API_URL + 'buycertificate', request, {
            headers: authHeader()
        });
    }

    sellCertificate(request){
        return axios.post(API_URL + 'sellcertificate', request, {
            headers: authHeader()
        });
    }

    addUser(request){
        return axios.post(API_URL + 'adduser', request, {
            headers: authHeader()
        });
    }

    updateDepositAndWithdraw(request){
        return axios.post(API_URL + 'updatedepositandwithdraw', request, {
            headers: authHeader()
        });
    }

    updateTransaction(request){
        return axios.post(API_URL + 'updatetransaction', request, {
            headers: authHeader()
        });
    }

    updatePortfolioRate(request){
        return axios.post(API_URL + 'updateportfoliorate', request, {
            headers: authHeader()
        });
    }

    updateFinalCalculation(request){
        return axios.post(API_URL + 'updatefinalcalculation', request, {
            headers: authHeader()
        });
    }

    approveDepositRequest(request){
        return axios.post(API_URL + 'approvedepositrequest', request, {
            headers: authHeader()
        });
    }

    denydepositrequest(requestId){
        return axios.post(API_URL + 'denydepositrequest/' + requestId, {} , {
            headers: authHeader()
        });
    }

    denywithdrawrequest(requestId){
        return axios.post(API_URL + 'denywithdrawrequest/' + requestId, {} , {
            headers: authHeader()
        });
    }

    approveWithdrawRequest(request){
        return axios.post(API_URL + 'approvewithdrawrequest', request, {
            headers: authHeader()
        });
    }
}