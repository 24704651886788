import axios from 'axios';
import authHeader from './auth-header';
const API_URL = "/api/portfolio/";
export default class PortfolioService {
    list(){
        return axios.get(API_URL, {headers: authHeader()});
    }

    detail(id){
        return axios.get(API_URL + id, {headers: authHeader()});
    }

    overview(id){
        return axios.get(API_URL + 'overview/' + id, {headers: authHeader()});
    }

    navCCQChart(id, period){
        return axios.get(API_URL + 'navccqchart/' + id + '?period=' + period, {headers: authHeader()});
    }

    growthRateChart(id, period){
        return axios.get(API_URL + 'growthRateChart/' + id + '?period=' + period, {headers: authHeader()});
    }

    allocationChart(id, historyId){
        return axios.get(API_URL + 'allocationChart/' + id + '/' + historyId, {headers: authHeader()})
    }

    assets(id){
        return axios.get(API_URL + 'assets/' + id, {headers: authHeader()})
    }

    sortAsset(request){
        return axios.post(API_URL + 'sortasset', request, {
            headers: authHeader()
        })
    }

    sortCashSource(request){
        return axios.post(API_URL + 'sortcashsource', request, {
            headers: authHeader()
        })
    }

    ccqDetails(id){
        return axios.get(API_URL + 'ccqDetails/' + id, {headers: authHeader()})
    }

    navccqHistories(id,page){
        return axios.get(API_URL + 'navCCQHistory/' + id + "/" +page, {headers: authHeader()})
    }

    assetHistories(id,page){
        return axios.get(API_URL + 'assetHistory/' + id + "/" +page, {headers: authHeader()})
    }

    depositRequests(id,page){
        return axios.get(API_URL + 'depositRequest/' + id + "/" +page, {headers: authHeader()})
    }

    withdrawRequests(id,page){
        return axios.get(API_URL + 'withdrawRequest/' + id + "/" +page, {headers: authHeader()})
    }

    transactionHistories(id,page){
        return axios.get(API_URL + 'transactionHistory/' + id + "/" +page, {headers: authHeader()})
    }

    logs(id,page){
        return axios.get(API_URL + 'logs/' + id + "/" +page, {headers: authHeader()})
    }

    addNAVCCQHistory(request){
        return axios.post(API_URL + 'addnavccqhistory', request, {
            headers: authHeader()
        });
    }
    
    toggleRootNAVCCQ(request){
        return axios.post(API_URL + 'togglerootnavccqhistory', request, {
            headers: authHeader()
        }); 
    }

    deleteNAVCCQHistory(request){
        return axios.post(API_URL + 'deletenavccqhistory', request, {
            headers: authHeader()
        }); 
    }

    deleteAssetHistory(request){
        return axios.post(API_URL + 'deleteassethistory', request, {
            headers: authHeader()
        }); 
    }

    sortAssetHistory(request){
        return axios.post(API_URL + 'sortassethistory', request, {
            headers: authHeader()
        });
    }

    stocks(){
        return axios.get(API_URL + 'stocks', {
            headers: authHeader()
        });
    }

    createAssetHistory(request){
        return axios.post(API_URL + 'createassethistory', request, {
            headers: authHeader()
        });
    }

    cashSources(id){
        return axios.get(API_URL + 'cashsources/' + id, {
            headers: authHeader()
        });
    }

    deleteLog(request){
        return axios.post(API_URL + 'deletelog', request, {
            headers: authHeader()
        });
    }

    upsertPortfolioLog(request){
        return axios.post(API_URL + 'upsertlog', request, {
            headers: authHeader()
        });
    }

    loadFinalCalculation(id){
        return axios.get(API_URL + 'loadfinalcalculation/' + id, {
            headers: authHeader()
        }); 
    }

    loadWithdrawRequestInfo(id) {
        return axios.get(API_URL + 'loadwithdrawrequestinfo/' + id, {
            headers: authHeader()
        }); 
    }

    addPortfolio(request){
        return axios.post(API_URL + 'addportfolio', request, {
            headers: authHeader()
        });
    }

    deletePortfolio(id){
        return axios.post(API_URL + 'deleteportfolio/' + id, {}, {
            headers: authHeader()
        });
    }

    customerAssetHistories(portfolioId, customerId, page){
        return axios.get(API_URL + 'customerAssetHistories/' + portfolioId + "/" + customerId + '/' + page, {headers: authHeader()})
    }

    addOrUpdateCustomerAssetHistory(request){
        return axios.post(API_URL + 'addOrUpdateCustomerAssetHistory', request, {
            headers: authHeader()
        });
    }

    deleteCustomerAssetHistory(id){
        return axios.post(API_URL + 'deleteCustomerAssetHistory/' + id, {}, {
            headers: authHeader()
        });
    }

    importCustomerAssetHistory(request){
        var header = authHeader();
        header = {...header, "Content-Type": "multipart/form-data"};
        return axios.post(API_URL + 'importCustomerAssetHistory', request, {
            headers: header
        });
    }
}