import React, { Fragment } from "react";
import MarketService from "../../../services/market.service";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { NavLink } from "react-router-dom";
import { Table, Row, Col, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AddMarketModal from "./AddMarketModal";

class MarketListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            markets: [],
            error: '',
            errorModalOpen: false,
            deleteMarketOpenModal: false,
            deleteMarketId: '',
            deleteMarketName: '',
            addMarketModalOpen: false
        };
        this.addMarket = this.addMarket.bind(this);
        this.deleteMarket = this.deleteMarket.bind(this);
    }

    componentDidMount() {
        this.loadMarkets();
    }

    openDeleteMarketModal(marketId, marketName) {
        this.setState({
            deleteMarketOpenModal: true,
            deleteMarketId: marketId,
            deleteMarketName: marketName
        });
    }

    toggleErrorModal(){
        this.setState({
            errorModalOpen: !this.state.errorModalOpen
        });
    }

    toggleDeleteMarketModal() {
        this.setState({
            deleteMarketOpenModal: !this.state.deleteMarketOpenModal
        });
    }

    toggleAddMarketModal() {
        this.setState({
            addMarketModalOpen: !this.state.addMarketModalOpen
        });
    }

    loadMarkets() {
        new MarketService().list().then(
            response => {
                this.setState({
                    markets: response.data,
                    error: ""
                });
            },
            error => {
                this.setState({
                    markets: [],
                    error: error.message,
                    errorModalOpen: true
                })
            }
        );
    }

    addMarket(request){
        new MarketService().add(request.name).then(
            response => {
                if (response.data.success) {
                    this.loadMarkets();
                    this.setState({
                        addMarketModalOpen: false
                    });
                } else {
                    this.setState({
                        error: response.data.message,
                        errorModalOpen: true,
                        addMarketModalOpen: false
                    });
                }

            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    addMarketModalOpen: false
                });
            }
        );
    }

    deleteMarket(id){
        new MarketService().delete(this.state.deleteMarketId).then(
            response => {
                if (response.data.success) {
                    this.loadMarkets();
                    this.setState({
                        deleteMarketOpenModal: false
                    });
                } else {
                    this.setState({
                        error: response.data.message,
                        errorModalOpen: true,
                        deleteMarketOpenModal: false
                    });
                }

            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    deleteMarketOpenModal: false
                });
            }
        );
    }

    render() {
        const { match } = this.props;
        return (
            <Fragment>
                <PageTitle
                    heading="Danh sách Markets"
                    icon="pe-7s-target icon-gradient bg-amy-crisp"
                />
                <Row>
                    <Col className='col-12'>
                        <Card className='main-card mb-3'>
                            <CardHeader>
                                <div className="card-header-title">
                                    Thông Tin
                                </div>
                                <div className="btn-actions-pane-right">
                                    <Button color="primary" onClick={() => this.toggleAddMarketModal()}>
                                        <i className='lnr-plus'> </i>
                                        Thêm Market
                                    </Button>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Table className="mb-0 text-center" borderless>
                                    <thead>
                                        <tr>
                                            <th>Tên</th>
                                            <th>Ngày Thêm</th>
                                            <th>...</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.markets && this.state.markets.map((market, i) => {
                                            return (
                                                <tr>
                                                    <td><NavLink to={`${match.url}` + '/' + market.id}>{market.name}</NavLink></td>
                                                    <td>{market.created}</td>
                                                    <td><Button onClick={() => this.openDeleteMarketModal(market.id, market.name)}>Xóa</Button></td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                    <AddMarketModal handleSubmit={this.addMarket} open={this.state.addMarketModalOpen} toggle={() => this.toggleAddMarketModal()} />
                    <Modal isOpen={this.state.errorModalOpen} fade={false} toggle={() => this.toggleErrorModal()}>
                        <ModalHeader className='text-danger' toggle={() => this.toggleErrorModal()}><i class="lnr-warning">&nbsp;</i>Lỗi</ModalHeader>
                        <ModalBody>
                            {this.state.error}
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={this.state.deleteMarketOpenModal} fade={false} toggle={() => this.toggleDeleteMarketModal()}>
                        <ModalHeader toggle={() => this.toggleDeleteMarketModal()}><i class="lnr-warning">&nbsp;</i>Bạn có chắc muốn xóa market <strong>{this.state.deleteMarketName}</strong>?</ModalHeader>
                        <ModalBody>
                            <div class="row">
                                <div class="col-md-12">
                                    <button class="btn btn-primary mb-2 me-2" onClick={this.deleteMarket}>Có</button>
                                    <button class="btn btn-secondary mb-2 me-2" onClick={() => this.toggleDeleteMarketModal()}>Không</button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </Row>
            </Fragment>
        );
    }
}

export default MarketListing;