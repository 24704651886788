import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { CardFooter, FormGroup, InputGroup, Form, Row, Col, Card, CardHeader, CardBody, Label, Input, InputGroupText } from 'reactstrap';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import PortfolioTransactionService from '../../../services/portfolio.transaction.service';

class UpdatePorfolioRateTool extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.updatePortfolioRate = this.updatePortfolioRate.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        hanMuc: 0,
        hieuQua: 0,
        phiRutTien: 0
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    updatePortfolioRate(event) {
        event.preventDefault();
        if (event.target.checkValidity()) {
            const request = {
                portfolioId: this.props.match.params.portfolioId,
                hieuQua: this.state.hieuQua,
                hanMuc: this.state.hanMuc,
                phiRutTien: this.state.phiRutTien
            };
            new PortfolioTransactionService().updatePortfolioRate(request).then(
                response => {
                    if (response.data.success) {
                        this.props.updateOverview();
                        this.props.history.push(`${this.props.match.url.replace("/updateportfoliorate", "")}`);
                    }
                },
                error => {
                    this.props.history.push(`${this.props.match.url.replace("/updateportfoliorate", "")}`);
                }
            )
        }
    }

    componentDidMount() {
        this.setState({
            hanMuc: this.props.hanMuc.replace('%', '').replace(' ',''),
            hieuQua: this.props.hieuQua.replace('%', '').replace(' ',''),
            phiRutTien: this.props.phiRutTien.replace('%', '').replace(' ','')
        });
    }

    render() {
        const { match } = this.props;
        const { hanMuc, hieuQua, phiRutTien } = this.state;
        return (
            <Fragment>
                <Row>
                    <Col md="6">
                        <Card className="main-card mb-3">
                            <CardHeader>
                                <div className="card-header-title">
                                    <i className="header-icon lnr-magic-wand icon-gradient bg-plum-plate"> </i>
                                    Cập Nhật Hạn Mức, Hiệu Quả Và Phí Rút Tiền
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={this.updatePortfolioRate}>
                                    <FormGroup>
                                        <Label for="hanMuc">Hạn Mức</Label>
                                        <InputGroup>
                                            <Input onChange={this.handleInputChange} value={hanMuc} type="number" min="0" max="100" id="hanMuc" name="hanMuc" />
                                            <InputGroupText>%</InputGroupText>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="hieuQua">Hiệu Quả</Label>
                                        <InputGroup>
                                            <Input onChange={this.handleInputChange} value={hieuQua} type="number" min="0" max="100" name="hieuQua" id="hieuQua" />
                                            <InputGroupText>%</InputGroupText>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="phiRutTien">Phí Rút Tiền</Label>
                                        <InputGroup>
                                            <Input onChange={this.handleInputChange} value={phiRutTien} type="number" step=".01" min="0" max="99.99" name="phiRutTien" id="phiRutTien" />
                                            <InputGroupText>%</InputGroupText>
                                        </InputGroup>
                                    </FormGroup>
                                    <button class="btn btn-primary mb-2 me-2" type="submit">Lưu</button>
                                    <NavLink className="btn btn-secondary mb-2 me-2" to={`${match.url.replace("/updateportfoliorate", "")}`}>
                                        Thoát
                                    </NavLink>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default UpdatePorfolioRateTool;