import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

class AddUserBankAccountModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  initialState = {
    userId: this.props.userId,
    bankName: "",
    branchName: "",
    accountNumber: "",
    accountName: "",
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  addUserBankAccount(e) {
    e.preventDefault();
    this.props.handleSubmit(this.state);
    this.setState(this.initialState);
  }

  render() {
    let { open, toggle } = this.props;
    let { bankName, branchName, accountNumber, accountName } = this.state;
    return (
      <>
        <Modal isOpen={open} fade={false} toggle={toggle}>
          <ModalHeader toggle={toggle}>Thêm tài khoản ngân hàng</ModalHeader>
          <ModalBody>
            <div class="row">
              <div class="col-md-12">
                <Form onSubmit={(e) => this.addUserBankAccount(e)}>
                  <FormGroup>
                    <Label>Tên ngân hàng</Label>
                    <Input
                      onChange={this.handleInputChange}
                      type="text"
                      required
                      value={bankName}
                      name="bankName"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Chi nhánh</Label>
                    <Input
                      onChange={this.handleInputChange}
                      type="text"
                      required
                      value={branchName}
                      name="branchName"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Số tài khoản</Label>
                    <Input
                      onChange={this.handleInputChange}
                      type="text"
                      required
                      value={accountNumber}
                      name="accountNumber"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Tên chủ tài khoản</Label>
                    <Input
                      onChange={this.handleInputChange}
                      type="text"
                      required
                      value={accountName}
                      name="accountName"
                    />
                  </FormGroup>
                  <Button className="mb-2 me-2" type="submit">
                    Thêm
                  </Button>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default AddUserBankAccountModal;
