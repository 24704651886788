import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Collapse,
  CardHeader,
  ModalBody,
  Modal,
  ModalHeader,
} from "reactstrap";
import UserService from "../../../../../services/user.service";
import AddUserBankAccountModal from "./AddUserBankAccountModal";
import DeleteUserBankAccountModal from "./DeleteUserBankAccountModal";

class BankAccountListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userBankAccounts: [],
      error: "",
      addUserBankAccountModelOpen: false,
      deleteUserBankAccountModelOpen: false,
      errorModalOpen: false,
      accountNumberToBeDeleted: "",
    };

    this.loadUserBankAccounts = this.loadUserBankAccounts.bind(this);
    this.deleteUserBankAccount = this.deleteUserBankAccount.bind(this);
    this.createUserBankAccount = this.createUserBankAccount.bind(this);
    this.toggleAddUserBankAccountModal =
      this.toggleAddUserBankAccountModal.bind(this);
    this.toggleDeleteUserBankAccountModal =
      this.toggleDeleteUserBankAccountModal.bind(this);
  }

  loadUserBankAccounts(userId) {
    new UserService().listBankAccounts(userId).then(
      (response) => {
        this.setState({
          userBankAccounts: response.data,
          error: "",
        });
      },
      (error) => {
        this.setState({
          userBankAccounts: [],
          error: error.message,
        });
      }
    );
  }

  createUserBankAccount(request) {
    new UserService().createBankAccount(request).then(
      (response) => {
        this.loadUserBankAccounts(request.userId);
        this.setState({
          addUserBankAccountModelOpen: false,
        });
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
          addUserBankAccountModelOpen: false,
        });
      }
    );
  }

  deleteUserBankAccount(id, accountNumber) {
    new UserService().deleteBankAccount(id, accountNumber).then(
      (response) => {
        this.setState({
          userBankAccounts: this.state.userBankAccounts.filter(
            (bankAccount) => bankAccount.accountNumber !== accountNumber
          ),
          deleteUserBankAccountModelOpen: false,
        });
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
          deleteUserBankAccountModelOpen: false,
        });
      }
    );
  }

  componentDidMount() {
    let { userId } = this.props;
    this.loadUserBankAccounts(userId);
  }

  toggleAddUserBankAccountModal() {
    this.setState({
      addUserBankAccountModelOpen: !this.state.addUserBankAccountModelOpen,
    });
  }

  toggleDeleteUserBankAccountModal(accountNumber) {
    this.setState({
      accountNumberToBeDeleted: accountNumber,
      deleteUserBankAccountModelOpen:
        !this.state.deleteUserBankAccountModelOpen,
    });
  }

  toggleErrorModal() {
    this.setState({ errorModalOpen: !this.state.errorModalOpen });
  }

  render() {
    let { match, userId } = this.props;
    let {
      addUserBankAccountModelOpen,
      deleteUserBankAccountModelOpen,
      accountNumberToBeDeleted,
    } = this.state;
    return (
      <Fragment>
        <Card>
          <CardHeader>
            <div className="card-header-title">
              Thông tin tài khoản ngân hàng
            </div>
            <div className="btn-actions-pane-right">
              <button
                class="btn btn-primary mb-2 me-2"
                onClick={() => this.toggleAddUserBankAccountModal()}
              >
                <i className="lnr-file-add"> </i>
                Thêm
              </button>
            </div>
          </CardHeader>
          <CardBody>
            <div class="table-responsive">
              <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                <thead>
                  <tr>
                    <th class="text-center">#</th>
                    <th class="text-center">Tên ngân hàng</th>
                    <th class="text-center">Chi nhánh</th>
                    <th class="text-center">No</th>
                    <th class="text-center">Tên chủ tài khoản</th>
                    <th class="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.userBankAccounts &&
                    this.state.userBankAccounts.map((bankAccount, i) => {
                      return (
                        <tr>
                          <td class="text-center" scope="row">
                            {i + 1}
                          </td>
                          <td class="text-center">{bankAccount.bankName}</td>
                          <td class="text-center">{bankAccount.branchName}</td>
                          <td class="text-center">
                            {bankAccount.accountNumber}
                          </td>
                          <td class="text-center">{bankAccount.accountName}</td>
                          <td class="text-center">
                            <button
                              class="btn btn-danger mb-2 me-2"
                              onClick={() =>
                                this.toggleDeleteUserBankAccountModal(
                                  bankAccount.accountNumber
                                )
                              }
                            >
                              <i className="lnr-trash"> </i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
        <AddUserBankAccountModal
          handleSubmit={this.createUserBankAccount}
          open={addUserBankAccountModelOpen}
          toggle={() => this.toggleAddUserBankAccountModal()}
          userId={userId}
        />
        <DeleteUserBankAccountModal
          handleSubmit={this.deleteUserBankAccount}
          open={deleteUserBankAccountModelOpen}
          accountNumber={accountNumberToBeDeleted}
          userId={userId}
          toggle={() => this.toggleDeleteUserBankAccountModal("")}
        />
        <Modal
          isOpen={this.state.errorModalOpen}
          fade={false}
          toggle={() => this.toggleErrorModal()}
        >
          <ModalHeader
            className="text-danger"
            toggle={() => this.toggleErrorModal()}
          >
            <i class="lnr-warning">&nbsp;</i>Lỗi
          </ModalHeader>
          <ModalBody>{this.state.error}</ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default BankAccountListing;
