import axios from 'axios';
import authHeader from './auth-header';
const API_URL = "/api/market/";
export default class MarketService {
    list() {
        return axios.get(API_URL, { headers: authHeader() });
    }

    name(id) {
        return axios.get(API_URL + id, { headers: authHeader() });
    }

    data(id, page) {
        return axios.get(API_URL + 'data/' + id + '/' + page, { headers: authHeader() });
    }

    add(name) {
        return axios.post(API_URL + 'add', 'name=' + name, { headers: authHeader() });
    }

    delete(id) {
        return axios.delete(API_URL + id, { headers: authHeader() });
    }

    chart(id) {
        return axios.get(API_URL + 'chart/' + id, { headers: authHeader() });
    }

    deleteMarketData(id){
        return axios.delete(API_URL + 'data/' + id, { headers: authHeader() });
    }

    addMarketData(request){
        return axios.post(API_URL + 'addmarketdata', request, { headers: authHeader() });
    }
}