import React, { Fragment } from "react";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { NavLink, Redirect } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import CustomerPortfolioService from "../../../services/customer.portfolio.service";
import CensoredInfo from "../../../common/censored-info";
import CensoredSwitch from "../../../common/censored-switch";

class CustomerPortfolioListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasPortfolio: true,
      portfolios: [],
      error: "",
      censored: true,
    };
  }

  toggleCensored() {
    this.setState({
      censored: !this.state.censored,
    });
  }

  componentDidMount() {
    new CustomerPortfolioService().list().then(
      (response) => {
        this.setState({
          hasPortfolio:
            response.data.portfolios && response.data.portfolios.length > 0,
          portfolios: response.data.portfolios,
          error: "",
        });
      },
      (error) => {
        this.setState({
          portfolios: [],
          error: error.message,
        });
      }
    );
  }

  render() {
    let { match } = this.props;
    let { censored } = this.state;
    return (
      <Fragment>
        <PageTitle
          heading="Danh sách Portfolio"
          icon="pe-7s-portfolio icon-gradient bg-amy-crisp"
        />
        <Row>
          <Col className="col-12">
            <Card className="main-card mb-3">
              <CardHeader>
                <div className="card-header-title">
                  Danh mục{" "}
                  <CensoredSwitch
                    censored={censored}
                    toggle={() => this.toggleCensored()}
                  />
                </div>
              </CardHeader>
              <CardBody>
                <Table className="mb-0" borderless>
                  <thead>
                    <tr>
                      <th>Tên</th>
                      <th>Số lượng CCQ</th>
                      <th>Tiền mặt chưa phân phối</th>
                      <th>NAVCCQ (1000đ)</th>
                      <th>Tổng Giá Trị</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.portfolios &&
                      this.state.portfolios.map((portfolio, i) => {
                        return (
                          <tr>
                            <td>
                              <NavLink to={`${match.url}/` + portfolio.id}>
                                {portfolio.name}
                              </NavLink>
                            </td>
                            <td>
                              <CensoredInfo
                                censored={censored}
                                content={portfolio.numberOfCertificates}
                              />
                            </td>
                            <td>
                              <CensoredInfo
                                censored={censored}
                                content={portfolio.cash}
                              />
                            </td>
                            <td>
                              <CensoredInfo
                                censored={censored}
                                content={portfolio.navccq}
                              />
                            </td>
                            <td>
                              <CensoredInfo
                                censored={censored}
                                content={portfolio.totalValue}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default CustomerPortfolioListing;
