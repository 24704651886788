import React, { Fragment } from "react";
import { Route, withRouter } from "react-router-dom";

import AppHeader from "../../Layout/AppHeader/";
import AppSidebar from "../../Layout/AppSidebar/";
import AppFooter from "../../Layout/AppFooter/";
import RoleListing from "./Listing";
import RoleDetail from "./Details";
//import UserDetail from './Details';

const Role = ({ match }) => (
  <Fragment>
    <AppHeader />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Route exact path={`${match.path}`} component={RoleListing} />
          <Route
            path={`${match.path}/:roleId`}
            render={(props) => (
              <RoleDetail key={props.match.params.roleId} {...props} />
            )}
          />
        </div>
        <AppFooter />
      </div>
    </div>
  </Fragment>
);

export default withRouter(Role);
