import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Modal, ModalHeader, ModalBody } from "reactstrap";
import AppPagination from "../../../../Blocks/AppPagination";
import MarketService from "../../../../services/market.service";
import AddMarketDataModal from "./AddMarketDataModal";

class MarketData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            totalPages: 1,
            hasPreviousPage: false,
            hasNextPage: false,
            errorModalOpen: false,
            deleteMarketDataOpenModal: false,
            deleteMarketDataId: '',
            deleteMarketDataTime: '',
            addMarketDataModalOpen: false
        };

        this.deleteMarketData = this.deleteMarketData.bind(this);
        this.addMarketData = this.addMarketData.bind(this);
    }

    componentDidMount() {
        this.loadMarketData();
    }

    toggleAddMarketDataOpenModal(){
        this.setState({
            addMarketDataModalOpen: !this.state.addMarketDataModalOpen
        });
    }

    toggleDeleteMarketDataOpenModal() {
        this.setState({
            deleteMarketDataOpenModal: !this.state.deleteMarketDataOpenModal
        });
    }

    loadMarketData() {
        const { match } = this.props;
        console.log(match.params);
        if (match.params.marketId) {
            let page = match.params.page ?? 1;
            new MarketService().data(match.params.marketId, page).then(
                response => {
                    if (response.data) {
                        this.setState({
                            data: response.data.data.items,
                            totalPages: response.data.data.totalPages,
                            hasPreviousPage: response.data.data.hasPreviousPage,
                            hasNextPage: response.data.data.hasNextPage,
                            errorModalOpen: false
                        })
                    } else {
                        this.setState({
                            error: response.data.message,
                            errorModalOpen: true
                        });
                    }

                },
                error => {
                    this.setState({
                        error: error.message,
                        errorModalOpen: true,
                    });
                }
            );
        }
    }

    openDeleteMarketDataModal(dataId, dataTime) {
        this.setState({
            deleteMarketDataId: dataId,
            deleteMarketDataTime: dataTime,
            deleteMarketDataOpenModal: true
        });
    }

    toggleErrorModal() {
        this.setState({
            errorModalOpen: !this.state.errorModalOpen
        });
    }

    deleteMarketData() {
        if (this.state.deleteMarketDataId !== '') {
            new MarketService().deleteMarketData(this.state.deleteMarketDataId).then(
                response => {
                    if (response.data.success) {
                        this.loadMarketData();
                        this.setState({
                            deleteMarketDataOpenModal: false,
                            deleteMarketDataId: '',
                            deleteMarketDataTime: ''
                        });
                    } else {
                        this.setState({
                            error: response.data.message,
                            errorModalOpen: true,
                            deleteMarketDataOpenModal: false
                        });
                    }
                },
                error => {
                    this.setState({
                        error: error,
                        errorModalOpen: true,
                        deleteMarketDataOpenModal: false
                    })
                }
            )
        }
    }

    addMarketData(request){
        if (!request.marketId){
            request.marketId = this.props.match.params.marketId;
        }

        new MarketService().addMarketData(request).then(
            response => {
                if (response.data.success) {
                    this.loadMarketData();
                    this.setState({
                        addMarketDataModalOpen: false
                    });
                } else {
                    this.setState({
                        error: response.data.message,
                        errorModalOpen: true,
                        addMarketDataModalOpen: false
                    });
                }

            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    addMarketDataModalOpen: false
                });
            }
        )
    }

    render() {
        const { match } = this.props;
        const { totalPages, hasNextPage, hasPreviousPage, data } = this.state;
        const parentUrl = match.url.substring(0, match.url.lastIndexOf("/"));
        const page = parseInt(match.params.page) ?? 1;
        return (
            <Fragment>
                <Row>
                    <Col lg="12">
                        <Card className="main-card mb-3">
                            <CardHeader>
                                <div className="card-header-title">
                                    <i className="header-icon lnr-briefcase icon-gradient bg-plum-plate"> </i>
                                    Dữ Liệu
                                </div>
                                <div class="btn-actions-pane-right">
                                    <button class="btn btn-primary mb-2 me-2" onClick={() => this.toggleAddMarketDataOpenModal()}>Thêm Dữ Liệu</button>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div class="table-responsive text-center">
                                    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th>Thời Gian</th>
                                                <th>Giá Trị (1000đ)</th>
                                                <th>...</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.map(d => (
                                                <tr>
                                                    <td>{d.time}</td>
                                                    <td>{d.value}</td>
                                                    <td><button class="btn btn-primary mb-2 me-2" onClick={() => this.openDeleteMarketDataModal(d.id, d.time)}>Xóa</button></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                            <CardFooter className='justify-content-center'>
                                <AppPagination totalPages={totalPages} parentUrl={parentUrl} page={page} hasNextPage={hasNextPage} hasPreviousPage={hasPreviousPage} />
                            </CardFooter>
                        </Card>
                    </Col>
                    <AddMarketDataModal handleSubmit={this.addMarketData} open={this.state.addMarketDataModalOpen} toggle={() => this.toggleAddMarketDataOpenModal()} />
                    <Modal isOpen={this.state.errorModalOpen} fade={false} toggle={() => this.toggleErrorModal()}>
                        <ModalHeader className='text-danger' toggle={() => this.toggleErrorModal()}><i class="lnr-warning">&nbsp;</i>Lỗi</ModalHeader>
                        <ModalBody>
                            {this.state.error}
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={this.state.deleteMarketDataOpenModal} fade={false} toggle={() => this.toggleDeleteMarketDataOpenModal()}>
                        <ModalHeader toggle={() => this.toggleDeleteMarketDataOpenModal()}><i class="lnr-warning">&nbsp;</i>Bạn có chắc muốn xóa dữ liệu <strong>{this.state.deleteMarketDataTime}</strong>?</ModalHeader>
                        <ModalBody>
                            <div class="row">
                                <div class="col-md-12">
                                    <button class="btn btn-primary mb-2 me-2" onClick={this.deleteMarketData}>Có</button>
                                    <button class="btn btn-secondary mb-2 me-2" onClick={() => this.toggleDeleteMarketDataOpenModal()}>Không</button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </Row>
            </Fragment>
        );
    }
}

export default withRouter(MarketData);