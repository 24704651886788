import React, { Fragment } from "react";
import { Route, withRouter } from "react-router-dom";
import { CardFooter, FormGroup, InputGroup, Form, Row, Col, Card, CardHeader, CardBody, Label, Input, InputGroupText } from 'reactstrap';
import PageTitle from '../../Layout/AppMain/PageTitle';
import AppHeader from '../../Layout/AppHeader/';
import AppSidebar from '../../Layout/AppSidebar/';
import AppFooter from '../../Layout/AppFooter/';
import SettingsService from "../../services/settings.service";

const services = [
    "Vietstock", "Vndirect"
];

class StockServiceConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            service: null
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateService = this.updateService.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    componentDidMount() {
        new SettingsService().getcurrent().then(
            response => {
                this.setState({
                    isLoading: false,
                    service: response.data
                });
            },
            error => {
                this.setState({
                    isLoading: false
                })
            }
        );
    }

    updateService(event) {
        event.preventDefault();
        if (event.target.checkValidity()) {
            this.setState({
                isLoading: true,
            });
            new SettingsService().update(this.state.service).then(
                response => {
                    this.setState({
                        isLoading: false
                    });
                },
                error => {
                    this.setState({
                        isLoading: false
                    })
                }
            );
        }
    }

    render() {
        let { service, isLoading } = this.state;
        return (
            <Fragment>
                <AppHeader />
                <div className="app-main">
                    <AppSidebar />
                    <div className="app-main__outer">
                        <div className="app-main__inner">
                            <PageTitle
                                heading="Cài Đặt Dịch Vụ Lấy Dữ Liệu Cổ Phiếu"
                                icon="pe-7s-config icon-gradient bg-amy-crisp"
                            />
                            <Row>
                                <Col className="col-6">
                                    <Card className="mb-3">
                                        <CardBody>
                                            {!isLoading && (
                                                <Form onSubmit={this.updateService}>
                                                    <FormGroup>
                                                        <Label for="service">Dịch Vụ</Label>
                                                        <Input onChange={this.handleInputChange} value={service} type="select" required name="service" id="service">
                                                            <option value="">Xin hãy chọn dịch vụ</option>
                                                            {services && services.map((s) => (
                                                                <option value={s}>{s}</option>
                                                            ))}
                                                        </Input>
                                                    </FormGroup>
                                                    <button class="btn btn-primary mb-2 me-2" type="submit">Lưu</button>
                                                </Form>)}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <AppFooter />
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(StockServiceConfig);