import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';
import { toISOStringWithTimezone } from '../../../../../common/utils';

class CreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
        this.toggleCreateAssetHistoryModal = this.toggleCreateAssetHistoryModal.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    initialState = {
        created: toISOStringWithTimezone(new Date()),
        value: 0,
        note: ''
    }

    toggleCreateAssetHistoryModal() {
        this.setState({
            createAssetModal: !this.state.createAssetModal
        });
    }

    createAssetHistory(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            const request = {
                created: this.state.created,
                value: this.state.value,
                note: this.state.note
            };
            this.props.handleSubmit(request);
            this.setState(this.initialState);
        }
    }

    render() {
        const { open, toggle, id } = this.props;
        let { created, value, note } = this.state;
        return (
            <Modal isOpen={open} fade={false} toggle={toggle}>
                <Form onSubmit={(e) => this.createAssetHistory(e)}>
                    <ModalHeader toggle={toggle}>{id != '' ? 'Sửa' : 'Thêm'}Lịch Sử Tài Sản</ModalHeader>
                    <ModalBody scrollable>
                        <div class="row">
                            <div class="col-md-12">
                                <FormGroup>
                                    <Label>Ngày</Label>
                                    <Input onChange={this.handleInputChange} type="datetime-local" required value={created} id="created" name="created" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="cash">Giá Trị</Label>
                                    <InputGroup>
                                        <Input onChange={this.handleInputChange} value={value} type="number" id="value" min="0" name="value" />
                                        <InputGroupText>đ</InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="note">Ghi Chú</Label>
                                    <Input onChange={this.handleInputChange} value={note} type="textarea" name="note" id="note" />
                                </FormGroup>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='mb-2 me-2' color='primary' type="submit">Thêm</Button>
                        <Button className='mb-2 me-2' color='secondary' type="button" onClick={toggle}>Hủy</Button>
                    </ModalFooter>
                </Form>
            </Modal >
        )
    }
}

export default CreateModal;