import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';

import { toISOStringWithTimezone } from '../../../../common/utils';

class SellCertificateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        quantity: 0,
        transactionDate: toISOStringWithTimezone(new Date()),
        note: ''
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    sellCertificate(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            let { quantity, transactionDate, note } = this.state;
            let sellCertificateRequest = {
                portfolioId: this.props.portfolioId,
                userId: this.props.userId,
                transactionDate: transactionDate,
                quantity: quantity,
                note: note
            };
            this.props.handleSubmit(sellCertificateRequest);
            this.setState(this.initialState);
        }
    }

    render() {
        let { open, toggle, userName, maxQuantity } = this.props;
        let { transactionDate, quantity, note } = this.state;
        return (

            <Modal isOpen={open} fade={false} toggle={toggle}>
                <ModalHeader toggle={toggle}>Bán CCQ</ModalHeader>
                <Form onSubmit={(e) => this.sellCertificate(e)}>
                    <ModalBody>
                        <FormGroup>
                            <Label>Người Dùng</Label>
                            <Input readOnly type="text" id="userName" name="userName" value={userName} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="quantity">Số Lượng CCQ</Label>
                            <Input onChange={this.handleInputChange} value={quantity} type="number" id="quantity" min="0.01" max={maxQuantity} name="quantity" step=".01" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="transactionDate">Ngày Giao Dịch</Label>
                            <Input onChange={this.handleInputChange} value={transactionDate} type="datetime-local" required step="1" id="transactionDate" name="transactionDate" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="note">Ghi Chú</Label>
                            <Input onChange={this.handleInputChange} value={note} type="textarea" name="note" id="note" />
                        </FormGroup>
                    </ModalBody >
                    <ModalFooter>
                        <button class="btn btn-primary mb-2 me-2" type="submit">Bán</button>
                        <button class="btn btn-secondary mb-2 me-2" type="button" onClick={toggle}>Thoát</button>
                    </ModalFooter>
                </Form>
            </Modal >
        );
    }
}

export default SellCertificateModal;