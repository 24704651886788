import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import { CardFooter, FormGroup, InputGroup, Form, Row, Col, Card, CardHeader, CardBody, Label, Input, InputGroupText } from 'reactstrap';
import PortfolioService from '../../../services/portfolio.service';
import PortfolioTransactionService from '../../../services/portfolio.transaction.service';

class CalculateFinalTool extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.updateFinalCalculation = this.updateFinalCalculation.bind(this);
        this.loadFinalCalculation = this.loadFinalCalculation.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        currentNAVCCQ: 0,
        updatedNAVCCQ: 0,
        currentNumberOfCertificates: 0,
        updatedNumberOfCertificates: 0,
        currentLMCFundCCQ: 0,
        currentLMCFundCCQ: 0,
        navDauKy: null,
        navCuoiKy: null
    };

    componentDidMount() {
        this.loadFinalCalculation();
    }

    loadFinalCalculation() {
        new PortfolioService().loadFinalCalculation(this.props.match.params.portfolioId).then(
            response => {
                if (response.data) {
                    this.setState({
                        currentNAVCCQ: response.data.currentNAVCCQ,
                        updatedNAVCCQ: response.data.updatedNAVCCQ,
                        currentNumberOfCertificates: response.data.currentNumberOfCertificates,
                        updatedNumberOfCertificates: response.data.updatedNumberOfCertificates,
                        currentLMCFundCCQ: response.data.currentLMCFundCCQ,
                        updatedLMCFundCCQ: response.data.updatedLMCFundCCQ,
                        navDauKy: response.data.navccqDauKy,
                        navCuoiKy: response.data.navccqCuoiKy
                    })
                }
            },
            error => {
                this.props.history.push(`${this.props.match.url.replace("/calculatefinal", "")}`);
            }
        )
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    updateFinalCalculation() {
        const request = {
            portfolioId: this.props.match.params.portfolioId,
            currentNAVCCQ: this.state.currentNAVCCQ,
            currentNumberOfCertificates: this.state.currentNumberOfCertificates,
            currentLMCFundCCQ: this.state.currentLMCFundCCQ,
            updatedNAVCCQ: this.state.updatedNAVCCQ,
            updatedNumberOfCertificates: this.state.updatedNumberOfCertificates,
            updatedLMCFundCCQ: this.state.updatedLMCFundCCQ,
            navccqCuoiKyId: this.state.navCuoiKy.id
        };
        new PortfolioTransactionService().updateFinalCalculation(request).then(
            response => {
                if (response.data.success) {
                    this.props.updateOverview();
                    this.props.history.push(`${this.props.match.url.replace("/calculatefinal", "")}`);
                }
            },
            error => {
                this.props.history.push(`${this.props.match.url.replace("/calculatefinal", "")}`);
            }
        )
    }

    render() {
        const { match } = this.props;
        const { currentNAVCCQ,
            updatedNAVCCQ,
            currentNumberOfCertificates,
            updatedNumberOfCertificates,
            currentLMCFundCCQ,
            updatedLMCFundCCQ,
            navDauKy,
            navCuoiKy } = this.state;
        return (
            <Fragment>
                <Row>
                    {navDauKy == null ? (
                        <div className="loader-container">
                            <div className="loader-container-inner">
                                <h6 className="mt-5">
                                    Hệ thống đang xử lý ...
                                </h6>
                            </div>
                        </div>
                    ) : (
                        <>
                            <Col md="6">
                                <Card className="main-card mb-3">
                                    <CardHeader>
                                        <div className="card-header-title">
                                            <i className="header-icon lnr-magic-wand icon-gradient bg-plum-plate"> </i>
                                            Tính Toán Cuối Kỳ
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <Form onSubmit={this.updateFinalCalculation}>
                                            <FormGroup>
                                                <Label for="currentNAVCCQ">NAVCCQ Hiện Tại</Label>
                                                <InputGroup>
                                                    <Input readOnly={true} onChange={this.handleInputChange} value={currentNAVCCQ} type="number" id="currentNAVCCQ" name="currentNAVCCQ" />
                                                    <InputGroupText>đ</InputGroupText>
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="updatedNAVCCQ">NAVCCQ Sau Cập Nhật</Label>
                                                <InputGroup>
                                                    <Input readOnly={true} onChange={this.handleInputChange} value={updatedNAVCCQ} type="number" name="updatedNAVCCQ" id="updatedNAVCCQ" />
                                                    <InputGroupText>đ</InputGroupText>
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentNumberOfCertificates">Số Lượng CCQ Hiện Tại</Label>
                                                <Input readOnly={true} onChange={this.handleInputChange} value={currentNumberOfCertificates} type="number" name="currentNumberOfCertificates" id="currentNumberOfCertificates" />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="updatedNumberOfCertificates">Số Lượng CCQ Sau Cập Nhật</Label>
                                                <Input readOnly={true} onChange={this.handleInputChange} value={updatedNumberOfCertificates} type="number" name="updatedNumberOfCertificates" id="updatedNumberOfCertificates" />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentLMCFundCCQ">LMCFund CCQ Hiện Tại</Label>
                                                <Input readOnly={true} onChange={this.handleInputChange} value={currentLMCFundCCQ} type="number" name="currentLMCFundCCQ" id="currentLMCFundCCQ" />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="updatedLMCFundCCQ">LMCFund CCQ Sau Cập Nhật</Label>
                                                <Input readOnly={true} onChange={this.handleInputChange} value={updatedLMCFundCCQ} type="number" name="updatedLMCFundCCQ" id="updatedLMCFundCCQ" />
                                            </FormGroup>
                                            <button class="btn btn-primary mb-2 me-2" type="submit">Cập Nhật</button>
                                            <NavLink className="btn btn-secondary mb-2 me-2" to={`${match.url.replace("/calculatefinal", "")}`}>
                                                Thoát
                                            </NavLink>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6">
                                <Row>
                                    <Col md="12">
                                        <div class="card mb-3 widget-content">
                                            <div class="widget-content-outer">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left">
                                                        <div class="widget-heading">NAVCCQ Đầu Kỳ {navDauKy.isRoot ? "(gốc)" : ""}</div>
                                                        <div class="widget-subheading">{navDauKy.date}</div>
                                                    </div>
                                                    <div class="widget-content-right">
                                                        <div class="widget-numbers text-success">{navDauKy.value}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div class="card mb-3 widget-content">
                                            <div class="widget-content-outer">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left">
                                                        <div class="widget-heading">NAVCCQ Cuối Kỳ</div>
                                                        <div class="widget-subheading">{navCuoiKy.date}</div>
                                                    </div>
                                                    <div class="widget-content-right">
                                                        <div class="widget-numbers text-success">{navCuoiKy.value}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    )}
                </Row>
            </Fragment>
        );
    }
}

export default CalculateFinalTool;