import React, { Fragment } from "react";

class Error401 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        <div>You don't have permission to access this page.</div>
      </Fragment>
    );
  }
}

export default Error401;
