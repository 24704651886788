import React, { Fragment } from "react";
import { connect } from "react-redux";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import { Form, Input } from "reactstrap";
import AuthService from "../../services/auth.service";
import "../../assets/login.css";
import LoginImage from "../../assets/utils/images/logo_transparent72x72.png";
import authRoleAndPermissions from "../../services/auth-role";
import CustomerPortfolioService from "../../services/customer.portfolio.service";
import { authPermissions } from "../../utils/constants";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
    };
  }

  componentDidMount() {
    this.redirectIfAuthenticated();
  }

  redirectIfAuthenticated() {
    const identity = authRoleAndPermissions();
    if (identity) {
      if (identity.permissions.includes(authPermissions.Portfolio.List)) {
        this.props.history.push("/portfolio");
      } else {
        new CustomerPortfolioService().list().then(
          (response) => {
            let hasPortfolio = false;
            hasPortfolio =
              response.data.portfolios && response.data.portfolios.length > 0;

            if (hasPortfolio) {
              this.props.history.push("/customerportfolio/" + response.data.portfolios[0].id);
            } else {
              this.props.history.push("/user/me");
            }
          },
          (error) => {}
        );
      }
    }
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }
  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    new AuthService()
      .login(this.state.username, this.state.password)
      .then((data) => {
        this.redirectIfAuthenticated();
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          loading: false,
          message: e.response.data,
        });
      });
  }
  render() {
    const { message } = this.state;
    return (
      <Fragment>
        <div className="login">
          <div className="login-container">
            <Form
              className="form-signin text-center"
              onSubmit={this.handleLogin}
              ref={(c) => {
                this.form = c;
              }}
            >
              <img src={LoginImage}></img>
              <h4>Đăng nhập vào hệ thống LMCFUND</h4>
              <div className="form-group mt-3 mb-3">
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={this.state.username}
                  onChange={this.onChangeUsername}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  required
                />
              </div>
              {message && (
                <div className="form-group mb-3">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}

              <button
                className="btn btn-lg btn-primary btn-block"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Đăng Nhập</span>
              </button>
            </Form>
            <div class="text-center">
              <NavLink to={`/forgot-password`}>Quên mật khẩu</NavLink>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Login);
