import React, { Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, CardHeader, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AppPagination from '../../../../Blocks/AppPagination';
import PortfolioService from '../../../../services/portfolio.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import AddNAVCCQHistoryModal from './AddNAVCCQHistoryModal';

class PortfolioNAVCCQHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            histories: null,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false,
            addNAVCCQHistoryModalOpen: false,
            deleteNAVCCQHistoryModalOpen: false,
            deleteNAVCCQHistoryId: '',
            deleteNAVCCQHistoryDate: '',
            toggleRootNAVCCQModalOpen: false,
            toggleRootNAVCCQDate: '',
            toggleRootHistoryId: '',
            error: '',
            errorModalOpen: false,
            isLoading: false
        };
        this.addNAVCCQHistory = this.addNAVCCQHistory.bind(this);
        this.toggleRootNAVCCQ = this.toggleRootNAVCCQ.bind(this);
        this.deleteNAVCCQHistory = this.deleteNAVCCQHistory.bind(this);
    }

    toggleAddNAVCCQHistoryModal() {
        this.setState({ addNAVCCQHistoryModalOpen: !this.state.addNAVCCQHistoryModalOpen });
    }

    toggleRootNAVCCQModal() {
        this.setState({
            toggleRootNAVCCQModalOpen: !this.state.toggleRootNAVCCQModalOpen
        });
    }

    toggleErrorModal() {
        this.setState({ errorModalOpen: !this.state.errorModalOpen });
    }

    toggleDeleteNAVCCQModal(){
        this.setState({
            deleteNAVCCQHistoryModalOpen: !this.state.deleteNAVCCQHistoryModalOpen
        });
    }

    openToggleRootNAVCCQModal(date, historyId, isRoot) {
        this.setState({
            toggleRootNAVCCQModalOpen: true,
            toggleRootNAVCCQDate: date,
            toogleRootHistoryId: historyId,
            toggleRootCurrentStatus: isRoot
        });
    }

    openDeleteNAVCCQHistoryModal(date, historyId){
        this.setState({
            deleteNAVCCQHistoryModalOpen: true,
            deleteNAVCCQHistoryDate: date,
            deleteNAVCCQHistoryId: historyId
        });
    }

    toggleRootNAVCCQ() {
        this.setState({
            isLoading: true
        });
        var request = {
            portfolioId: this.props.match.params.portfolioId,
            historyId: this.state.toogleRootHistoryId
        };
        new PortfolioService().toggleRootNAVCCQ(request).then(
            response => {
                if (response.data) {
                    this.props.updateOverview();
                    this.setState({
                        histories: response.data.items,
                        totalPages: response.data.totalPages,
                        hasPreviousPage: response.data.hasPreviousPage,
                        hasNextPage: response.data.hasNextPage,
                        errorModalOpen: false,
                        toggleRootNAVCCQModalOpen: false,
                        toggleRootNAVCCQDate: '',
                        toggleRootHistoryId: '',
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        error: 'Lỗi server. Liên hệ lập trình viên',
                        errorModalOpen: true,
                        toggleRootNAVCCQModalOpen: false,
                        isLoading: false
                    });
                }
            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    toggleRootNAVCCQModalOpen: false,
                    isLoading: false
                });
            }
        );
    }

    addNAVCCQHistory(request) {
        this.setState({
            isLoading: true
        });
        new PortfolioService().addNAVCCQHistory(request).then(
            response => {
                if (response.data) {
                    this.props.updateOverview();
                    this.setState({
                        histories: response.data.items,
                        totalPages: response.data.totalPages,
                        hasPreviousPage: response.data.hasPreviousPage,
                        hasNextPage: response.data.hasNextPage,
                        errorModalOpen: false,
                        addNAVCCQHistoryModalOpen: false,
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        error: 'Lỗi server. Liên hệ lập trình viên',
                        errorModalOpen: true,
                        addNAVCCQHistoryModalOpen: false,
                        isLoading: false
                    });
                }
            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    addNAVCCQHistoryModalOpen: false,
                    isLoading: false
                });
            }
        );
    }

    deleteNAVCCQHistory() {
        this.setState({
            isLoading: true
        });
        var request = {
            portfolioId: this.props.match.params.portfolioId,
            historyId: this.state.deleteNAVCCQHistoryId
        };
        new PortfolioService().deleteNAVCCQHistory(request).then(
            response => {
                if (response.data) {
                    this.props.updateOverview();
                    this.setState({
                        histories: response.data.items,
                        totalPages: response.data.totalPages,
                        hasPreviousPage: response.data.hasPreviousPage,
                        hasNextPage: response.data.hasNextPage,
                        errorModalOpen: false,
                        deleteNAVCCQHistoryModalOpen: false,
                        deleteNAVCCQHistoryDate: '',
                        deleteNAVCCQHistoryId: '',
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        error: 'Lỗi server. Liên hệ lập trình viên',
                        errorModalOpen: true,
                        deleteNAVCCQHistoryModalOpen: false,
                        isLoading: false
                    });
                }
            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    deleteNAVCCQHistoryModalOpen: false,
                    isLoading: false
                });
            }
        );
    }

    componentDidMount() {
        this.loadNAVCCQHistories();
    }

    loadNAVCCQHistories() {
        this.setState({
            isLoading: true
        });
        let { match } = this.props;
        let page = parseInt(match.params.page) ?? 1;
        new PortfolioService().navccqHistories(match.params.portfolioId, page).then(
            response => {
                this.setState({
                    histories: response.data.items,
                    totalPages: response.data.totalPages,
                    hasPreviousPage: response.data.hasPreviousPage,
                    hasNextPage: response.data.hasNextPage,
                    isLoading: false
                });
            },
            error => {
                this.setState({
                    error: error.message,
                    isLoading: false
                })
            }
        );
    }

    render() {
        let { match } = this.props;
        let parentUrl = match.url.substring(0, match.url.lastIndexOf("/"));
        let page = parseInt(match.params.page) ?? 1;
        let { histories, hasNextPage, hasPreviousPage, totalPages, isLoading, 
            addNAVCCQHistoryModalOpen, toggleRootNAVCCQModalOpen, toggleRootCurrentStatus,
            deleteNAVCCQHistoryModalOpen, deleteNAVCCQHistoryDate } = this.state;
        return (
            <Fragment>
                <TransitionGroup>
                    <CSSTransition
                        component="div"
                        className="TabsAnimation"
                        appear={true}
                        timeout={0}
                        enter={false}
                        exit={false}>
                        <Row>
                            {isLoading ? (
                                <div className="loader-container">
                                    <div className="loader-container-inner">
                                        <h6 className="mt-5">
                                            Hệ thống đang xử lý ...
                                        </h6>
                                    </div>
                                </div>
                            ) : (
                                <Col lg="12">
                                    <Card className="main-card mb-3">
                                        <CardHeader>
                                            <div className="card-header-title">
                                                <i className="header-icon lnr-list icon-gradient bg-plum-plate"> </i>
                                                Lịch Sử NAVCCQ
                                            </div>
                                            <div class="btn-actions-pane-right">
                                                <button class="btn btn-primary mb-2 me-2" onClick={() => this.toggleAddNAVCCQHistoryModal()}>Thêm Lịch Sử NAVCCQ</button>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <div class="table-responsive">
                                                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">Thời Gian</th>
                                                            <th class="text-center">Giá Trị</th>
                                                            <th class="text-center">Giá Trị Sau Phí</th>
                                                            <th class="text-center">Gốc</th>
                                                            <th class="text-center">...</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {histories && histories.map(h => (
                                                            <tr>
                                                                <td class="text-center">
                                                                    {h.date}
                                                                </td>
                                                                <td class="text-center">
                                                                    {h.value}
                                                                </td>
                                                                <td class="text-center">
                                                                    {h.valueAfterFee}
                                                                </td>
                                                                <td class="text-center">
                                                                    {h.isRoot ? (
                                                                        <FontAwesomeIcon icon={faCheck} className="text-primary" />
                                                                    ) : (
                                                                        <FontAwesomeIcon icon={faTimes} className="text-danger" />
                                                                    )}
                                                                </td>
                                                                <td class="text-center">
                                                                    <button class="btn btn-warning mb-2 me-2" onClick={() => this.openToggleRootNAVCCQModal(h.date, h.id, h.isRoot)}>
                                                                        {h.isRoot ? 'Xóa Đánh Dấu Gốc' : 'Đánh Dấu Gốc'}
                                                                    </button>
                                                                    <button class="btn btn-primary mb-2 me-2" onClick={() => this.openDeleteNAVCCQHistoryModal(h.date, h.id)}>Xóa</button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                        <CardFooter className='justify-content-center'>
                                            <AppPagination totalPages={totalPages} parentUrl={parentUrl} page={page} hasNextPage={hasNextPage} hasPreviousPage={hasPreviousPage} />
                                        </CardFooter>
                                    </Card>
                                    <AddNAVCCQHistoryModal handleSubmit={this.addNAVCCQHistory} portfolioId={this.props.match.params.portfolioId} toggle={() => this.toggleAddNAVCCQHistoryModal()} open={addNAVCCQHistoryModalOpen} />
                                    <Modal isOpen={toggleRootNAVCCQModalOpen} fade={false} toggle={() => this.toggleRootNAVCCQModal()}>
                                        <ModalHeader toggle={() => this.toggleRootNAVCCQModal()}>Xác Nhận</ModalHeader>
                                        <ModalBody>
                                            {toggleRootCurrentStatus ? (
                                                <p>Bạn muốn hủy đánh dấu lịch sử <strong>{this.state.toggleRootNAVCCQDate}</strong> ?</p>
                                            ) : (
                                                <p>Bạn muốn đánh dấu lịch sử <strong>{this.state.toggleRootNAVCCQDate}</strong> là gốc ?</p>
                                            )}
                                        </ModalBody>
                                        <ModalFooter>
                                            <button class="btn btn-primary mb-2 me-2" onClick={this.toggleRootNAVCCQ}>Có</button>
                                            <button class="btn btn-secondary mb-2 me-2" onClick={() => this.toggleRootNAVCCQModal()}>Không</button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal isOpen={deleteNAVCCQHistoryModalOpen} fade={false} toggle={() => this.toggleDeleteNAVCCQModal()}>
                                        <ModalHeader toggle={() => this.toggleDeleteNAVCCQModal()}>Xác Nhận Xóa</ModalHeader>
                                        <ModalBody>
                                            Bạn muốn xóa lịch sử <strong>{deleteNAVCCQHistoryDate}</strong> ?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button class="btn btn-primary mb-2 me-2" onClick={this.deleteNAVCCQHistory}>Có</button>
                                            <button class="btn btn-secondary mb-2 me-2" onClick={() => this.toggleDeleteNAVCCQModal()}>Không</button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal isOpen={this.state.errorModalOpen} fade={false} toggle={() => this.toggleErrorModal()}>
                                        <ModalHeader className='text-danger' toggle={() => this.toggleErrorModal()}><i class="lnr-warning">&nbsp;</i>Lỗi</ModalHeader>
                                        <ModalBody>
                                            {this.state.error}
                                        </ModalBody>
                                    </Modal>
                                </Col>
                            )}
                        </Row>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}

export default withRouter(PortfolioNAVCCQHistory);
