import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Collapse, CardHeader, ModalBody, Modal, ModalHeader } from 'reactstrap';
import PortfolioService from '../../../../services/portfolio.service';
import PortfolioTransactionService from '../../../../services/portfolio.transaction.service';
import SellModal from './SellModal';
import BuyModal from './BuyModal';
import SortModal from './SortModal';
import AddCashSourceModal from './AddCashSourceModal';
import TransferCashSourceModal from './TransferCashSourceModal';

class PortfolioAssets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cashSources: [],
            assets: [],
            totalCash: "",
            cashSourcesExpanded: false,
            sellModalOpen: false,
            buyModalOpen: false,
            error: "",
            sellAssetName: '',
            sellAssetType: 0,
            sellAssetMaxQuantity: 0,
            buyAssetName: '',
            buyAssetType: '',
            stocks: [],
            errorModalOpen: false,
            assetSortModalOpen: false,
            cashSourceSortModalOpen: false,
            addCashSourceModalOpen: false,
            transferCashSourceModalOpen: false,
            deleteCashSourceModalOpen: false,
            deleteCashSource: ''
        };
        this.buyAsset = this.buyAsset.bind(this);
        this.sellAsset = this.sellAsset.bind(this);
        this.loadAssets = this.loadAssets.bind(this);
        this.updateAssetsOrder = this.updateAssetsOrder.bind(this);
        this.updateCashSourcesOrder = this.updateCashSourcesOrder.bind(this);
        this.addCashSource = this.addCashSource.bind(this);
        this.transferCashSource = this.transferCashSource.bind(this);
        this.deleteCashSource = this.deleteCashSource.bind(this);
    }

    componentDidMount() {
        this.loadAssets();
    }

    loadAssets() {
        let { match } = this.props;
        new PortfolioService().assets(match.params.portfolioId).then(
            response => {
                this.setState({
                    assets: response.data.assets,
                    cashSources: response.data.cashSources,
                    totalCash: response.data.totalCash,
                    stocks: response.data.stocks
                });
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    toggleCashSourcesDropdown() {
        this.setState({ cashSourcesExpanded: !this.state.cashSourcesExpanded });
    }

    toggleSellModal() {
        this.setState({ sellModalOpen: !this.state.sellModalOpen });
    }

    toggleErrorModal() {
        this.setState({ errorModalOpen: !this.state.errorModalOpen });
    }

    toggleBuyModal() {
        this.setState({ buyModalOpen: !this.state.buyModalOpen });
    }

    toggleAssetSortModal() {
        this.setState({ assetSortModalOpen: !this.state.assetSortModalOpen });
    }

    toggleCashSourceSortModal(e) {
        this.setState({ cashSourceSortModalOpen: !this.state.cashSourceSortModalOpen });
    }

    toggleAddCashSourceModal() {
        this.setState({ addCashSourceModalOpen: !this.state.addCashSourceModalOpen });
    }

    toggleTransferCashSourceModal() {
        this.setState({ transferCashSourceModalOpen: !this.state.transferCashSourceModalOpen });
    }

    toggleDeleteCashSourceModal(){
        this.setState({ deleteCashSourceModalOpen: !this.state.deleteCashSourceModalOpen });
    }

    openBuyModal(assetName, assetType) {
        this.setState({ buyModalOpen: true, buyAssetName: assetName, buyAssetType: assetType })
    }

    openSellModal(assetName, assetType, maxQuantity) {
        this.setState({ sellModalOpen: true, sellAssetName: assetName, sellAssetType: assetType === 'Khác' ? 1 : 0, sellAssetMaxQuantity: maxQuantity });
    }

    openDeleteCashSourceModal(cashSource){
        this.setState({ deleteCashSourceModalOpen: true, deleteCashSource: cashSource });
    }

    updateAssetsOrder(assets) {
        let request = {
            portfolioId: this.props.match.params.portfolioId,
            assets: assets
        };
        new PortfolioService().sortAsset(request).then(
            response => {
                this.setState({
                    assets: response.data.assets,
                    assetSortModalOpen: false
                });
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    updateCashSourcesOrder(cashSources) {
        let request = {
            portfolioId: this.props.match.params.portfolioId,
            cashSources: cashSources
        };
        new PortfolioService().sortCashSource(request).then(
            response => {
                this.setState({
                    cashSources: response.data.cashSources,
                    cashSourceSortModalOpen: false
                });
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    buyAsset(request) {
        new PortfolioTransactionService().buyAsset(request).then(
            response => {
                if (response.data.success) {
                    this.props.updateOverview();
                    this.loadAssets();
                    this.setState({
                        errorModalOpen: false,
                        buyModalOpen: false,
                        buyAssetName: '',
                        buyAssetType: ''
                    });
                } else {
                    this.setState({
                        error: response.data.message,
                        errorModalOpen: true,
                        buyModalOpen: false
                    });
                }

            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    buyModalOpen: false
                });
            }
        );
    }

    sellAsset(request) {
        new PortfolioTransactionService().sellAsset(request).then(
            response => {
                if (response.data.success) {
                    this.props.updateOverview();
                    this.loadAssets();
                    this.setState({
                        errorModalOpen: false,
                        sellModalOpen: false,
                        sellAssetName: '',
                        sellAssetType: 'Khác'
                    });
                } else {
                    this.setState({
                        error: response.data.message,
                        errorModalOpen: true,
                        sellModalOpen: false
                    });
                }

            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    sellModalOpen: false
                });
            }
        );
    }

    addCashSource(request) {
        request.portfolioId = this.props.match.params.portfolioId;
        new PortfolioTransactionService().addCashSource(request).then(
            response => {
                if (response.data.success) {
                    this.props.updateOverview();
                    this.loadAssets();
                    this.setState({
                        errorModalOpen: false,
                        addCashSourceModalOpen: false
                    });
                } else {
                    this.setState({
                        error: response.data.message,
                        errorModalOpen: true,
                        addCashSourceModalOpen: false
                    });
                }

            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    addCashSourceModalOpen: false
                });
            }
        );
    }

    transferCashSource(request) {
        request.portfolioId = this.props.match.params.portfolioId;
        new PortfolioTransactionService().transferCashSource(request).then(
            response => {
                if (response.data.success) {
                    this.props.updateOverview();
                    this.loadAssets();
                    this.setState({
                        errorModalOpen: false,
                        transferCashSourceModalOpen: false
                    });
                } else {
                    this.setState({
                        error: response.data.message,
                        errorModalOpen: true,
                        transferCashSourceModalOpen: false
                    });
                }

            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    transferCashSourceModalOpen: false
                });
            }
        );
    }

    deleteCashSource(){
        let request = {
            portfolioId: this.props.match.params.portfolioId,
            cashSource: this.state.deleteCashSource
        };
        new PortfolioTransactionService().deleteCashSource(request).then(
            response => {
                if (response.data.success) {
                    this.props.updateOverview();
                    this.loadAssets();
                    this.setState({
                        errorModalOpen: false,
                        deleteCashSourceModalOpen: false
                    });
                } else {
                    this.setState({
                        error: response.data.message,
                        errorModalOpen: true,
                        deleteCashSourceModalOpen: false
                    });
                }

            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    deleteCashSourceModalOpen: false
                });
            }
        );
    }

    render() {
        let { cashSources,
            assets,
            totalCash,
            cashSourcesExpanded,
            sellAssetName, sellModalOpen,
            buyAssetName, buyModalOpen, buyAssetType, sellAssetType, stocks, sellAssetMaxQuantity, addCashSourceModalOpen, transferCashSourceModalOpen } = this.state;
        return (
            <Fragment>
                <TransitionGroup>
                    <CSSTransition
                        component="div"
                        className="TabsAnimation"
                        appear={true}
                        timeout={0}
                        enter={false}
                        exit={false}>
                        <Row>
                            <Col lg="12">
                                <Card className="main-card mb-3">
                                    <CardHeader>
                                        <div className="card-header-title">
                                            <i className="header-icon lnr-briefcase icon-gradient bg-plum-plate"> </i>
                                            Tài Sản
                                        </div>
                                        <div class="btn-actions-pane-right">
                                            <button class="btn btn-primary mb-2 me-2" onClick={() => this.openBuyModal('', '')}>Mua</button>
                                            <button class="btn btn-primary mb-2 me-2" onClick={() => this.toggleAssetSortModal()}>
                                                <i class="lnr-move">&nbsp;</i>Sắp Xếp
                                            </button>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <div class="table-responsive">
                                            <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">Tên</th>
                                                        <th class="text-center">Loại</th>
                                                        <th class="text-center">Số Lượng</th>
                                                        <th class="text-center">Giá Vốn Trung Bình</th>
                                                        <th class="text-center">Giá Hiện Tại</th>
                                                        <th class="text-center">Tổng Giá Trị</th>
                                                        <th class="text-center">Lời/Lỗ</th>
                                                        <th class="text-center">...</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center">
                                                            Tiền Mặt
                                                        </td>
                                                        <td class="text-center">
                                                            Khác
                                                        </td>
                                                        <td class="text-center">
                                                            1
                                                        </td>
                                                        <td class="text-center">
                                                            ...
                                                        </td>
                                                        <td class="text-center">
                                                            ...
                                                        </td>
                                                        <td class="text-center">
                                                            {totalCash}
                                                        </td>
                                                        <td class="text-center">
                                                            ...
                                                        </td>
                                                        <td class="text-center">
                                                            <button onClick={() => this.toggleCashSourcesDropdown()} class="btn btn-primary mb-2 me-2">Chi Tiết</button>
                                                            <button class="btn btn-primary mb-2 me-2" onClick={() => this.toggleAddCashSourceModal()}>Thêm Nguồn Tiền</button>
                                                            <button class="btn btn-primary mb-2 me-2" onClick={() => this.toggleTransferCashSourceModal()}>Chuyển Tiền</button>
                                                            <button onClick={() => this.toggleCashSourceSortModal()} class="btn btn-primary mb-2 me-2"><i class="lnr-move">&nbsp;</i>Sắp Xếp</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="8">
                                                            <Collapse isOpen={cashSourcesExpanded}>
                                                                <table class="align-middle mb-0 table table-sm">
                                                                    <thead>
                                                                        <tr class="table-info">
                                                                            <th class="text-center">Nguồn</th>
                                                                            <th class="text-center">Giá Trị</th>
                                                                            <th class="text-center">Tỉ Lệ</th>
                                                                            <th class="text-center">...</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {cashSources && cashSources.map((cs, i) => (
                                                                            <tr class="table-info">
                                                                                <td class="text-center">
                                                                                    {cs.name}
                                                                                </td>
                                                                                <td class="text-center">
                                                                                    {cs.value}
                                                                                </td>
                                                                                <td class="text-center">
                                                                                    {cs.proportion}
                                                                                </td>
                                                                                <td class="text-center">
                                                                                    {i != 0 ?
                                                                                        (<button type="submit"
                                                                                            class="btn btn-primary" onClick={() => this.openDeleteCashSourceModal(cs.name)}>Xóa</button>)
                                                                                        :
                                                                                        (<>...</>)
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </Collapse>
                                                        </td>
                                                    </tr>
                                                    {assets && assets.map(a => (
                                                        <tr>
                                                            <td class="text-center">
                                                                {a.name}
                                                            </td>
                                                            <td class="text-center">
                                                                {a.type}
                                                            </td>
                                                            <td class="text-center">
                                                                {a.quantity}
                                                            </td>
                                                            <td class="text-center">
                                                                {a.averagePrice}
                                                            </td>
                                                            <td class="text-center">
                                                                {a.currentPrice}
                                                            </td>
                                                            <td class="text-center">
                                                                {a.totalValue}
                                                            </td>
                                                            <td class="text-center">
                                                                {a.profitOrLoss}
                                                            </td>
                                                            <td class="text-center">
                                                                <button class="btn btn-primary mb-2 me-2" onClick={() => this.openBuyModal(a.name, a.type)}>Mua</button>
                                                                <button class="btn btn-primary mb-2 me-2" onClick={() => this.openSellModal(a.name, a.type, a.quantity)}>Bán</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <SellModal handleSubmit={this.sellAsset} portfolioId={this.props.match.params.portfolioId} toggle={() => this.toggleSellModal()} cashSources={cashSources} assetName={sellAssetName} assetType={sellAssetType} maxQuantity={sellAssetMaxQuantity} open={sellModalOpen} />
                                        <BuyModal handleSubmit={this.buyAsset} portfolioId={this.props.match.params.portfolioId} toggle={() => this.toggleBuyModal()} cashSources={cashSources} assetName={buyAssetName} stocks={stocks} open={buyModalOpen} assetType={buyAssetType} />
                                        <Modal isOpen={this.state.errorModalOpen} fade={false} toggle={() => this.toggleErrorModal()}>
                                            <ModalHeader className='text-danger' toggle={() => this.toggleErrorModal()}><i class="lnr-warning">&nbsp;</i>Lỗi</ModalHeader>
                                            <ModalBody>
                                                {this.state.error}
                                            </ModalBody>
                                        </Modal>
                                        {assets && (
                                            <SortModal title="Sắp Xếp Tài Sản" updateSortedItems={this.updateAssetsOrder} items={assets.map(a => a.name)} toggle={() => this.toggleAssetSortModal()} open={this.state.assetSortModalOpen} />
                                        )}
                                        {cashSources && (
                                            <SortModal title="Sắp Xếp Nguồn Tiền" updateSortedItems={this.updateCashSourcesOrder} items={cashSources.slice(1).map(c => c.name)} toggle={() => this.toggleCashSourceSortModal()} open={this.state.cashSourceSortModalOpen} />
                                        )}
                                        <AddCashSourceModal handleSubmit={this.addCashSource} cashSources={cashSources} open={addCashSourceModalOpen} toggle={() => this.toggleAddCashSourceModal()} />
                                        <TransferCashSourceModal handleSubmit={this.transferCashSource} cashSources={cashSources} open={transferCashSourceModalOpen} toggle={() => this.toggleTransferCashSourceModal()} />
                                        <Modal isOpen={this.state.deleteCashSourceModalOpen} fade={false} toggle={() => this.toggleDeleteCashSourceModal()}>
                                            <ModalHeader toggle={() => this.toggleDeleteCashSourceModal()}><i class="lnr-warning">&nbsp;</i>Bạn có chắc muốn xóa nguồn tiền <strong>{this.state.deleteCashSource}</strong>?</ModalHeader>
                                            <ModalBody>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <button class="btn btn-primary mb-2 me-2" onClick={this.deleteCashSource}>Có</button>
                                                        <button class="btn btn-primary mb-2 me-2" onClick={() => this.toggleDeleteCashSourceModal()}>Không</button>
                                                    </div>
                                                </div>
                                            </ModalBody>
                                        </Modal>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}

export default withRouter(PortfolioAssets);