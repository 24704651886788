import React, { Fragment } from "react";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { NavLink, Redirect } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Collapse,
  CardHeader,
  ModalBody,
  Modal,
  ModalHeader,
} from "reactstrap";
import UserService from "../../../services/user.service";
import AddUserModal from "./AddUserModal";
import DeleteUserModal from "./DeleteUserModal";
import UserImpersonateService from "../../../services/user.impersonate.service";
import { parseJwt } from "../../../common/utils";
import { authRoles } from "../../../utils/constants";
import ResetPasswordModal from "./ChangePasswordModal";
import ChangeRoleModal from "./ChangeRoleModal";
import ChangeEmailModal from "./ChangeEmailModal";
class UserListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      error: "",
      addUserModelOpen: false,
      deleteUserModelOpen: false,
      changePasswordModelOpen: false,
      changeEmailModelOpen: false,
      errorModalOpen: false,
      changeRoleModelOpen: false,
      userIdToBeDeleted: "",
      userIdToBeChangedPassword: "",
      userIdToBeChangedRole: "",
      userRoleToBeChangedRole: "",
      userIdToBeChangedEmail: "",
    };

    this.loadUsers = this.loadUsers.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.createUser = this.createUser.bind(this);
    this.changeRole = this.changeRole.bind(this);
    this.toggleDeleteUserModal = this.toggleDeleteUserModal.bind(this);
    this.toggleChangeRoleModal = this.toggleChangeRoleModal.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
  }

  loadUsers() {
    new UserService().list().then(
      (response) => {
        this.setState({
          users: response.data,
          error: "",
        });
      },
      (error) => {
        this.setState({
          users: [],
          error: error.message,
        });
      }
    );
  }

  createUser(request) {
    new UserService().create(request).then(
      (response) => {
        this.loadUsers();
        this.setState({
          addUserModelOpen: false,
        });
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
          addUserModelOpen: false,
        });
      }
    );
  }

  deleteUser(id) {
    new UserService().delete(id).then(
      (response) => {
        this.setState({
          users: this.state.users.filter((user) => user.id !== id),
          deleteUserModelOpen: false,
        });
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
        });
      }
    );
  }

  changePassword(request) {
    request.userId = this.state.userIdToBeChangedPassword;
    new UserService().resetpassword(request).then(
      (response) => {
        this.setState({
          changePasswordModelOpen: false,
        });
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
        });
      }
    );
  }

  changeEmail(request) {
    request.userId = this.state.userIdToBeChangedEmail;
    new UserService().changeemail(request).then(
      (response) => {
        this.setState({
          changeEmailModelOpen: false,
        });
        this.loadUsers();
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
        });
      }
    );
  }

  changeRole(request) {
    new UserService().changeRole(request).then(
      (response) => {
        this.setState({
          changeRoleModelOpen: false,
        });
        this.loadUsers();
      },
      (error) => {
        this.setState({
          changeRoleModelOpen: false,
          error: error.message,
          errorModalOpen: true,
        });
      }
    );
  }

  componentDidMount() {
    let isAdmin = false;
    let user = JSON.parse(localStorage.user);
    if (user && user.token) {
      const identity = parseJwt(user.token);
      if (identity && identity.role === authRoles.ADMIN) {
        isAdmin = true;
      }
    }

    if (!isAdmin) return <Redirect to={`/user/me`} />;
    this.loadUsers();
  }

  toggleAddUserModal() {
    this.setState({ addUserModelOpen: !this.state.addUserModelOpen });
  }

  toggleDeleteUserModal(id) {
    if (id) {
      this.setState({
        userIdToBeDeleted: id,
      });
    }
    this.setState({
      deleteUserModelOpen: !this.state.deleteUserModelOpen,
    });
  }

  toggleChangePasswordModal(id) {
    if (id) {
      this.setState({
        userIdToBeChangedPassword: id,
      });
    }
    this.setState({
      changePasswordModelOpen: !this.state.changePasswordModelOpen,
    });
  }

  toggleChangeEmailModal(id) {
    if (id) {
      this.setState({
        userIdToBeChangedEmail: id,
      });
    }
    this.setState({
      changeEmailModelOpen: !this.state.changeEmailModelOpen,
    });
  }

  toggleChangeRoleModal(id, roleName) {
    if (id !== null) {
      this.setState({
        userRoleToBeChangedRole: roleName,
        userIdToBeChangedRole: id,
      });
    }

    this.setState({
      changeRoleModelOpen: !this.state.changeRoleModelOpen,
    });
  }

  toggleErrorModal() {
    this.setState({ errorModalOpen: !this.state.errorModalOpen });
  }

  startImpersonating(userId) {
    new UserImpersonateService().startImpersonating({ userId: userId }).then(
      (response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
          this.props.history.push("/");
        }
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
        });
      }
    );
  }

  render() {
    let { match } = this.props;
    let {
      addUserModelOpen,
      deleteUserModelOpen,
      userIdToBeDeleted,
      changePasswordModelOpen,
      changeEmailModelOpen,
      userIdToBeChangedPassword,
      changeRoleModelOpen,
      userRoleToBeChangedRole,
      userIdToBeChangedRole,
      userIdToBeChangedEmail
    } = this.state;
    return (
      <Fragment>
        <PageTitle
          heading="Danh sách người dùng"
          icon="pe-7s-portfolio icon-gradient bg-amy-crisp"
        />
        <Row>
          <Col className="col-12">
            <Card>
              <CardHeader>
                <div className="card-header-title">Danh mục</div>
                <div className="btn-actions-pane-right">
                  <button
                    class="btn btn-primary mb-2 me-2"
                    onClick={() => this.toggleAddUserModal()}
                  >
                    <i className="lnr-file-add"> </i>
                    Thêm
                  </button>
                </div>
              </CardHeader>
              <CardBody>
                <div class="table-responsive">
                  <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                      <tr>
                        <th class="text-center">#</th>
                        <th>Tên đăng nhập</th>
                        <th class="text-center">Email</th>
                        <th class="text-center" style={{display:'none'}}>Tình Trạng</th>
                        <th class="text-center"></th>
                        <th class="text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.users &&
                        this.state.users.map((user, i) => {
                          return (
                            <tr>
                              <td class="text-center" scope="row">
                                {i + 1}
                              </td>
                              <td>
                                <div class="widget-content p-0">
                                  <div class="widget-content-wrapper">
                                    <div class="widget-content-left mr-3">
                                      <div class="widget-content-left">
                                        <img
                                          width="40"
                                          class="rounded-circle"
                                          src="default-user.png"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div class="widget-content-left flex2">
                                      <div class="widget-heading">
                                        <NavLink to={`${match.url}/` + user.id}>
                                          {user.username}
                                        </NavLink>
                                      </div>
                                      <div class="widget-subheading opacity-7">
                                        {user.roleName}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td class="text-center">{user.email}</td>
                              <td class="text-center" style={{display:'none'}}>
                                {user.isActive ? (
                                  <div className="badge bg-success">
                                    Đang hoạt động
                                  </div>
                                ) : (
                                  <div className="badge bg-warning">
                                    Không hoạt động
                                  </div>
                                )}
                              </td>
                              <td class="text-center">
                                <button
                                  class="btn btn-warning mb-2 me-2"
                                  onClick={() =>
                                    this.toggleChangeEmailModal(user.id)
                                  }
                                >
                                  Change Email
                                </button>
                                <button
                                  class="btn btn-warning mb-2 me-2"
                                  onClick={() =>
                                    this.startImpersonating(user.id)
                                  }
                                >
                                  Impersonating
                                </button>
                                <button
                                  class="btn btn-warning mb-2 me-2"
                                  onClick={() =>
                                    this.toggleChangePasswordModal(user.id)
                                  }
                                >
                                  Reset password
                                </button>
                                <button
                                  class="btn btn-warning mb-2 me-2"
                                  onClick={() =>
                                    this.toggleChangeRoleModal(
                                      user.id,
                                      user.roleName
                                    )
                                  }
                                >
                                  Change role
                                </button>
                              </td>
                              <td class="text-center">
                                <button
                                  class="btn btn-danger mb-2 me-2"
                                  onClick={() =>
                                    this.toggleDeleteUserModal(user.id)
                                  }
                                >
                                  <i className="lnr-trash"> </i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <AddUserModal
          handleSubmit={this.createUser}
          open={addUserModelOpen}
          toggle={() => this.toggleAddUserModal()}
        />
        <DeleteUserModal
          handleSubmit={this.deleteUser}
          open={deleteUserModelOpen}
          userIdToBeDeleted={userIdToBeDeleted}
          toggle={() => this.toggleDeleteUserModal("")}
        />

        <ResetPasswordModal
          handleSubmit={this.changePassword}
          open={changePasswordModelOpen}
          userIdToBeChangedPassword={userIdToBeChangedPassword}
          toggle={() => this.toggleChangePasswordModal()}
        />

        <ChangeEmailModal
          handleSubmit={this.changeEmail}
          open={changeEmailModelOpen}
          userIdToBeChangedEmail={userIdToBeChangedEmail}
          toggle={() => this.toggleChangeEmailModal()}
        />

        <ChangeRoleModal
          handleSubmit={this.changeRole}
          open={changeRoleModelOpen}
          userIdToBeChangedRole={userIdToBeChangedRole}
          roleName={userRoleToBeChangedRole}
          toggle={() => this.toggleChangeRoleModal()}
        />

        <Modal
          isOpen={this.state.errorModalOpen}
          fade={false}
          toggle={() => this.toggleErrorModal()}
        >
          <ModalHeader
            className="text-danger"
            toggle={() => this.toggleErrorModal()}
          >
            <i class="lnr-warning">&nbsp;</i>Lỗi
          </ModalHeader>
          <ModalBody>{this.state.error}</ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default UserListing;
