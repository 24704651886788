import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

class ChangePasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  initialState = {
    userId: this.props.userId,
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    oldPasswordError: "",
    newPasswordError: "",
    confirmNewPasswordError: "",
  };

  validateInput(event) {
    let { newPassword, confirmNewPassword } = this.state;
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    switch (name) {
      case "confirmNewPassword":
        if (!value) {
          this.setState({
            confirmNewPasswordError: "Đây là trường bắt buộc nhập!",
          });
        } else {
          if (value != newPassword) {
            this.setState({
              confirmNewPasswordError: "Mật khẩu không trùng khớp",
            });
          } else {
            this.setState({ confirmNewPasswordError: null });
          }
        }
        break;

      case "newPassword":
        if (!value) {
          this.setState({
            newPasswordError: "Đây là trường bắt buộc nhập!",
          });
        } else {
          if (value != confirmNewPassword) {
            this.setState({
              confirmNewPasswordError: "Mật khẩu không trùng khớp",
            });
          } else {
            this.setState({
              confirmNewPasswordError: null,
              newPasswordError: null,
            });
          }
        }
        break;

      case "oldPassword":
        if (!value) {
          this.setState({
            oldPasswordError: "Đây là trường bắt buộc nhập!",
          });
        } else {
          this.setState({
            oldPasswordError: null,
          });
        }
        break;
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });

    this.validateInput(event);
  }

  changeUserPassword(e) {
    e.preventDefault();
    this.props.handleSubmit(this.state);
    this.setState(this.initialState);
  }

  render() {
    let { open, toggle } = this.props;
    let { oldPasswordError, newPasswordError, confirmNewPasswordError } =
      this.state;
    return (
      <>
        <Modal isOpen={open} fade={false} toggle={toggle}>
          <ModalHeader toggle={toggle}>Đổi mật khẩu</ModalHeader>
          <ModalBody>
            <div class="row">
              <div class="col-md-12">
                <Form onSubmit={(e) => this.changeUserPassword(e)}>
                  <FormGroup>
                    <Label>Mật khẩu cũ</Label>
                    <Input
                      onChange={this.handleInputChange}
                      type="password"
                      required
                      placeholder="**********"
                      name="oldPassword"
                    />
                    {oldPasswordError && (
                      <span className="text-danger">{oldPasswordError}</span>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Mật khẩu mới</Label>
                    <Input
                      onChange={this.handleInputChange}
                      type="password"
                      required
                      placeholder="**********"
                      name="newPassword"
                    />
                    {newPasswordError && (
                      <span className="text-danger">{newPasswordError}</span>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Nhập lại mật khẩu mới</Label>
                    <Input
                      onChange={this.handleInputChange}
                      type="password"
                      required
                      placeholder="**********"
                      name="confirmNewPassword"
                    />
                    {confirmNewPasswordError && (
                      <span className="text-danger">
                        {confirmNewPasswordError}
                      </span>
                    )}
                  </FormGroup>
                  <Button
                    className="mb-2 me-2 btn-primary"
                    type="submit"
                    disabled={
                      confirmNewPasswordError ||
                      newPasswordError ||
                      oldPasswordError
                    }
                  >
                    Đồng ý
                  </Button>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default ChangePasswordModal;
