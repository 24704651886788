import React, { Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, CardHeader, CardFooter,  ModalBody, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import AppPagination from '../../../../Blocks/AppPagination';
import CustomerPortfolioService from '../../../../services/customer.portfolio.service';

class CustomerPortfolioLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logs: null,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false
        };
    }

    componentDidMount() {
        this.loadLogs();
    }

    loadLogs() {
        let { match } = this.props;
        let page = parseInt(match.params.page) ?? 1;
        new CustomerPortfolioService().logs(match.params.portfolioId, page).then(
            response => {
                this.setState({
                    logs: response.data.items,
                    totalPages: response.data.totalPages,
                    hasPreviousPage: response.data.hasPreviousPage,
                    hasNextPage: response.data.hasNextPage
                });
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    render() {
        let { match } = this.props;
        let parentUrl = match.url.substring(0, match.url.lastIndexOf("/"));
        let page = parseInt(match.params.page) ?? 1;
        let { logs, hasNextPage, hasPreviousPage, totalPages } = this.state;
        return (
            <Fragment>
                <TransitionGroup>
                    <CSSTransition
                        component="div"
                        className="TabsAnimation"
                        appear={true}
                        timeout={0}
                        enter={false}
                        exit={false}>
                        <Row>
                            <Col lg="12">
                                <Card className="main-card mb-3">
                                    <CardHeader>
                                        <div className="card-header-title">
                                            <i className="header-icon lnr-sync icon-gradient bg-plum-plate"> </i>
                                            Nhật Ký Danh Mục Đầu Tư
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <div class="table-responsive">
                                            <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">Ngày</th>
                                                        <th class="text-center">Nội Dung</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {logs && logs.map(l => (
                                                        <tr>
                                                            <td class="text-center">
                                                                {l.date}
                                                            </td>
                                                            <td dangerouslySetInnerHTML={{ __html: l.message }} class="text-center">
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                    <CardFooter className='justify-content-center'>
                                        <AppPagination totalPages={totalPages} parentUrl={parentUrl} page={page} hasNextPage={hasNextPage} hasPreviousPage={hasPreviousPage} />
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}

export default withRouter(CustomerPortfolioLog);
