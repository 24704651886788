import React from 'react';
import { Line } from 'react-chartjs-2';

class LineChart extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.dataLabels && this.props.values) {
      let chartData = {
        labels: this.props.dataLabels,
        datasets: [
          {
            label: this.props.label,
            fill: false,
            backgroundColor: '#ed0f51',
            borderColor: '#ed0f51',
            data: this.props.values,
            lineTension: 0.5
          }
        ]
      };

      return (
        <div>
          <Line data={chartData} />
        </div>
      );
    }
    return (
      <></>
    );
  }
}

export default LineChart;