import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';
import { constantsCashSources } from '../../../../utils/constants';

import { toISOStringWithTimezone } from '../../../../common/utils';

class SellModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        cashSource: constantsCashSources.CASH,
        fee: 0,
        price: 0,
        quantity: 0,
        transactionDate: toISOStringWithTimezone(new Date()),
        note: ''
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    sellAsset(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            let { quantity, price, fee, transactionDate, cashSource, note } = this.state;
            let sellRequest = {
                portfolioId: this.props.portfolioId,
                name: this.props.assetName,
                type: this.props.assetType,
                quantity: quantity,
                price: price,
                fee: fee,
                transactionDate: transactionDate,
                cashSource: cashSource,
                note: note
            };
            this.props.handleSubmit(sellRequest);
            this.setState(this.initialState);
        }
    }

    render() {
        let { cashSources, open, toggle, assetName, assetType } = this.props;
        let { transactionDate, quantity, fee, price, cashSource, note } = this.state;
        return (
            <>
                <Modal isOpen={open} fade={false} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Bán Tài Sản</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={(e) => this.sellAsset(e)}>
                            <FormGroup>
                                <Label for="name">Loại</Label>
                                <Input readOnly={true} type="text" value={assetType === 0 ? 'Cổ Phiếu' : 'Khác'} id="assetType" name="assetType"></Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="name">Tên</Label>
                                <Input type="text" readOnly={true} value={assetName} id="name" name="name" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="quantity">Số Lượng</Label>
                                <Input onChange={this.handleInputChange} value={quantity} type="number" min="1" max={this.props.maxQuantity} required id="quantity" name="quantity" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="price">Giá Bán</Label>
                                <InputGroup>
                                    <Input onChange={this.handleInputChange} value={price} type="number" id="price" min="0" name="price" />
                                    <InputGroupText>đ</InputGroupText>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="fee">Phí Giao Dịch</Label>
                                <InputGroup>
                                    <Input onChange={this.handleInputChange} value={fee} type="number" id="fee" min="0" name="fee" />
                                    <InputGroupText>đ</InputGroupText>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="cashSource">Nguồn</Label>
                                <Input onChange={this.handleInputChange} value={cashSource} type="select" required name="cashSource" id="cashSource">
                                    {cashSources && cashSources.map((cs) => (
                                        <option value={cs.name}>{cs.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="transactionDate">Ngày Giao Dịch</Label>
                                <Input onChange={this.handleInputChange} value={transactionDate} type="datetime-local" required step="1" id="transactionDate" name="transactionDate" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="note">Ghi Chú</Label>
                                <Input onChange={this.handleInputChange} value={note} type="textarea" name="note" id="note" />
                            </FormGroup>
                            <Button type="submit">Bán</Button>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

export default SellModal;