import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';
import { toISOStringWithTimezone } from '../../../../common/utils';

class AddNAVCCQHistoryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        historyDate: toISOStringWithTimezone(new Date()),
        value: '',
        valueAfterFee: '',
        isRoot: false
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    addNAVCCQHistory(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            let { historyDate, value, valueAfterFee, isRoot } = this.state;
            let addNAVCCQHistoryRequest = {
                portfolioId: this.props.portfolioId,
                historyDate: historyDate,
                value: value,
                valueAfterFee: valueAfterFee,
                isRoot: isRoot
            };
            this.props.handleSubmit(addNAVCCQHistoryRequest);
            this.setState(this.initialState);
        }
    }

    render() {
        let { open, toggle } = this.props;
        let { historyDate, value, valueAfterFee, isRoot } = this.state;
        return (

            <Modal isOpen={open} fade={false} toggle={toggle}>
                <ModalHeader toggle={toggle}>Thêm Lịch Sử NAVCCQ</ModalHeader>
                <Form onSubmit={(e) => this.addNAVCCQHistory(e)}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="historyDate">Thời Gian</Label>
                            <Input onChange={this.handleInputChange} value={historyDate} type="datetime-local" required step="1" id="historyDate" name="historyDate" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="value">Giá Trị</Label>
                            <InputGroup>
                                <Input onChange={this.handleInputChange} value={value} type="number" id="value" min="0" name="value" />
                                <InputGroupText>đ</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label for="valueAfterFee">Giá Trị Sau Phí</Label>
                            <InputGroup>
                                <Input onChange={this.handleInputChange} value={valueAfterFee} type="number" id="valueAfterFee" min="0" name="valueAfterFee" />
                                <InputGroupText>đ</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup check inline>
                            <Input type="checkbox" onChange={this.handleInputChange} checked={isRoot} name="isRoot" id="isRoot" />
                            <Label check for="isRoot">
                                Gốc
                            </Label>
                        </FormGroup>
                    </ModalBody >
                    <ModalFooter>
                        <button class="btn btn-primary mb-2 me-2" type="submit">Thêm</button>
                        <button class="btn btn-secondary mb-2 me-2" type="button" onClick={toggle}>Thoát</button>
                    </ModalFooter>
                </Form>
            </Modal >
        );
    }
}

export default AddNAVCCQHistoryModal;