import React, { Fragment } from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';

class AddMarketDataModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        time: '',
        value: 0
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    addMarket(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            this.props.handleSubmit(this.state);
            this.setState(this.initialState);
        }
    }

    render() {
        let { open, toggle } = this.props;
        let { time, value } = this.state;
        return (
            <Fragment>
                <Modal isOpen={open} fade={false} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Thêm Market</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={(e) => this.addMarket(e)}>
                            <FormGroup>
                                <Label for="time">Thời Gian</Label>
                                <Input onChange={this.handleInputChange} type="datetime-local" required value={time} id="time" name="time" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="value">Giá Trị</Label>
                                <InputGroup>
                                    <Input onChange={this.handleInputChange} type="text" required value={value} id="value" name="value" />
                                    <InputGroupText>(1000đ)</InputGroupText>
                                </InputGroup>
                            </FormGroup>
                            <Button color='primary' className='me-1' type="submit">Thêm</Button>
                            <Button color='secondary' type="button" onClick={toggle}>Thoát</Button>
                        </Form>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

export default AddMarketDataModal;