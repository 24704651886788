import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class AuthVerify extends Component {
  constructor(props) {
    super(props);
    this.props.history.listen(() => {
      let user = localStorage.user ? JSON.parse(localStorage.user) : null;
      if (user && user.token) {
        if (new Date(user.expires_in) < Date.now()) {
          this.props.logOut();
        }
      }
    });
  }
  render() {
    return <></>;
  }
}
export default withRouter(AuthVerify);
