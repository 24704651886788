import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

class DeleteUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  initialState = {
    id: "",
  };

  deleteUser(e) {
    e.preventDefault();
    this.props.handleSubmit(this.props.userIdToBeDeleted);
    this.setState(this.initialState);
  }

  render() {
    let { open, toggle, id } = this.props;
    return (
      <>
        <Modal isOpen={open} fade={false} toggle={toggle}>
          <ModalHeader toggle={toggle}>Xóa người dùng</ModalHeader>
          <ModalBody>
            <div class="row">
              <div class="col-md-12">
                <Form onSubmit={(e) => this.deleteUser(e)}>
                  <input type="hidden" name="id" value={id} />
                  <FormGroup>
                    <Label>
                      Bạn có chắc muốn xóa người dùng, hành động này sẽ không
                      thể hoàn tác!
                    </Label>
                  </FormGroup>
                  <Button className="mb-2 me-2 btn btn-danger" type="submit">
                    Đồng ý
                  </Button>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default DeleteUserModal;
