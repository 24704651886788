import React from "react";
import { collapseToast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

class ChangeEmailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  initialState = {
    userId: this.props.userId,
    email: this.props.email,
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.email != this.props.email) {
      this.setState({
        email: this.props.email,
      });
    }
  }

  changeUserEmail(e) {
    e.preventDefault();
    this.props.handleSubmit(this.state);
    this.setState(this.initialState);
  }

  render() {
    let { open, toggle } = this.props;
    let { email } = this.state;
    return (
      <>
        <Modal isOpen={open} fade={false} toggle={toggle}>
          <ModalHeader toggle={toggle}>Đổi email</ModalHeader>
          <ModalBody>
            <div class="row">
              <div class="col-md-12">
                <Form onSubmit={(e) => this.changeUserEmail(e)}>
                  <FormGroup>
                    <Label>Email mới</Label>
                    <Input
                      onChange={this.handleInputChange}
                      type="text"
                      required
                      value={email}
                      name="email"
                    />
                  </FormGroup>
                  <Button className="mb-2 me-2" type="submit">
                    Đồng ý
                  </Button>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default ChangeEmailModal;
