import React from 'react';
import {Pie} from 'react-chartjs-2';
import { backgroundColors } from '../../../utils/backgroundColors';

class PieChart extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        if (this.props.dataLabels && this.props.values){
            let chartData = {
                labels: this.props.dataLabels,
                datasets: [{
                    data: this.props.values,
                    backgroundColor: backgroundColors
                }]
            };
            return (
                <div>
                    <Pie dataKey="value" data={chartData} options={{aspectRatio: 2}} />
                </div>
            );
        }

        return (
            <></>
        );
    }
}

export default PieChart;