import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import authRoleAndPermissions from "../services/auth-role";

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let userIdentity = authRoleAndPermissions();

    if (userIdentity) {
      const { roles, permissions } = this.props;

      if (
        (roles && roles.includes(userIdentity.role)) ||
        (permissions &&
          permissions.some((p) => userIdentity.permissions.includes(p)))
      ) {
        return <Route {...this.props} />;
      }

      if (!roles && !permissions) {
        return <Route {...this.props} />;
      }

      return <Redirect to="/error/nopermission" />;
    }

    return <Redirect to="/login" />;
  }
}

export default withRouter(PrivateRoute);
