import axios from "axios";
const API_URL = "/api/auth/";
export default class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "authentication", { username, password })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  forgotPassword(request) {
    return axios.post(API_URL + "forgot-password", request, null);
  }

  resetPassword(request) {
    return axios.post(API_URL + "reset-password", request, null);
  }
}
