export const MainNav = [
  {
    icon: "pe-7s-rocket",
    label: "Dashboard Example",
    to: "#/dashboards/basic",
  },
];

export const AdminToolsNav = [
  {
    icon: "pe-7s-target",
    label: "Danh sách market",
    to: "#/market",
  },
  {
    icon: "pe-7s-portfolio",
    label: "Quản Lý Danh Mục",
    to: "#/portfolio",
  },
];

export const UserToolsNav = [
  {
    icon: "pe-7s-portfolio",
    label: "Danh mục đầu tư",
    to: "#/customerportfolio",
  },
];

export const UsersNav = [
  {
    icon: "pe-7s-target",
    label: "Danh sách người dùng",
    to: "#/user",
  },
  {
    icon: "pe-7s-target",
    label: "Danh sách chức vụ",
    to: "#/role",
  },
];

export const UserProfileNav = [
  {
    icon: "pe-7s-target",
    label: "Thông tin cá nhân",
    to: "#/user/me",
  },
];

export const ContactUsNav = [
  {
    icon: "pe-7s-target",
    label: "Liên hệ",
    to: "#/contactus",
  },
];

export const AdminContactUsNav = [
  {
    icon: "pe-7s-target",
    label: "Danh sách yêu cầu hỗ trợ",
    to: "#/contactus/tickets",
  },
];

export const LinksNav = [
  {
    icon: "pe-7s-target",
    label: "Thông tin khác",
    to: "#/links",
  },
];

export const ScheduledJobNav = [
  {
    icon: "pe-7s-target",
    label: "Danh sách scheduled jobs",
    to: "#/scheduledjobs",
  },
];

export const SettingsNav = [
  {
    icon: "pe-7s-config",
    label: "Service Lấy Dữ Liệu",
    to: "#/stockserviceconfig",
  }
]
