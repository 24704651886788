import { Route, Redirect, Switch } from "react-router-dom";
import React, { Fragment, useState } from "react";
import Portfolio from "../../Pages/Portfolio";
import CustomerPortfolio from "../../Pages/CustomerPortfolio";
import Market from "../../Pages/Market";
import User from "../../Pages/User";
import Role from "../../Pages/Role";
import PrivateRoute from "../../common/private-route";
import { authPermissions, authRoles } from "../../utils/constants";

import { ToastContainer } from "react-toastify";
import ContactUs from "../../Pages/ContactUs";
import authRoleAndPermissions from "../../services/auth-role";
import Login from "../../Pages/Login";
import ScheduledJob from "../../Pages/ScheduledJob";
import Errors from "../../Pages/Errors";
import Links from "../../Pages/Links";
import ForgotPassword from "../../Pages/ForgotPassword";
import ResetPassword from "../../Pages/ResetPassword";
import CustomerPortfolioService from "../../services/customer.portfolio.service";
import StockServiceConfiguration from "../../Pages/StockServiceConfig";

const AppMain = () => {
  const identity = authRoleAndPermissions();
  return (
    <Fragment>
      <Switch>
        <Route
            exact
            path="/"
            component={Login}
          />
        <PrivateRoute
          permissions={[authPermissions.Portfolio.List]}
          path="/portfolio"
          component={Portfolio}
        />
        <PrivateRoute
          permissions={[authPermissions.Portfolio.List]}
          path="/market"
          component={Market}
        />
        <PrivateRoute path="/customerportfolio" component={CustomerPortfolio} />
        <PrivateRoute path="/user" component={User} />
        <PrivateRoute
          permissions={[authPermissions.User.List]}
          path="/role"
          component={Role}
        />
        <PrivateRoute path="/contactus" component={ContactUs} />
        <PrivateRoute
          path="/scheduledjob"
          permissions={[authPermissions.ScheduledJob.List]}
          component={ScheduledJob}
        />
        <PrivateRoute
          path="/links"
          permissions={[authPermissions.LinksList.List]}
          component={Links}
        />
        <PrivateRoute path="/scheduledjobs" component={ScheduledJob} />
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route
          path="/reset-password/:token"
          render={(props) => (
            <ResetPassword key={props.match.params.token} {...props} />
          )}
        />
        <PrivateRoute path="/stockserviceconfig" component={StockServiceConfiguration} permissions={[authPermissions.LinksList.List]} />
        <Route path="/error" component={Errors} />
      </Switch>
      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
