import React from 'react';
import { Line } from 'react-chartjs-2';
import { backgroundColors } from '../../../utils/backgroundColors';

const chartData = {
    labels: [],
    datasets: [

    ]
};

const lineData = {
    label: '',
    fill: false,
    lineTension: 0.5,
    backgroundColor: '#ed0f51',
    borderColor: '#ed0f51',
    borderCapStyle: 'round',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: '#ed0f51',
    pointBackgroundColor: '#ffffff',
    pointBorderWidth: 2,
    pointHoverRadius: 10,
    pointHoverBackgroundColor: '#ed0f51',
    pointHoverBorderColor: '#ed0f51',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    data: []
};


class MultiLineChart extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.chartData) {
            let chartData = {
                labels: this.props.chartData[0].dataLabels,
                datasets: this.props.chartData.map((c,i) => {
                    return {
                        label: c.label,
                        data: c.values,
                        fill: false,
                        borderColor: backgroundColors[i],
                        backgroundColor: backgroundColors[i],
                        lineTension: 0.5
                    };
                })
            };

            return (
                <div>
                    <Line data={chartData} />
                </div>
            )
        }
        return (<></>);
    }
}

export default MultiLineChart;