import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';


class AddWithdrawRequestModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        value: 0
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    addRequest(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            this.props.handleSubmit(this.state.value);
            this.setState(this.initialState);
        }
    }

    render() {
        let { open, toggle } = this.props;
        let { value } = this.state;
        return (
            <>
                <Modal isOpen={open} fade={false} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Thêm Yêu Cầu Rút Tiền</ModalHeader>
                    <ModalBody>
                        <div class="row">
                            <div class="col-md-12">
                                <Form onSubmit={(e) => this.addRequest(e)}>
                                    <FormGroup>
                                        <Label>Tổng Số Tiền Bạn Đang Có</Label>
                                        <InputGroup>
                                            <Input readOnly={true} value={this.props.maxValue} type="number" min="1"/>
                                            <InputGroupText>đ</InputGroupText>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="value">Số Tiền Rút</Label>
                                        <InputGroup>
                                            <Input onChange={this.handleInputChange} value={value} type="number" id="value" min="1" name="value" max={this.props.maxValue} />
                                            <InputGroupText>đ</InputGroupText>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Phí Rút Tiền Dự Kiến (%)</Label>
                                        <InputGroup>
                                            <Input readOnly={true} value={this.props.fee} type="number" />
                                            <InputGroupText>%</InputGroupText>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Phí Rút Tiền Dự Kiến (VND)</Label>
                                        <InputGroup>
                                            <Input readOnly={true} value={(this.props.fee * value / 100)} type="number"/>
                                            <InputGroupText>đ</InputGroupText>
                                        </InputGroup>
                                    </FormGroup>
                                    <Button color='primary' className='mb-2 me-2' type="submit">Thêm</Button>
                                </Form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

export default AddWithdrawRequestModal;