import React, { Fragment } from "react";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import ContactUsService from "../../../services/contactus.service";

class ContactUsTicketDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: null,
      error: "",
    };
  }

  componentDidMount() {
    let { match } = this.props;
    if (match.params.ticketId) {
      new ContactUsService().detail("me", match.params.ticketId).then(
        (response) => {
          this.setState({
            detail: response.data,
          });
        },
        (error) => {
          this.setState({
            error: error.message,
          });
        }
      );
    }
  }

  render() {
    let { detail } = this.state;
    return (
      <Fragment>
        <PageTitle
          heading="Thông tin yêu cầu hỗ trợ"
          icon="pe-7s-portfolio icon-gradient bg-amy-crisp"
        />
        <Row>
          <Col className="col-8 mt-2">
            <Card>
              <CardHeader>
                <div className="card-header-title">
                  <i className="header-icon lnr-history icon-gradient bg-plum-plate"></i>
                  Ticket #{detail != null && detail.id}
                </div>
              </CardHeader>
              <CardBody>
                <Form onSubmit={(e) => this.changeUserEmail(e)}>
                  <FormGroup>
                    <Label>Email</Label>
                    <Input
                      type="text"
                      name="emailAddress"
                      value={detail != null ? detail.emailAddress : ""}
                      readOnly
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Content</Label>
                    <Input
                      type="textarea"
                      name="content"
                      rows="5"
                      value={detail != null ? detail.content : ""}
                      readOnly
                    />
                  </FormGroup>
                  <Button className="mb-2 me-2" type="submit" disabled="true">
                    Gửi email
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default ContactUsTicketDetail;
