import React, { Fragment } from "react";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  ModalBody,
  Modal,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import LinksListService from "../../../services/links.service";
import authRoleAndPermissions from "../../../services/auth-role";
import { authPermissions } from "../../../utils/constants";
import { Bounce, toast } from "react-toastify";

class LinksListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [],
      error: "",
      errorModalOpen: false,
      title: "",
      url: "",
      shouldOpenInNewTab: true,
    };

    this.load = this.load.bind(this);
    this.create = this.create.bind(this);
    this.delete = this.delete.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  notifySucess = (text) =>
    (this.toastId = toast(text, {
      transition: Bounce,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-center",
      type: "success",
    }));

  load() {
    new LinksListService().list().then(
      (response) => {
        this.setState({
          links: response.data,
        });
      },
      (error) => {
        this.setState({
          links: [],
          error: error.message,
        });
      }
    );
  }

  componentDidMount() {
    this.load();
  }

  delete(id) {
    new LinksListService().delete(id).then(
      (response) => {
        this.notifySucess("Delete link successfully");
        this.load();
      },
      (error) => {
        this.setState({
          links: [],
          error: error.message,
        });
      }
    );
  }

  create(e) {
    e.preventDefault();
    var request = {
      title: this.state.title,
      url: this.state.url,
      shouldOpenInNewTab: this.state.shouldOpenInNewTab,
    };

    new LinksListService().create(request).then(
      (response) => {
        this.notifySucess("Create link successfully");
        this.setState({
          title: "",
          url: "",
          shouldOpenInNewTab: true,
        });
        this.load();
      },
      (error) => {
        this.setState({
          links: [],
          error: error.message,
        });
      }
    );
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const identity = authRoleAndPermissions();
    return (
      <Fragment>
        <PageTitle
          heading="Danh sách thông tin hữu ích"
          icon="pe-7s-portfolio icon-gradient bg-amy-crisp"
        />
        <Row>
          <Col className="col-6">
            <Card>
              <CardHeader>
                <div className="card-header-title">Danh sách</div>
              </CardHeader>
              <CardBody>
                <div class="table-responsive">
                  <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                      <tr>
                        <th class="text-center">#</th>
                        <th class="text-center">Tựa đề</th>
                        {identity.permissions &&
                          identity.permissions.includes(
                            authPermissions.LinksList.Edit
                          ) && (
                            <>
                              <th class="text-center"></th>
                            </>
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.links &&
                        this.state.links.map((link, i) => {
                          return (
                            <tr>
                              <td class="text-center" scope="row">
                                {i + 1}
                              </td>
                              <td class="text-center">
                                <a
                                  target={
                                    link.shouldOpenInNewTab ? "_blank" : "_self"
                                  }
                                  title={link.title}
                                  href={link.url}
                                >
                                  {link.title}
                                </a>
                              </td>
                              {identity.permissions &&
                                identity.permissions.includes(
                                  authPermissions.LinksList.Edit
                                ) && (
                                  <>
                                    <td class="text-center">
                                      <button
                                        class="btn btn-danger mb-2 me-2"
                                        onClick={() => this.delete(link.id)}
                                      >
                                        <i className="fa fa-trash fa-w-20"></i>
                                        Delete
                                      </button>
                                    </td>
                                  </>
                                )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
          {identity.permissions &&
            identity.permissions.includes(authPermissions.LinksList.Edit) && (
              <>
                <Col className="col-6">
                  <Card>
                    <CardHeader>
                      <div className="card-header-title">Thêm thông tin</div>
                    </CardHeader>
                    <CardBody>
                      <Form onSubmit={(e) => this.create(e)}>
                        <FormGroup>
                          <Label>Tựa đề</Label>
                          <Input
                            type="text"
                            onChange={this.handleInputChange}
                            name="title"
                            value={this.state.title}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Url</Label>
                          <Input
                            type="text"
                            onChange={this.handleInputChange}
                            name="url"
                            value={this.state.url}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Input
                            type="checkbox"
                            name="shouldOpenInNewTab"
                            onChange={this.handleInputChange}
                            value={this.state.shouldOpenInNewTab}
                          />
                          <Label>&nbsp;Should open in new tab</Label>
                        </FormGroup>
                        <Button
                          className="mb-2 me-2"
                          type="submit"
                          disabled={!this.state.url || !this.state.title}
                        >
                          Tạo mới
                        </Button>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </>
            )}
        </Row>
        <Modal
          isOpen={this.state.errorModalOpen}
          fade={false}
          toggle={() => this.toggleErrorModal()}
        >
          <ModalHeader
            className="text-danger"
            toggle={() => this.toggleErrorModal()}
          >
            <i class="lnr-warning">&nbsp;</i>Lỗi
          </ModalHeader>
          <ModalBody>{this.state.error}</ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default LinksListing;
