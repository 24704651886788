import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "/api/contactus/";
export default class ContactUsService {
  listTicket(userId) {
    return axios.get(API_URL + userId + "/tickets", { headers: authHeader() });
  }

  listTopTicketWaitingForSupport(userId) {
    return axios.get(API_URL + userId + "/tickets/waitingforsupport", {
      headers: authHeader(),
    });
  }

  detail(userId, ticketId) {
    return axios.get(API_URL + userId + "/tickets/" + ticketId, {
      headers: authHeader(),
    });
  }

  sendEmail(id, request) {
    return axios.post(API_URL + id + "/sendEmail", request, {
      headers: authHeader(),
    });
  }

  markTicketAsDone(userId, ticketId) {
    return axios.post(
      API_URL + userId + "/tickets/" + ticketId + "/done",
      null,
      {
        headers: authHeader(),
      }
    );
  }
}
