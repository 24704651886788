import React, { Fragment } from "react";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import HeaderLogo from "../AppLogo";

import SearchBox from "./Components/SearchBox";
import UserBox from "./Components/UserBox";
import UserImpersonateService from "../../services/user.impersonate.service";
import AuthService from "../../services/auth.service";
import { ModalBody, Modal, ModalHeader } from "reactstrap";
import SupportNotificationBox from "./Components/SupportNotificationBox";
import authRoleAndPermissions from "../../services/auth-role";
import { authPermissions } from "../../utils/constants";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      errorModalOpen: false,
    };
    this.logOut = this.logOut.bind(this);
    this.stopImpersonating = this.stopImpersonating.bind(this);
  }

  logOut() {
    const { history } = this.props;
    new AuthService().logout();
    history.push("/");
  }

  stopImpersonating() {
    const user = JSON.parse(localStorage.getItem("user"));
    new UserImpersonateService()
      .stopImpersonating({ originalUserId: user.originalUserId })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
          this.props.history.push("/");
        }
        return response.data;
      });
  }
  render() {
    let { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow } =
      this.props;
    let identity = authRoleAndPermissions();
    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            component="div"
            className={cx("app-header", headerBackgroundColor, {
              "header-shadow": enableHeaderShadow,
            })}
            appear={true}
            timeout={1500}
            enter={false}
            exit={false}
          >
            <div>
              <HeaderLogo />

              <div
                className={cx("app-header__content", {
                  "header-mobile-open": enableMobileMenuSmall,
                })}
              >
                <div className="app-header-left">
                  <SearchBox />
                </div>
                <div className="app-header-right">
                  {identity &&
                    identity.permissions &&
                    identity.permissions.includes(
                      authPermissions.SupportTicket.List
                    ) && (
                      <>
                        <SupportNotificationBox />
                      </>
                    )}
                  <UserBox
                    logOut={this.logOut}
                    stopImpersonating={this.stopImpersonating}
                  />
                </div>
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
        <Modal
          isOpen={this.state.errorModalOpen}
          fade={false}
          toggle={() => this.toggleErrorModal()}
        >
          <ModalHeader
            className="text-danger"
            toggle={() => this.toggleErrorModal()}
          >
            <i class="lnr-warning">&nbsp;</i>Lỗi
          </ModalHeader>
          <ModalBody>{this.state.error}</ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

export default withRouter(connect(mapStateToProps)(Header));
