import React, { Fragment } from "react";
import { Route, withRouter } from "react-router-dom";

import AppHeader from "../../Layout/AppHeader/";
import AppSidebar from "../../Layout/AppSidebar/";
import AppFooter from "../../Layout/AppFooter/";
import ScheduledJobDetail from "./Detail";
import ScheduledJobListing from "./Listing";
import PrivateRoute from "../../common/private-route";

const ScheduledJob = ({ match }) => (
  <Fragment>
    <AppHeader />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <PrivateRoute
            exact
            path={`${match.path}`}
            component={ScheduledJobListing}
          />
          <Route
            path={`${match.path}/:name`}
            render={(props) => (
              <ScheduledJobDetail key={props.match.params.name} {...props} />
            )}
          />
        </div>
        <AppFooter />
      </div>
    </div>
  </Fragment>
);

export default withRouter(ScheduledJob);
