import React, { Fragment } from 'react';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import { NavLink, Redirect } from 'react-router-dom';
import { Table, Row, Col, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PortfolioService from '../../../services/portfolio.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import CensoredInfo from '../../../common/censored-info';
import CensoredSwitch from '../../../common/censored-switch';
import AddPortfolioModal from './AddPortfolioModal';


class PortfolioListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            portfolios: [],
            censored: true,
            error: "",
            addPortfolioModalOpen: false,
            deletePortfolioModalOpen: false,
            errorModalOpen: false,
            deletePortfolioName: '',
            deletePortfolioId: ''
        };
        this.addPortfolio = this.addPortfolio.bind(this);
        this.deletePortfolio = this.deletePortfolio.bind(this);
    }

    toggleAddPortfolioModal() {
        this.setState({
            addPortfolioModalOpen: !this.state.addPortfolioModalOpen
        });
    }

    toggleDeletePortfolioModal() {
        this.setState({
            deletePortfolioModalOpen: !this.state.deletePortfolioModalOpen
        })
    }

    toggleErrorModal() {
        this.setState({ errorModalOpen: !this.state.errorModalOpen });
    }


    addPortfolio(request) {
        new PortfolioService().addPortfolio(request).then(
            response => {
                if (response.data.success) {
                    this.loadPortfolios();
                    this.setState({
                        addPortfolioModalOpen: false
                    });
                } else {
                    this.setState({
                        error: response.data.message,
                        errorModalOpen: true,
                        addPortfolioModalOpen: false
                    });
                }

            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    addPortfolioModalOpen: false
                });
            }
        );
    }

    openDeletePortfolioModal(id, name) {
        this.setState({
            deletePortfolioModalOpen: true,
            deletePortfolioId: id,
            deletePortfolioName: name
        });
    }

    deletePortfolio() {
        new PortfolioService().deletePortfolio(this.state.deletePortfolioId).then(
            response => {
                if (response.data.success) {
                    this.loadPortfolios();
                    this.setState({
                        deletePortfolioModalOpen: false,
                        deletePortfolioId: '',
                        deletePortfolioName: ''
                    });
                } else {
                    this.setState({
                        error: response.data.message,
                        errorModalOpen: true,
                        deletePortfolioModalOpen: false
                    });
                }

            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    deletePortfolioModalOpen: false
                });
            }
        );
    }

    componentDidMount() {
        this.loadPortfolios();
    }

    loadPortfolios() {
        new PortfolioService().list().then(
            response => {
                this.setState({
                    portfolios: response.data.portfolios,
                    error: ""
                });
            },
            error => {
                this.setState({
                    portfolios: [],
                    error: error.message
                })
            }
        );
    }

    toggleCensored() {
        this.setState({
            censored: !this.state.censored
        });
    }

    render() {
        let { match } = this.props;
        let { censored } = this.state;

        return (
            <Fragment>
                <PageTitle
                    heading="Danh sách Portfolio"
                    icon="pe-7s-portfolio icon-gradient bg-amy-crisp"
                />
                <Row>
                    <Col className='col-12'>
                        <Card className='main-card mb-3'>
                            <CardHeader>
                                <div className="card-header-title">
                                    Danh mục
                                    <CensoredSwitch censored={censored} toggle={() => this.toggleCensored()} />
                                </div>
                                <div className="btn-actions-pane-right">
                                    <Button color="primary" onClick={() => this.toggleAddPortfolioModal()}>
                                        <i className='lnr-file-add'> </i>
                                        Thêm
                                    </Button>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Table className="mb-0" striped borderless>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Tên</th>
                                            <th>NAVCCQ (1000đ)</th>
                                            <th>Sô lượng CCQ</th>
                                            <th>Tổng Giá Trị</th>
                                            <th>...</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.portfolios && this.state.portfolios.map((portfolio, i) => {
                                            return (
                                                <tr>
                                                    <th scope="row">{i + 1}</th>
                                                    <td><NavLink to={`${match.url}/` + portfolio.id}>{portfolio.name}</NavLink></td>
                                                    <td><CensoredInfo content={portfolio.navccq} censored={censored} /></td>
                                                    <td><CensoredInfo content={portfolio.numberOfCertificates} censored={censored} /></td>
                                                    <td><CensoredInfo content={portfolio.nav} censored={censored} /></td>
                                                    <td><Button onClick={() => this.openDeletePortfolioModal(portfolio.id, portfolio.name)}>Xóa</Button></td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                    <AddPortfolioModal open={this.state.addPortfolioModalOpen} toggle={() => this.toggleAddPortfolioModal()} handleSubmit={this.addPortfolio} />
                    <Modal isOpen={this.state.errorModalOpen} fade={false} toggle={() => this.toggleErrorModal()}>
                        <ModalHeader className='text-danger' toggle={() => this.toggleErrorModal()}><i class="lnr-warning">&nbsp;</i>Lỗi</ModalHeader>
                        <ModalBody>
                            {this.state.error}
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={this.state.deletePortfolioModalOpen} fade={false} toggle={() => this.toggleDeletePortfolioModal()}>
                        <ModalHeader toggle={() => this.toggleDeletePortfolioModal()}><i class="lnr-warning">&nbsp;</i>Bạn có chắc muốn xóa portfolio <strong>{this.state.deletePortfolioName}</strong>?</ModalHeader>
                        <ModalBody>
                            <div class="row">
                                <div class="col-md-12">
                                    <button class="btn btn-primary mb-2 me-2" color='primary' onClick={this.deletePortfolio}>Có</button>
                                    <button class="btn btn-primary mb-2 me-2" color='secondary' onClick={() => this.toggleDeletePortfolioModal()}>Không</button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </Row>
            </Fragment>
        );
    }
}

export default PortfolioListing;