import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "/api/users/";
export default class UserService {
  list() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  detail(id) {
    return axios.get(API_URL + id + "/detail", { headers: authHeader() });
  }

  delete(id) {
    return axios.post(API_URL + id + "/delete", null, {
      headers: authHeader(),
    });
  }

  create(request) {
    return axios.post(API_URL + "create", request, {
      headers: authHeader(),
    });
  }

  changepassword(request) {
    return axios.post(API_URL + request.userId + "/changepassword", request, {
      headers: authHeader(),
    });
  }

  resetpassword(request) {
    return axios.post(API_URL + request.userId + "/resetpassword", request, {
      headers: authHeader(),
    });
  }

  changeemail(request) {
    return axios.post(API_URL + request.userId + "/changeemail", request, {
      headers: authHeader(),
    });
  }

  changephonenumber(request) {
    return axios.post(
      API_URL + request.userId + "/changephonenumber",
      request,
      {
        headers: authHeader(),
      }
    );
  }

  togglestatus(id) {
    return axios.post(API_URL + id + "/togglestatus", null, {
      headers: authHeader(),
    });
  }

  listBankAccounts(userId) {
    return axios.get(API_URL + userId + "/bankaccount", {
      headers: authHeader(),
    });
  }

  createBankAccount(request) {
    return axios.post(
      API_URL + request.userId + "/bankaccount/create",
      request,
      {
        headers: authHeader(),
      }
    );
  }

  deleteBankAccount(userId, accountNumber) {
    return axios.post(
      API_URL + userId + "/bankaccount/delete/" + accountNumber,
      null,
      {
        headers: authHeader(),
      }
    );
  }

  listEmergencyContacts(userId) {
    return axios.get(API_URL + userId + "/emergencycontact", {
      headers: authHeader(),
    });
  }

  createEmergencyContact(request) {
    return axios.post(
      API_URL + request.userId + "/emergencycontact/create",
      request,
      {
        headers: authHeader(),
      }
    );
  }

  deleteEmergencyContact(userId, fullName) {
    return axios.post(
      API_URL + userId + "/emergencycontact/delete/" + fullName,
      null,
      {
        headers: authHeader(),
      }
    );
  }

  changeRole(request) {
    return axios.post(API_URL + request.userId + "/changerole", request, {
      headers: authHeader(),
    });
  }
}
