import React, {Fragment} from 'react';
import { NavLink } from 'react-router-dom';
import {PaginationLink, Pagination, PaginationItem } from 'reactstrap';
class AppPagination extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        let {parentUrl, page, totalPages, hasNextPage, hasPreviousPage} = this.props;
        if (totalPages <= 0){
            return <></>;
        }
        return (
            <Fragment>
                <Pagination className='mt-1' aria-label="NAVCCQHistories">
                    <PaginationItem disabled={!hasPreviousPage}>
                        <NavLink className={"page-link"} to={`${parentUrl}/${page - 1}`}>
                            <span aria-hidden="true">«</span><span class="sr-only">Previous</span>
                        </NavLink>
                    </PaginationItem>
                    {page < 5 ? (
                        Array.from({length: page}).map((item,index) => (
                            <PaginationItem active={(index + 1) === page} >
                                <NavLink className={"page-link"} to={`${parentUrl}/${index + 1}`}>
                                    {index + 1}
                                </NavLink>
                            </PaginationItem>
                        ))
                    ): (
                        <Fragment>
                            <PaginationItem>
                                <NavLink className={"page-link"} to={`${parentUrl}/1`}>
                                    1
                                </NavLink>
                            </PaginationItem>
                            <PaginationItem disabled>
                                <PaginationLink className={"page-link"}>
                                    <span>...</span>
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem>
                                <NavLink className={"page-link"} to={`${parentUrl}/${page - 2}`}>
                                    {page - 2}
                                </NavLink>
                            </PaginationItem>
                            <PaginationItem>
                                <NavLink className={"page-link"} to={`${parentUrl}/${page - 1}`}>
                                    {page - 1}
                                </NavLink>
                            </PaginationItem>
                            <PaginationItem active>
                                <NavLink className={"page-link"} to={`${parentUrl}/${page}`}>
                                    {page}
                                </NavLink>
                            </PaginationItem>
                        </Fragment>                           
                    )}
                    
                    {page + 3 < totalPages ? (
                        <Fragment>
                            <PaginationItem>
                                <NavLink className={"page-link"} to={`${parentUrl}/${page + 1}`}>
                                    {page + 1}
                                </NavLink>
                            </PaginationItem>
                            <PaginationItem>
                                <NavLink className={"page-link"} to={`${parentUrl}/${page + 2}`}>
                                    {page + 2}
                                </NavLink>
                            </PaginationItem>
                            <PaginationItem disabled>
                                <PaginationLink className={"page-link"}>
                                    <span>...</span>
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem>
                                <NavLink className={"page-link"} to={`${parentUrl}/${totalPages}`}>
                                    {totalPages}
                                </NavLink>
                            </PaginationItem>
                        </Fragment>
                    ): (
                        <Fragment>
                        {Array.from({length: totalPages - page}).map((item, index) => (
                            <PaginationItem>
                                <NavLink className={"page-link"} to={`${parentUrl}/${page+index + 1}`}>
                                    {page+index + 1}
                                </NavLink>
                            </PaginationItem>
                        ))}
                        </Fragment>
                    )}
                    <PaginationItem disabled={!hasNextPage}>
                        <NavLink className={"page-link"} to={`${parentUrl}/${page + 1}`}>
                            <span aria-hidden="true">»</span><span class="sr-only">Next</span>
                        </NavLink>
                    </PaginationItem>
                </Pagination>
            </Fragment>
        )
    }
}

export default AppPagination;