import React, { Fragment } from "react";
import { PaginationLink, Pagination, PaginationItem } from "reactstrap";
class CustomPagination extends React.Component {
  constructor(props) {
    super(props);
  }
  changePage(pageNumber) {
    this.props.changePage(pageNumber);
  }

  render() {
    let { page, totalPages, hasNextPage, hasPreviousPage } = this.props;
    if (totalPages <= 0) {
      return <></>;
    }
    return (
      <Fragment>
        <Pagination className="mt-1" aria-label="NAVCCQHistories">
          <PaginationItem disabled={!hasPreviousPage}>
            <button class="page-link" onClick={() => this.changePage(page - 1)}>
              <span aria-hidden="true">«</span>
              <span class="sr-only">Previous</span>
            </button>
          </PaginationItem>
          {page < 5 ? (
            Array.from({ length: page }).map((item, index) => (
              <PaginationItem active={index + 1 === page}>
                <button
                  class="page-link"
                  onClick={() => this.changePage(index + 1)}
                >
                  {index + 1}
                </button>
              </PaginationItem>
            ))
          ) : (
            <Fragment>
              <PaginationItem>
                <button class="page-link" onClick={() => this.changePage(1)}>
                  1
                </button>
              </PaginationItem>
              <PaginationItem disabled>
                <PaginationLink className={"page-link"}>
                  <span>...</span>
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <button
                  class="page-link"
                  onClick={() => this.changePage(page - 2)}
                >
                  {page - 2}
                </button>
              </PaginationItem>
              <PaginationItem>
                <button
                  class="page-link"
                  onClick={() => this.changePage(page - 1)}
                >
                  {page - 1}
                </button>
              </PaginationItem>
              <PaginationItem active>
                <button class="page-link">{page}</button>
              </PaginationItem>
            </Fragment>
          )}

          {page + 3 < totalPages ? (
            <Fragment>
              <PaginationItem>
                <button
                  class="page-link"
                  onClick={() => this.changePage(page + 1)}
                >
                  {page + 1}
                </button>
              </PaginationItem>
              <PaginationItem>
                <button
                  class="page-link"
                  onClick={() => this.changePage(page + 2)}
                >
                  {page + 2}
                </button>
              </PaginationItem>
              <PaginationItem disabled>
                <PaginationLink className={"page-link"}>
                  <span>...</span>
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <button
                  class="page-link"
                  onClick={() => this.changePage(totalPages)}
                >
                  {totalPages}
                </button>
              </PaginationItem>
            </Fragment>
          ) : (
            <Fragment>
              {Array.from({ length: totalPages - page }).map((item, index) => (
                <PaginationItem>
                  <button
                    class="page-link"
                    onClick={() => this.changePage(page + index + 1)}
                  >
                    {page + index + 1}
                  </button>
                </PaginationItem>
              ))}
            </Fragment>
          )}
          <PaginationItem disabled={!hasNextPage}>
            <button class="page-link" onClick={() => this.changePage(page + 1)}>
              <span aria-hidden="true">»</span>
              <span class="sr-only">Next</span>
            </button>
          </PaginationItem>
        </Pagination>
      </Fragment>
    );
  }
}

export default CustomPagination;
