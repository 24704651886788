import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';
import CashSourceListing from './CashSourceListing';
import { constantsCashSources } from '../../../../utils/constants';


class AddCashSourceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        name: '',
        value: 0,
        cashSource: constantsCashSources.CASH,
        note: ''
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    addCashSource(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            this.props.handleSubmit(this.state);
            this.setState(this.initialState);
        }
    }

    render() {
        let { cashSources, open, toggle } = this.props;
        let { name, value, note, cashSource } = this.state;
        return (
            <>
                <Modal scrollable isOpen={open} fade={false} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Thêm Nguồn Tiền</ModalHeader>
                    <ModalBody>
                        <div class="row">
                            <div class="col-md-12">
                                <Form onSubmit={(e) => this.addCashSource(e)}>
                                    <FormGroup>
                                        <Label>Tên</Label>
                                        <Input onChange={this.handleInputChange} type="text" required value={name} id="name" name="name" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="value">Giá Trị</Label>
                                        <InputGroup>
                                            <Input onChange={this.handleInputChange} value={value} type="number" id="value" min="0" name="value" />
                                            <InputGroupText>đ</InputGroupText>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="cashSource">Nguồn</Label>
                                        <Input onChange={this.handleInputChange} value={cashSource} type="select" required name="cashSource" id="cashSource">
                                            {cashSources && cashSources.map((cs) => (
                                                <option value={cs.name}>{cs.name}</option>
                                            ))}
                                            <option value="Profit">Lãi/Lỗ</option>
                                            <option value="Other">Khác</option>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="note">Ghi Chú</Label>
                                        <Input onChange={this.handleInputChange} value={note} type="textarea" name="note" id="note" />
                                    </FormGroup>
                                    <Button className='mb-2 me-2' type="submit">Thêm</Button>
                                </Form>
                            </div>
                            <div class="col-md-12">
                                <CashSourceListing cashSources={cashSources} />
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

export default AddCashSourceModal;