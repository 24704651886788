import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

class CensoredSwitch extends React.Component {
    constructor(props) {
        super(props);
    }

    onClick(){
        this.props.toggle();
    }

    render() {
        const { censored } = this.props;
        return (
            <span style={{ cursor: "pointer" }} onClick={() => this.onClick()}>
                &nbsp;
                {censored ? (
                    <FontAwesomeIcon icon={faEye} />
                ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                )}
            </span>
        )
    }
}

export default CensoredSwitch;