import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, CardHeader, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import AppPagination from '../../../../Blocks/AppPagination';
import CustomerPortfolioService from '../../../../services/customer.portfolio.service';
import AddDepositRequestModal from '../DepositRequests/AddDepositRequestModal';
import AddWithdrawRequestModal from './AddWithdrawRequestModal';

class CustomerPortfolioWithdrawRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requests: null,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false,
            errorModalOpen: false,
            error: '',
            addWithdrawRequestModalOpen: false,
            isLoading: false
        };
        this.addRequest = this.addRequest.bind(this);
    }

    toggleErrorModal() {
        this.setState({
            errorModalOpen: !this.state.errorModalOpen
        });
    }

    toggleAddWithdrawRequestModal() {
        this.setState({
            addWithdrawRequestModalOpen: !this.state.addWithdrawRequestModalOpen
        });
    }

    getUserToken() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token) {
            return user.token;
        }

        return '';
    }

    componentDidMount() {
        this.loadWithdrawRequests();
    }

    loadWithdrawRequests() {
        let { match } = this.props;
        let page = parseInt(match.params.page) ?? 1;
        new CustomerPortfolioService().withdrawRequests(match.params.portfolioId, page).then(
            response => {
                this.setState({
                    requests: response.data.items,
                    totalPages: response.data.totalPages,
                    hasPreviousPage: response.data.hasPreviousPage,
                    hasNextPage: response.data.hasNextPage
                });
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    addRequest(value) {
        this.setState({
            isLoading: true
        });
        let { match } = this.props;
        const request = {
            portfolioId: match.params.portfolioId,
            value: value
        };
        new CustomerPortfolioService().addWithdrawRequest(request).then(
            response => {
                if (response.data.success) {
                    this.props.updateOverview();
                    this.loadWithdrawRequests();
                    this.setState({
                        errorModalOpen: false,
                        addWithdrawRequestModalOpen: false,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        error: response.data.message,
                        errorModalOpen: true,
                        addWithdrawRequestModalOpen: false,
                        isLoading: false
                    });
                }
            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    addWithdrawRequestModalOpen: false,
                    isLoading: false
                })
            }
        );
    }

    render() {
        let { match } = this.props;
        let parentUrl = match.url.substring(0, match.url.lastIndexOf("/"));
        let page = parseInt(match.params.page) ?? 1;
        let { requests, hasNextPage, hasPreviousPage, totalPages, addWithdrawRequestModalOpen, isLoading } = this.state;
        return (
            <Fragment>
                <TransitionGroup>
                    <CSSTransition
                        component="div"
                        className="TabsAnimation"
                        appear={true}
                        timeout={0}
                        enter={false}
                        exit={false}>
                        <Row>
                            {isLoading ? (
                                <div className="loader-container">
                                    <div className="loader-container-inner">
                                        <h6 className="mt-5">
                                            Hệ thống đang xử lý ...
                                        </h6>
                                    </div>
                                </div>
                            ) : (
                                <Col lg="12">
                                    <Card className="main-card mb-3">
                                        <CardHeader>
                                            <div className="card-header-title">
                                                <i className="header-icon lnr-exit-up icon-gradient bg-plum-plate"> </i>
                                                Yêu Cầu Rút Tiền
                                            </div>
                                            <div class="btn-actions-pane-right">
                                                <button class="btn btn-primary mb-2 me-2" onClick={() => this.toggleAddWithdrawRequestModal('', '')}>Thêm Yêu Cầu</button>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <div class="table-responsive">
                                                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">Số Tiền Rút</th>
                                                            <th class="text-center">Ngày Tạo Yêu Cầu</th>
                                                            <th class="text-center">Tình Trạng</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {requests && requests.map(r => (
                                                            <tr>
                                                                <td class="text-center">
                                                                    {r.value}
                                                                </td>
                                                                <td class="text-center">
                                                                    {r.date}
                                                                </td>
                                                                <td class="text-center">
                                                                    <div className={'badge ' + r.badgeStatus}>
                                                                        {r.status}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                        <CardFooter className='justify-content-center'>
                                            <AppPagination totalPages={totalPages} parentUrl={parentUrl} page={page} hasNextPage={hasNextPage} hasPreviousPage={hasPreviousPage} />
                                        </CardFooter>
                                        <AddWithdrawRequestModal handleSubmit={this.addRequest} maxValue={this.props.maxValue} fee={this.props.fee} open={addWithdrawRequestModalOpen} toggle={() => this.toggleAddWithdrawRequestModal()} />
                                        <Modal isOpen={this.state.errorModalOpen} fade={false} toggle={() => this.toggleErrorModal()}>
                                            <ModalHeader className='text-danger' toggle={() => this.toggleErrorModal()}><i class="lnr-warning">&nbsp;</i>Lỗi</ModalHeader>
                                            <ModalBody>
                                                {this.state.error}
                                            </ModalBody>
                                        </Modal>
                                    </Card>
                                </Col>)}
                        </Row>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}

export default withRouter(CustomerPortfolioWithdrawRequests);

