import React, { Fragment } from "react";
import { Route, withRouter } from "react-router-dom";

import AppHeader from "../../Layout/AppHeader/";
import AppSidebar from "../../Layout/AppSidebar/";
import AppFooter from "../../Layout/AppFooter/";
import Error401 from "./401";

const Errors = ({ match }) => (
  <Fragment>
    <AppHeader />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Route path={`${match.path}/nopermission`} component={Error401} />
        </div>
        <AppFooter />
      </div>
    </div>
  </Fragment>
);

export default withRouter(Errors);
