import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, CardHeader, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import AppPagination from '../../../../Blocks/AppPagination';
import PortfolioService from '../../../../services/portfolio.service';
import PortfolioTransactionService from '../../../../services/portfolio.transaction.service';
import ApproveWithdrawRequestModal from './ApproveWithdrawRequestModal';

class PortfolioWithdrawRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requests: null,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false,
            errorModalOpen: false,
            error: '',
            approveWithdrawRequestModalOpen: false,
            approveWithdrawRequestId: ''
        };
        this.approveWithdrawRequest = this.approveWithdrawRequest.bind(this);
    }

    getUserToken() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token) {
            return user.token;
        }

        return '';
    }

    componentDidMount() {
        this.loadWithdrawRequests();
    }

    openApproveWithdrawRequestModal(requestId) {
        this.setState({
            approveWithdrawRequestId: requestId,
            approveWithdrawRequestModalOpen: true
        });
    }

    toggleApproveWithdrawRequestModal(){
        this.setState({
            approveWithdrawRequestModalOpen: !this.state.approveWithdrawRequestModalOpen
        });
    }

    denyWithdrawRequest(requestId) {
        this.setState({
            isLoading: true
        });
        new PortfolioTransactionService().denywithdrawrequest(requestId).then(
            response => {
                if (response.data) {
                    this.props.updateOverview();
                    this.loadWithdrawRequests();
                }
                else {
                    this.setState({
                        error: 'Lỗi server. Liên hệ lập trình viên',
                        errorModalOpen: true,
                        isLoading: false
                    });
                }
            },
            error => {
                this.setState({
                    error: error,
                    errorModalOpen: true,
                    isLoading: false
                });
            }
        );
    }

    approveWithdrawRequest(request) {
        this.setState({
            isLoading: true,
            approveWithdrawRequestModalOpen: false,
        });
        new PortfolioTransactionService().approveWithdrawRequest(request).then(
            response => {
                if (response.data) {
                    this.setState({
                        approveWithdrawRequestId: '',
                        errorModalOpen: false,
                        error: ''
                    });
                    this.props.updateOverview();
                    this.loadWithdrawRequests();
                }
                else {
                    this.setState({
                        error: 'Lỗi server. Liên hệ lập trình viên',
                        errorModalOpen: true,
                        isLoading: false
                    });
                }
            },
            error => {
                this.setState({
                    error: error,
                    errorModalOpen: true,
                    isLoading: false
                });
            }
        );
    }

    loadWithdrawRequests() {
        this.setState({
            isLoading: true
        });
        let { match } = this.props;
        let page = parseInt(match.params.page) ?? 1;
        new PortfolioService().withdrawRequests(match.params.portfolioId, page).then(
            response => {
                this.setState({
                    requests: response.data.items,
                    totalPages: response.data.totalPages,
                    hasPreviousPage: response.data.hasPreviousPage,
                    hasNextPage: response.data.hasNextPage,
                    isLoading: false
                });
            },
            error => {
                this.setState({
                    error: error.message,
                    isLoading: false
                })
            }
        );
    }

    render() {
        let { match } = this.props;
        let parentUrl = match.url.substring(0, match.url.lastIndexOf("/"));
        let page = parseInt(match.params.page) ?? 1;
        let { requests, hasNextPage, hasPreviousPage, totalPages, isLoading, approveWithdrawRequestId, approveWithdrawRequestModalOpen } = this.state;
        return (
            <Fragment>
                <TransitionGroup>
                    <CSSTransition
                        component="div"
                        className="TabsAnimation"
                        appear={true}
                        timeout={0}
                        enter={false}
                        exit={false}>
                        <Row>
                            {isLoading ? (
                                <div className="loader-container">
                                    <div className="loader-container-inner">
                                        <h6 className="mt-5">
                                            Hệ thống đang xử lý ...
                                        </h6>
                                    </div>
                                </div>
                            ) : (
                                <Col lg="12">
                                    <Card className="main-card mb-3">
                                        <CardHeader>
                                            <div className="card-header-title">
                                                <i className="header-icon lnr-exit-up icon-gradient bg-plum-plate"> </i>
                                                Yêu Cầu Rút Tiền
                                            </div>
                                            <div class="btn-actions-pane-right">

                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <div class="table-responsive">
                                                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">Người Dùng</th>
                                                            <th class="text-center">Số Tiền Rút</th>
                                                            <th class="text-center">Ngày Tạo Yêu Cầu</th>
                                                            <th class="text-center">Tình Trạng</th>
                                                            <th class="text-center">...</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {requests && requests.map(r => (
                                                            <tr>
                                                                <td class="text-center">
                                                                    {r.userName}
                                                                </td>
                                                                <td class="text-center">
                                                                    {r.value}
                                                                </td>
                                                                <td class="text-center">
                                                                    {r.date}
                                                                </td>
                                                                <td class="text-center">
                                                                    <div className={'badge ' + r.badgeStatus}>
                                                                        {r.status}
                                                                    </div>
                                                                </td>
                                                                <td class="text-center">
                                                                    {r.status === 'Đang chờ duyệt' && (
                                                                        <>
                                                                            <button class="btn btn-primary mb-2 me-2" onClick={() => this.openApproveWithdrawRequestModal(r.id)}>Duyệt</button>
                                                                            <button class="btn btn-secondary mb-2 me-2" onClick={() => this.denyWithdrawRequest(r.id)}>Từ chối</button>
                                                                        </>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                        <CardFooter className='justify-content-center'>
                                            <AppPagination totalPages={totalPages} parentUrl={parentUrl} page={page} hasNextPage={hasNextPage} hasPreviousPage={hasPreviousPage} />
                                        </CardFooter>
                                    </Card>
                                </Col>)}
                            {approveWithdrawRequestId !== '' && (
                                <ApproveWithdrawRequestModal handleSubmit={this.approveWithdrawRequest} requestId={approveWithdrawRequestId} open={approveWithdrawRequestModalOpen} toggle={() => this.toggleApproveWithdrawRequestModal()} />
                            )}
                            <Modal isOpen={this.state.errorModalOpen} fade={false} toggle={() => this.toggleErrorModal()}>
                                <ModalHeader className='text-danger' toggle={() => this.toggleErrorModal()}><i c lass="lnr-warning">&nbsp;</i>Lỗi</ModalHeader>
                                <ModalBody>
                                    {this.state.error}
                                </ModalBody>
                            </Modal>
                        </Row>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}

export default withRouter(PortfolioWithdrawRequests);

