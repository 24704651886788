import React, {Fragment} from 'react';
import { withRouter } from 'react-router-dom';
// Layout

import AppHeader from '../../Layout/AppHeader/';
import AppSidebar from '../../Layout/AppSidebar/';
import AppFooter from '../../Layout/AppFooter/';
import MarketListing from './Listing';
import MarketDetails from './Details';
import PrivateRoute from '../../common/private-route';
import { authRoles } from '../../utils/constants';

const Market = ({match}) => (
    <Fragment>
        <AppHeader/>
        <div className="app-main">
            <AppSidebar/>  
            <div className="app-main__outer">
                <div className="app-main__inner">
                    <PrivateRoute roles={[authRoles.ADMIN]} exact path={`${match.path}`} component={MarketListing}/>
                    <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/:marketId`}>
                        <MarketDetails/>
                    </PrivateRoute>
                </div>
                <AppFooter/>
            </div>
        </div>
    </Fragment>
)

export default withRouter(Market);