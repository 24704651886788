import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';
import { toISOStringWithTimezone } from '../../../../common/utils';

class CreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
        this.toggleCreateAssetModal = this.toggleCreateAssetModal.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    initialState = {
        created: toISOStringWithTimezone(new Date()),
        cash: '',
        assets: [],
        createAssetModal: false,
        createAssetName: '',
        createStockName: '',
        createAssetType: '0',
        createAssetQuantity: 0,
        createAssetAveragePrice: 0,
        createAssetCurrentPrice: 0,
    }

    toggleCreateAssetModal() {
        this.setState({
            createAssetModal: !this.state.createAssetModal
        });
    }

    addAssetToHistory(e) {
        e.stopPropagation();
        e.preventDefault();
        if (e.target.checkValidity()) {
            var asset = {
                type: parseInt(this.state.createAssetType),
                name: this.state.createAssetType === '0' ? this.state.createStockName : this.state.createAssetName,
                quantity: this.state.createAssetQuantity,
                averagePrice: this.state.createAssetAveragePrice,
                currentPrice: this.state.createAssetCurrentPrice
            };
            this.setState({
                createAssetName: '',
                createStockName: '',
                createAssetType: '0',
                createAssetQuantity: 0,
                createAssetAveragePrice: 0,
                createAssetCurrentPrice: 0,
                assets: [...this.state.assets, asset],
                createAssetModal: false
            })
        }
    }

    createAssetHistory(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            const request = {
                created: this.state.created,
                cash: this.state.cash,
                assets: this.state.assets
            };
            this.props.handleSubmit(request);
            this.setState(this.initialState);
        }
    }

    deleteAsset(index){
        if (this.state.assets.length > index){
            this.setState({
                assets: this.state.assets.filter((_, i) => i !== index) 
            });
        }
    }

    render() {
        const { open, toggle, stocks } = this.props;
        const { created, cash,
            createAssetName,
            createStockName,
            createAssetType,
            createAssetQuantity,
            createAssetAveragePrice,
            createAssetCurrentPrice, } = this.state;
        return (
            <Modal isOpen={open} fade={false} toggle={toggle}>
                <Form onSubmit={(e) => this.createAssetHistory(e)}>
                    <ModalHeader toggle={toggle}>Thêm Lịch Sử Tài Sản</ModalHeader>
                    <ModalBody scrollable>
                        <div class="row">
                            <div class="col-md-12">
                                <FormGroup>
                                    <Label>Ngày</Label>
                                    <Input onChange={this.handleInputChange} type="datetime-local" required value={created} id="created" name="created" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="cash">Tiền Mặt</Label>
                                    <InputGroup>
                                        <Input onChange={this.handleInputChange} value={cash} type="number" id="cash" min="0" name="cash" />
                                        <InputGroupText>đ</InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div class="col-md-12">
                                <div class="main-card mb-3 card">
                                    <div class="card-header">
                                        <div className="card-header-title">
                                            Tài Sản
                                        </div>
                                        <div class="btn-actions-pane-right">
                                            <button class="btn btn-primary mb-2 me-2" type="button" onClick={this.toggleCreateAssetModal}>Thêm Tài Sản</button>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Loại</th>
                                                        <th>Tên</th>
                                                        <th>Số Lượng</th>
                                                        <th>Giá Vốn Trung Bình</th>
                                                        <th>Giá Hiện Tại</th>
                                                        <th>...</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.assets.map((a,i) => (
                                                        <tr>
                                                            <td>{a.type === '0' ? 'Cổ Phiếu' : 'Khác'}</td>
                                                            <td>{a.name}</td>
                                                            <td>{a.quantity}</td>
                                                            <td>{a.averagePrice}</td>
                                                            <td>{a.currentPrice}</td>
                                                            <td>
                                                                <Button className='mb-2 me-2' onClick={() => this.deleteAsset(i)}>Xóa</Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal scrollable={true} isOpen={this.state.createAssetModal} toggle={this.toggleCreateAssetModal}>
                            <Form onSubmit={(e) => this.addAssetToHistory(e)}>
                                <ModalHeader>Thêm Tài Sản</ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <Label for="createAssetType">Loại</Label>
                                        <Input onChange={this.handleInputChange} type="select" value={createAssetType} id="createAssetType" name="createAssetType">
                                            <option value="0">Cổ phiếu</option>
                                            <option value="1">Khác</option>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Tên</Label>
                                        <Input onChange={this.handleInputChange} type="text" required={createAssetType === '1'} hidden={createAssetType === '0'} value={createAssetName} id="createAssetName" name="createAssetName" />
                                        <Input onChange={this.handleInputChange} type="select" required={createAssetType === '0'} hidden={createAssetType === '1'} value={createStockName} id="createStockName" name="createStockName">
                                            <option value="">Chọn Cổ phiếu</option>
                                            {stocks && stocks.map((st) => (
                                                <option value={st}>{st}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="createAssetQuantity">Số Lượng</Label>
                                        <Input onChange={this.handleInputChange} value={createAssetQuantity} type="number" min="1" max="" required id="createAssetQuantity" name="createAssetQuantity" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="createAssetAveragePrice">Giá Vốn Trung Bình</Label>
                                        <InputGroup>
                                            <Input onChange={this.handleInputChange} value={createAssetAveragePrice} type="number" id="createAssetAveragePrice" min="1" name="createAssetAveragePrice" />
                                            <InputGroupText>đ</InputGroupText>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="createAssetCurrentPrice">Giá Hiện Tại (Lịch Sử)</Label>
                                        <InputGroup>
                                            <Input onChange={this.handleInputChange} value={createAssetCurrentPrice} type="number" id="createAssetCurrentPrice" min="1" name="createAssetCurrentPrice" />
                                            <InputGroupText>đ</InputGroupText>
                                        </InputGroup>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    <Button className='mb-2 me-2' color="primary" type='submit'>Thêm</Button>
                                    <Button className='mb-2 me-2' color="secondary" onClick={this.toggleCreateAssetModal}>Hủy</Button>{' '}
                                </ModalFooter>
                            </Form>
                        </Modal>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='mb-2 me-2' color='primary' type="submit">Thêm</Button>
                        <Button className='mb-2 me-2' color='secondary' type="button" onClick={toggle}>Hủy</Button>
                    </ModalFooter>
                </Form>
            </Modal >
        )
    }
}

export default CreateModal;