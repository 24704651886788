import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import PrivateRoute from "../../../common/private-route";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import ScheduledJobService from "../../../services/scheduledjob.service";
import ScheduledJobForm from "./ScheduledJobForm";
import ScheduledJobHistory from "./ScheduledJobHistory";

class ScheduledJobDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduledJob: null,
    };
  }

  componentDidMount() {
    let { match } = this.props;
    new ScheduledJobService().detail(match.params.name).then(
      (response) => {
        this.setState({
          scheduledJob: response.data,
        });
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
        });
      }
    );
  }

  render() {
    let { match } = this.props;
    let { scheduledJob } = this.state;
    return (
      <Fragment>
        <PageTitle
          heading={scheduledJob && scheduledJob.scheduledJobDisplayName}
          icon="pe-7s-portfolio icon-gradient bg-amy-crisp"
        />
        <Nav pills>
          <NavItem>
            <NavLink className="nav-link" to={`${match.url}/form`}>
              Scheduled job
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="nav-link" to={`${match.url}/history`}>
              History
            </NavLink>
          </NavItem>
        </Nav>
        <PrivateRoute
          path={`${match.path}/form`}
          render={(props) => (
            <ScheduledJobForm scheduledJobName={match.params.name} />
          )}
        />
        <PrivateRoute
          path={`${match.path}/history`}
          render={(props) => (
            <ScheduledJobHistory scheduledJobName={match.params.name} />
          )}
        />
      </Fragment>
    );
  }
}

export default ScheduledJobDetail;
