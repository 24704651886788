import React, { Fragment } from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';

class AddPortfolioModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        name: '',
        cash: 1000000,
        hanMuc: 6,
        hieuQua: 25,
        phiRutTien: 0.05,
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    addPortfolio(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            this.props.handleSubmit(this.state);
            this.setState(this.initialState);
        }
    }

    render() {
        let { open, toggle } = this.props;
        let { name, cash, hanMuc, hieuQua, phiRutTien } = this.state;
        return (
            <Fragment>
                <Modal isOpen={open} fade={false} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Thêm Portfolio</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={(e) => this.addPortfolio(e)}>
                            <FormGroup>
                                <Label for="name">Tên</Label>
                                <Input onChange={this.handleInputChange} type="text" required value={name} id="name" name="name" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="cash">Tiền Mặt</Label>
                                <InputGroup>
                                    <Input onChange={this.handleInputChange} value={cash} type="number" min="1" max="" required id="cash" name="cash" />
                                    <InputGroupText>đ</InputGroupText>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="hanMuc">Hạn Mức</Label>
                                <InputGroup>
                                    <Input onChange={this.handleInputChange} value={hanMuc} type="number" id="hanMuc" min="0" name="hanMuc" />
                                    <InputGroupText>%</InputGroupText>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="hieuQua">Hiệu Quả</Label>
                                <InputGroup>
                                    <Input onChange={this.handleInputChange} value={hieuQua} type="number" id="hieuQua" min="0" name="hieuQua" />
                                    <InputGroupText>%</InputGroupText>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="phiRutTien">Phí Rút Tiền</Label>
                                <InputGroup>
                                    <Input onChange={this.handleInputChange} value={phiRutTien} type="number" step=".01" min="0" max="99.99" name="phiRutTien" id="phiRutTien" />
                                    <InputGroupText>%</InputGroupText>
                                </InputGroup>
                            </FormGroup>
                            <Button color='primary' className='me-1' type="submit">Thêm</Button>
                            <Button color='secondary' type="button" onClick={toggle}>Thoát</Button>
                        </Form>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

export default AddPortfolioModal;