import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';
import { toISOStringWithTimezone } from '../../../../common/utils';

class UpsertModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        logId: '',
        date: toISOStringWithTimezone(new Date()),
        message: '',
        note: ''
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (prevProps.logId !== this.props.logId) {
            const {log} = this.props;
            if (log) {
                this.setState({
                    logId: this.props.logId,
                    date: toISOStringWithTimezone(new Date(log.isoFormatDate)),
                    message: log.message.replaceAll("<br />", ". "),
                    note: log.note
                });
            } else {
                this.setState(this.initialState)
            }
        }
    }

    upsertLog(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            const request = {
                id: this.props.logId,
                portfolioId: this.props.portfolioId,
                message: this.state.message,
                date: this.state.date,
                note: this.state.note
            };
            this.props.handleSubmit(request);
            this.setState(this.initialState);
        }
    }

    render() {
        const { open, toggle } = this.props;
        let { date, message, note, logId } = this.state;
        return (
            <Modal isOpen={open} fade={false} toggle={toggle}>
                <Form onSubmit={(e) => this.upsertLog(e)}>
                    <ModalHeader toggle={toggle}>{logId !== '' ? 'Cập Nhật' : 'Thêm'} Portfolio Log</ModalHeader>
                    <ModalBody>
                        <div class="row">
                            <div class="col-md-12">
                                <FormGroup>
                                    <Label for="date">Ngày Giao Dịch</Label>
                                    <Input onChange={this.handleInputChange} value={date} type="datetime-local" required step="1" id="date" name="date" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="message">Nội Dung</Label>
                                    <Input onChange={this.handleInputChange} value={message} type="textarea" name="message" id="message" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="note">Ghi Chú</Label>
                                    <Input onChange={this.handleInputChange} value={note} type="textarea" name="note" id="note" />
                                </FormGroup>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='mb-2 me-2' color='primary' type="submit">{logId !== '' ? 'Cập Nhật' : 'Thêm'}</Button>
                        <Button className='mb-2 me-2' color='secondary' type="button" onClick={toggle}>Hủy</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

export default UpsertModal;