import React, { Fragment } from "react";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import ContactUsService from "../../../services/contactus.service";

class SendEmailContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      emailAddress: "",
      content: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  componentDidMount() {
    let { match } = this.props;
    if (match.params.ticketId) {
      new ContactUsService().detail("me", match.params.ticketId).then(
        (response) => {
          this.setState({
            detail: response.data,
          });
        },
        (error) => {
          this.setState({
            error: error.message,
          });
        }
      );
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  sendEmail(e) {
    e.preventDefault();
    let { emailAddress, content } = this.state;
    new ContactUsService()
      .sendEmail("me", { EmailAddress: emailAddress, Content: content })
      .then(
        (response) => {
          if (response.data)
            this.props.history.push("/contactus/tickets/" + response.data.id);
        },
        (error) => {
          this.setState({
            error: error.message,
          });
        }
      );
  }

  render() {
    return (
      <Fragment>
        <PageTitle
          heading="Thông tin yêu cầu hỗ trợ"
          icon="pe-7s-portfolio icon-gradient bg-amy-crisp"
        />
        <Row>
          <Col className="col-8 mt-2">
            <Card>
              <CardHeader>
                <div className="card-header-title">
                  <i className="header-icon lnr-history icon-gradient bg-plum-plate"></i>
                  Điền thông tin bên dưới để gửi yêu cầu hỗ trợ đến chúng tôi
                </div>
              </CardHeader>
              <CardBody>
                <Form onSubmit={(e) => this.sendEmail(e)}>
                  <FormGroup>
                    <Label>Email</Label>
                    <Input
                      type="text"
                      onChange={this.handleInputChange}
                      name="emailAddress"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Content</Label>
                    <Input
                      type="textarea"
                      onChange={this.handleInputChange}
                      name="content"
                      rows="5"
                    />
                  </FormGroup>
                  <Button className="mb-2 me-2" type="submit">
                    Gửi email
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default SendEmailContactUs;
