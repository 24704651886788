import React, { Fragment } from "react";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  ModalBody,
  Modal,
  ModalHeader,
} from "reactstrap";
import ContactUsService from "../../../services/contactus.service";
class ContactUsTicketListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets: [],
      error: "",
      errorModalOpen: false,
    };

    this.loadTickets = this.loadTickets.bind(this);
  }

  loadTickets() {
    new ContactUsService().listTicket("me").then(
      (response) => {
        this.setState({
          tickets: response.data,
        });
      },
      (error) => {
        this.setState({
          tickets: [],
          error: error.message,
        });
      }
    );
  }

  componentDidMount() {
    this.loadTickets();
  }

  markTicketAsDone(ticketId) {
    new ContactUsService().markTicketAsDone("me", ticketId).then(
      (response) => {
        this.loadTickets();
      },
      (error) => {
        this.setState({
          tickets: [],
          error: error.message,
        });
      }
    );
  }
  render() {
    return (
      <Fragment>
        <PageTitle
          heading="Danh sách yêu cầu hỗ trợ"
          icon="pe-7s-portfolio icon-gradient bg-amy-crisp"
        />
        <Row>
          <Col className="col-12">
            <Card>
              <CardHeader>
                <div className="card-header-title">Danh sách</div>
              </CardHeader>
              <CardBody>
                <div class="table-responsive">
                  <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                      <tr>
                        <th class="text-center">#</th>
                        <th>From email</th>
                        <th class="text-center">Content</th>
                        <th class="text-center">Created date</th>
                        <th class="text-center">Updated date</th>
                        <th class="text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.tickets &&
                        this.state.tickets.map((ticket, i) => {
                          return (
                            <tr>
                              <td class="text-center" scope="row">
                                {i + 1}
                              </td>
                              <td class="text-center">{ticket.emailAddress}</td>
                              <td class="text-center">{ticket.content}</td>
                              <td class="text-center">{ticket.createdAt}</td>
                              <td class="text-center">{ticket.updatedDate}</td>
                              <td class="text-center">
                                {ticket.status != "Done" ? (
                                  <button
                                    class="btn btn-warning mb-2 me-2"
                                    onClick={() =>
                                      this.markTicketAsDone(ticket.id)
                                    }
                                  >
                                    <i className="fa fa-toggle-off fa-w-20"></i>
                                    Waiting
                                  </button>
                                ) : (
                                  <button class="btn btn-success mb-2 me-2">
                                    <i className="fa fa-toggle-on fa-w-20"> </i>
                                    Done
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.errorModalOpen}
          fade={false}
          toggle={() => this.toggleErrorModal()}
        >
          <ModalHeader
            className="text-danger"
            toggle={() => this.toggleErrorModal()}
          >
            <i class="lnr-warning">&nbsp;</i>Lỗi
          </ModalHeader>
          <ModalBody>{this.state.error}</ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default ContactUsTicketListing;
