import React from 'react';
class CashSourceListing extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div class="main-card mb-3 card">
                <div class="card-header">Danh Sách Nguồn Tiền
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Nguồn Tiền</th>
                                    <th>Giá Trị</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.cashSources && this.props.cashSources.map((cs) => (
                                    <tr>
                                        <td>{cs.name}</td>
                                        <td>{cs.value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default CashSourceListing;