import React, { Fragment } from 'react';
import update from 'immutability-helper';
import { withRouter, NavLink } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, CardHeader, CardFooter, Collapse, ModalBody, Modal, ModalHeader, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import AppPagination from '../../../../../Blocks/AppPagination';
import PortfolioService from '../../../../../services/portfolio.service';
import CreateModal from './CreateModal';
import CensoredInfo from '../../../../../common/censored-info';

class CustomerAssetHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assetHistories: null,
            isLoading: false,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false,
            errorModalOpen: false,
            error: '',
            deleteAssetHistoryModalOpen: false,
            deleteAssetHistoryDate: '',
            deleteAssetHistoryId: '',
            createAssetHistoryModalOpen: false,
            userName: '',
            importAssetHistoryModalOpen: false,
            importFile: null
        };
        this.createAssetHistory = this.createAssetHistory.bind(this);
        this.deleteAssetHistory = this.deleteAssetHistory.bind(this);
        this.importCustomerAssetHistory = this.importCustomerAssetHistory.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.loadCustomerAssetHistories = this.loadCustomerAssetHistories.bind(this);
    }

    componentDidMount() {
        this.loadCustomerAssetHistories();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'file' ? target.files[0] : target.value;

        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    toggleCreateAssetHistoryModal() {
        this.setState({
            createAssetHistoryModalOpen: !this.state.createAssetHistoryModalOpen
        });
    }

    toggleImportAssetHistoryModal() {
        this.setState({
            importAssetHistoryModalOpen: !this.state.importAssetHistoryModalOpen
        })
    }

    loadCustomerAssetHistories() {
        let { match } = this.props;
        let page = parseInt(match.params.page) ?? 1;
        new PortfolioService().customerAssetHistories(match.params.portfolioId, match.params.userId, page).then(
            response => {
                this.setState({
                    isLoading: false,
                    assetHistories: response.data.details.items,
                    totalPages: response.data.details.totalPages,
                    hasPreviousPage: response.data.details.hasPreviousPage,
                    hasNextPage: response.data.details.hasNextPage,
                    userName: response.data.userName
                });
            },
            error => {
                this.setState({
                    isLoading: false,
                    error: error.message
                })
            }
        );
    }

    createAssetHistory(request) {
        this.setState({
            isLoading: true
        });
        request.portfolioId = this.props.match.params.portfolioId;
        request.customerId = this.props.match.params.userId;
        new PortfolioService().addOrUpdateCustomerAssetHistory(request).then(
            response => {
                if (response.data.success) {
                    this.loadCustomerAssetHistories();
                    this.setState({
                        isLoading: false,
                        createAssetHistoryModalOpen: false
                    });
                }
            }, error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    createAssetHistoryModalOpen: false,
                    isLoading: false
                });
            }
        )
    }

    importCustomerAssetHistory(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            this.setState({
                isLoading: true
            });
            var formData = new FormData();
            formData.append('portfolioId', this.props.match.params.portfolioId);
            formData.append('customerId', this.props.match.params.userId)
            formData.append('importFile', this.state.importFile);
            new PortfolioService().importCustomerAssetHistory(formData).then(
                response => {
                    if (response.data.success) {
                        this.loadCustomerAssetHistories();
                        this.setState({
                            isLoading: false,
                            importAssetHistoryModalOpen: false,
                            importFile: null
                        });
                    }
                }, error => {
                    this.setState({
                        error: error.message,
                        errorModalOpen: true,
                        importAssetHistoryModalOpen: false,
                        isLoading: false
                    });
                }
            );
        }
    }

    openDeleteModal(createdDate, id) {
        this.setState({
            deleteAssetHistoryModalOpen: true,
            deleteAssetHistoryDate: createdDate,
            deleteAssetHistoryId: id
        });
    }

    toggleDeleteAssetHistoryModal() {
        this.setState({
            deleteAssetHistoryModalOpen: !this.state.deleteAssetHistoryModalOpen
        });
    }

    toggleErrorModal() {
        this.setState({
            errorModalOpen: !this.state.errorModalOpen
        });
    }

    deleteAssetHistory() {
        this.setState({
            isLoading: true
        });
        new PortfolioService().deleteCustomerAssetHistory(this.state.deleteAssetHistoryId).then(
            response => {
                if (response.data.success) {
                    this.loadCustomerAssetHistories();
                    this.setState({
                        errorModalOpen: false,
                        deleteAssetHistoryModalOpen: false,
                        deleteAssetHistoryDate: '',
                        deleteAssetHistoryId: '',
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        error: 'Lỗi server. Liên hệ lập trình viên',
                        errorModalOpen: true,
                        deleteAssetHistoryModalOpen: false,
                        isLoading: false
                    });
                }
            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    deleteAssetHistoryModalOpen: false,
                    isLoading: false
                });
            }
        );
    }

    render() {
        const { match, censored } = this.props;
        const parentUrl = match.url.substring(0, match.url.lastIndexOf("/"));
        var ccqDetailsStr = 'ccqdetails';
        let page = parseInt(match.params.page) ?? 1;
        let { userName, isLoading, assetHistories, hasNextPage, hasPreviousPage, totalPages, deleteAssetHistoryModalOpen,
            deleteAssetHistoryDate, importAssetHistoryModalOpen } = this.state;
        return (
            <Fragment>
                <TransitionGroup>
                    <CSSTransition
                        component="div"
                        className="TabsAnimation"
                        appear={true}
                        timeout={0}
                        enter={false}
                        exit={false}>
                        <Row>
                            {isLoading ? (
                                <div className="loader-container">
                                    <div className="loader-container-inner">
                                        <h6 className="mt-5">
                                            Hệ thống đang xử lý ...
                                        </h6>
                                    </div>
                                </div>
                            ) : (
                                <Col lg="12">
                                    <div><NavLink className="btn btn-primary mb-2 me-2" to={`${match.url.substring(0, match.url.indexOf(ccqDetailsStr) + ccqDetailsStr.length)}`}>Quay Về</NavLink></div>
                                    <Card className="main-card mb-3">
                                        <CardHeader>
                                            <div className="card-header-title">
                                                <i className="header-icon lnr-history icon-gradient bg-plum-plate"> </i>
                                                <b>{userName}</b> - Lịch Sử Tài Sản
                                            </div>
                                            <div class="btn-actions-pane-right">
                                                <button class="btn btn-primary mb-2 me-2" onClick={() => this.toggleCreateAssetHistoryModal()}>Thêm Lịch Sử Tài Sản</button>
                                                <button class="btn btn-primary mb-2 me-2" onClick={() => this.toggleImportAssetHistoryModal()}>Import Lịch Sử Tài Sản</button>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <div class="table-responsive">
                                                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">Ngày Tạo</th>
                                                            <th class="text-center">Tổng Giá Trị</th>
                                                            <th class="text-center">...</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {assetHistories && assetHistories.map((ah, i) => (
                                                            <>
                                                                <tr>
                                                                    <td class="text-center">
                                                                        {ah.createdDate}
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <CensoredInfo content={ah.value} censored={censored} />
                                                                    </td>
                                                                    <td class="text-center">
                                                                        {ah.note && (
                                                                            <button class="btn btn-secondary mb-2 me-2" data-toggle="tooltip" data-placement="top"
                                                                                title={ah.note}>
                                                                                <i class="lnr-file-empty"></i>
                                                                            </button>
                                                                        )}
                                                                        <button class="btn btn-primary mb-2 me-2" onClick={() => this.openDeleteModal(ah.createdDate, ah.id)}>Xóa</button>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                        <CardFooter className='justify-content-center'>
                                            <AppPagination totalPages={totalPages} parentUrl={parentUrl} page={page} hasNextPage={hasNextPage} hasPreviousPage={hasPreviousPage} />
                                        </CardFooter>
                                    </Card>
                                    <CreateModal handleSubmit={this.createAssetHistory} open={this.state.createAssetHistoryModalOpen} toggle={() => this.toggleCreateAssetHistoryModal()} />
                                    <Modal isOpen={deleteAssetHistoryModalOpen} fade={false} toggle={() => this.toggleDeleteAssetHistoryModal()}>
                                        <ModalHeader toggle={() => this.toggleDeleteAssetHistoryModal()}>Xác Nhận Xóa</ModalHeader>
                                        <ModalBody>
                                            Bạn muốn xóa lịch sử <strong>{deleteAssetHistoryDate}</strong> ?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button class="btn btn-primary mb-2 me-2" onClick={this.deleteAssetHistory}>Có</button>
                                            <button class="btn btn-secondary mb-2 me-2" onClick={() => this.toggleDeleteAssetHistoryModal()}>Không</button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal isOpen={importAssetHistoryModalOpen} fade={false} toggle={() => this.toggleImportAssetHistoryModal()}>
                                        <Form onSubmit={this.importCustomerAssetHistory} method='POST'>
                                            <ModalHeader toggle={() => this.toggleImportAssetHistoryModal()}>Import Lịch Sử Tài Sản</ModalHeader>
                                            <ModalBody>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <FormGroup>
                                                            <Label>Tệp</Label>
                                                            <Input onChange={this.handleInputChange} type="file" required id="importFile" name="importFile" />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </ModalBody>
                                            <ModalFooter>
                                                <button class="btn btn-primary mb-2 me-2" type='submit'>Có</button>
                                                <button class="btn btn-secondary mb-2 me-2" onClick={() => this.toggleImportAssetHistoryModal()}>Không</button>
                                            </ModalFooter>
                                        </Form>
                                    </Modal>
                                    <Modal isOpen={this.state.errorModalOpen} fade={false} toggle={() => this.toggleErrorModal()}>
                                        <ModalHeader className='text-danger' toggle={() => this.toggleErrorModal()}><i class="lnr-warning">&nbsp;</i>Lỗi</ModalHeader>
                                        <ModalBody>
                                            {this.state.error}
                                        </ModalBody>
                                    </Modal>
                                </Col>
                            )}
                        </Row>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}

export default withRouter(CustomerAssetHistory);
