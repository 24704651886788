import React, { Fragment } from 'react';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, CardHeader, CardFooter, Collapse, ModalBody, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import AppPagination from '../../../../Blocks/AppPagination';
import PortfolioService from '../../../../services/portfolio.service';
import SortModal from '../Assets/SortModal';
import { faL } from '@fortawesome/free-solid-svg-icons';
import CreateModal from './CreateModal';

class PortfolioAssetHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            assetHistories: null,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false,
            assetHistoryDetailsExpanded: [],
            createAssetHistoryModalOpen: false,
            sortAssetHistoryModalOpen: false,
            assetHistoryDateToSort: '',
            assetHistoryItemsToSort: null,
            assetHistoryIdToSort: '',
            deleteAssetHistoryModalOpen: false,
            deleteAssetHistoryDate: '',
            deleteAssetHistoryId: '',
            errorModalOpen: false,
            error: '',
            stocks: []
        };
        this.deleteAssetHistory = this.deleteAssetHistory.bind(this);
        this.updateAssetHistoryOrder = this.updateAssetHistoryOrder.bind(this);
        this.createAssetHistory = this.createAssetHistory.bind(this);
    }

    componentDidMount() {
        this.loadAssetHistories();
        this.loadStocks();
    }

    loadStocks() {
        new PortfolioService().stocks().then(response => {
            if (response) {
                this.setState({
                    stocks: response.data
                })
            }
        });
    }

    loadAssetHistories() {
        let { match } = this.props;
        let page = parseInt(match.params.page) ?? 1;
        new PortfolioService().assetHistories(match.params.portfolioId, page).then(
            response => {
                this.setState({
                    isLoading: false,
                    assetHistories: response.data.items,
                    totalPages: response.data.totalPages,
                    hasPreviousPage: response.data.hasPreviousPage,
                    hasNextPage: response.data.hasNextPage,
                    assetHistoryDetailsExpanded: Array.from({ length: response.data.items.length }, i => i = false)
                });
            },
            error => {
                this.setState({
                    isLoading: false,
                    error: error.message
                })
            }
        );
    }

    openDeleteModal(date, historyId) {
        this.setState({
            deleteAssetHistoryModalOpen: true,
            deleteAssetHistoryDate: date,
            deleteAssetHistoryId: historyId
        });
    }

    openSortModal(assetHistory) {
        if (assetHistory) {
            this.setState({
                assetHistoryDateToSort: assetHistory.createdDate,
                assetHistoryItemsToSort: assetHistory.assets,
                assetHistoryIdToSort: assetHistory.id,
                sortAssetHistoryModalOpen: true
            })
        }
    }

    toggleDeleteAssetHistoryModal() {
        this.setState({
            deleteAssetHistoryModalOpen: !this.state.deleteAssetHistoryModalOpen
        });
    }

    toggleDeleteNAVCCQModal() {
        this.setState({
            deleteNAVCCQHistoryModalOpen: !this.state.deleteNAVCCQHistoryModalOpen
        });
    }

    toggleSortAssetHistoryModal() {
        this.setState({
            sortAssetHistoryModalOpen: !this.state.sortAssetHistoryModalOpen
        });
    }

    toggleAddAssetHistoryModal() {
        this.setState({
            createAssetHistoryModalOpen: !this.state.createAssetHistoryModalOpen
        });
    }

    deleteAssetHistory() {
        this.setState({
            isLoading: true
        });
        var request = {
            portfolioId: this.props.match.params.portfolioId,
            historyId: this.state.deleteAssetHistoryId
        };
        new PortfolioService().deleteAssetHistory(request).then(
            response => {
                if (response.data) {
                    this.props.updateOverview();
                    this.setState({
                        assetHistories: response.data.items,
                        totalPages: response.data.totalPages,
                        hasPreviousPage: response.data.hasPreviousPage,
                        hasNextPage: response.data.hasNextPage,
                        errorModalOpen: false,
                        deleteAssetHistoryModalOpen: false,
                        deleteAssetHistoryDate: '',
                        deleteAssetHistoryId: '',
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        error: 'Lỗi server. Liên hệ lập trình viên',
                        errorModalOpen: true,
                        deleteAssetHistoryModalOpen: false,
                        isLoading: false
                    });
                }
            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    deleteAssetHistoryModalOpen: false,
                    isLoading: false
                });
            }
        );
    }

    updateAssetHistoryOrder(assets) {
        this.setState({
            isLoading: true
        });
        let request = {
            portfolioId: this.props.match.params.portfolioId,
            historyId: this.state.assetHistoryIdToSort,
            assets: assets
        };
        new PortfolioService().sortAssetHistory(request).then(
            response => {
                this.setState({
                    isLoading: false,
                    assetHistories: response.data.items,
                    totalPages: response.data.totalPages,
                    hasPreviousPage: response.data.hasPreviousPage,
                    hasNextPage: response.data.hasNextPage,
                    assetHistoryDateToSort: '',
                    assetHistoryItemsToSort: null,
                    assetHistoryIdToSort: '',
                    sortAssetHistoryModalOpen: false
                });
            },
            error => {
                this.setState({
                    isLoading: false,
                    error: error.message,
                    sortAssetHistoryModalOpen: false,
                    assetHistoryDateToSort: '',
                    assetHistoryItemsToSort: null,
                    assetHistoryIdToSort: '',
                    errorModalOpen: true
                })
            }
        );
    }

    createAssetHistory(request) {
        if (request) {
            this.setState({
                isLoading: true
            });
            request.portfolioId = this.props.match.params.portfolioId;
            new PortfolioService().createAssetHistory(request).then(
                response => {
                    if (response.data) {
                        this.props.updateOverview();
                        this.setState({
                            isLoading: false,
                            assetHistories: response.data.items,
                            totalPages: response.data.totalPages,
                            hasPreviousPage: response.data.hasPreviousPage,
                            hasNextPage: response.data.hasNextPage,
                            createAssetHistoryModalOpen: false
                        });
                    }
                }, error => {
                    this.setState({
                        error: error.message,
                        errorModalOpen: true,
                        createAssetHistoryModalOpen: false,
                        isLoading: false
                    });
                }
            );
        }
    }

    toggleAssetHistoriesDropdown(i) {
        let valueToUpdate = !this.state.assetHistoryDetailsExpanded[i];
        this.setState({ assetHistoryDetailsExpanded: update(this.state.assetHistoryDetailsExpanded, { [i]: { $set: valueToUpdate } }) });
    }

    toggleErrorModal() {
        this.setState({ errorModalOpen: !this.state.errorModalOpen });
    }

    render() {
        let { match } = this.props;
        let parentUrl = match.url.substring(0, match.url.lastIndexOf("/"));
        let page = parseInt(match.params.page) ?? 1;
        let { isLoading, assetHistories, hasNextPage, hasPreviousPage,
            totalPages, assetHistoryDetailsExpanded,
            deleteAssetHistoryModalOpen, deleteAssetHistoryDate,
            sortAssetHistoryModalOpen, assetHistoryDateToSort, assetHistoryItemsToSort } = this.state;
        return (
            <Fragment>
                <TransitionGroup>
                    <CSSTransition
                        component="div"
                        className="TabsAnimation"
                        appear={true}
                        timeout={0}
                        enter={false}
                        exit={false}>
                        <Row>
                            {isLoading ? (
                                <div className="loader-container">
                                    <div className="loader-container-inner">
                                        <h6 className="mt-5">
                                            Hệ thống đang xử lý ...
                                        </h6>
                                    </div>
                                </div>
                            ) : (
                                <Col lg="12">
                                    <Card className="main-card mb-3">
                                        <CardHeader>
                                            <div className="card-header-title">
                                                <i className="header-icon lnr-history icon-gradient bg-plum-plate"> </i>
                                                Lịch Sử Tài Sản
                                            </div>
                                            <div class="btn-actions-pane-right">
                                                <button class="btn btn-primary mb-2 me-2" onClick={() => this.toggleAddAssetHistoryModal()}>Thêm Lịch Sử Tài Sản</button>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <div class="table-responsive">
                                                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">Ngày Tạo</th>
                                                            <th class="text-center">Tổng Giá Trị</th>
                                                            <th class="text-center">Chi Tiết</th>
                                                            <th class="text-center">...</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {assetHistories && assetHistories.map((ah, i) => (
                                                            <>
                                                                <tr>
                                                                    <td class="text-center">
                                                                        {ah.createdDate}
                                                                    </td>
                                                                    <td class="text-center">
                                                                        {ah.totalValue}
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <button class="btn btn-primary mb-2 me-2" onClick={() => this.toggleAssetHistoriesDropdown(i)}>Chi Tiết</button>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <button class="btn btn-warning mb-2 me-2" onClick={() => this.openSortModal(ah)}>Sắp Xếp</button>
                                                                        <button class="btn btn-primary mb-2 me-2" onClick={() => this.openDeleteModal(ah.createdDate, ah.id)}>Xóa</button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="8">
                                                                        <Collapse isOpen={assetHistoryDetailsExpanded[i]}>
                                                                            <table class="align-middle mb-0 table table-sm">
                                                                                <thead>
                                                                                    <tr class="table-info">
                                                                                        <th class="text-center">Tên</th>
                                                                                        <th class="text-center">Loại</th>
                                                                                        <th class="text-center">Số Lượng</th>
                                                                                        <th class="text-center">Giá Vốn Trung Bình</th>
                                                                                        <th class="text-center">Giá Lúc Tạo</th>
                                                                                        <th class="text-center">Tổng Giá Trị</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr class="table-info">
                                                                                        <td class="text-center">
                                                                                            Tiền Mặt
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            Khác
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            1
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            ...
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            ...
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            {ah.totalCash}
                                                                                        </td>
                                                                                    </tr>
                                                                                    {ah.assets && ah.assets.map((a) => (
                                                                                        <tr class="table-info">
                                                                                            <td class="text-center">
                                                                                                {a.name}
                                                                                            </td>
                                                                                            <td class="text-center">
                                                                                                {a.type}
                                                                                            </td>
                                                                                            <td class="text-center">
                                                                                                {a.quantity}
                                                                                            </td>
                                                                                            <td class="text-center">
                                                                                                {a.averagePrice}
                                                                                            </td>
                                                                                            <td class="text-center">
                                                                                                {a.currentPrice}
                                                                                            </td>
                                                                                            <td class="text-center">
                                                                                                {a.totalValue}
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </Collapse>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                        <CardFooter className='justify-content-center'>
                                            <AppPagination totalPages={totalPages} parentUrl={parentUrl} page={page} hasNextPage={hasNextPage} hasPreviousPage={hasPreviousPage} />
                                        </CardFooter>
                                    </Card>
                                    {assetHistoryItemsToSort && (
                                        <SortModal title={`Sắp Xếp Tài Sản cho Lịch Sử ${assetHistoryDateToSort}`} updateSortedItems={this.updateAssetHistoryOrder} items={assetHistoryItemsToSort.map(a => a.name)} toggle={() => this.toggleSortAssetHistoryModal()} open={sortAssetHistoryModalOpen} />
                                    )}
                                    <CreateModal handleSubmit={this.createAssetHistory} stocks={this.state.stocks} open={this.state.createAssetHistoryModalOpen} toggle={() => this.toggleAddAssetHistoryModal()} />
                                    <Modal isOpen={deleteAssetHistoryModalOpen} fade={false} toggle={() => this.toggleDeleteAssetHistoryModal()}>
                                        <ModalHeader toggle={() => this.toggleDeleteAssetHistoryModal()}>Xác Nhận Xóa</ModalHeader>
                                        <ModalBody>
                                            Bạn muốn xóa lịch sử <strong>{deleteAssetHistoryDate}</strong> ?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button class="btn btn-primary mb-2 me-2" onClick={this.deleteAssetHistory}>Có</button>
                                            <button class="btn btn-secondary mb-2 me-2" onClick={() => this.toggleDeleteAssetHistoryModal()}>Không</button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal isOpen={this.state.errorModalOpen} fade={false} toggle={() => this.toggleErrorModal()}>
                                        <ModalHeader className='text-danger' toggle={() => this.toggleErrorModal()}><i class="lnr-warning">&nbsp;</i>Lỗi</ModalHeader>
                                        <ModalBody>
                                            {this.state.error}
                                        </ModalBody>
                                    </Modal>
                                </Col>
                            )}
                        </Row>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}

export default withRouter(PortfolioAssetHistory);
