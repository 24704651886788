import React, { Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, CardHeader, CardFooter,  ModalBody, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import AppPagination from '../../../../Blocks/AppPagination';
import PortfolioService from '../../../../services/portfolio.service';
import UpsertModal from './UpsertModal';

class PortfolioLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logs: null,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false,
            deletePortfolioLogModalOpen: false,
            deletePortfolioLogContent: '',
            deletePortfolioLogId: '',
            upsertPortfolioLogModalOpen: false,
            upsertPortfolioLogId: '',
            upsertPortfolioLog: null
        };
        this.deletePortfolioLog = this.deletePortfolioLog.bind(this);
        this.upsertPortfolioLog = this.upsertPortfolioLog.bind(this);
    }

    openDeletePortfolioLogModal(log) {
        if (log) {
            this.setState({
                deletePortfolioLogModalOpen: true,
                deletePortfolioLogId: log.id,
                deletePortfolioLogContent: log.message
            });
        }
    }

    openUpsertPortfolioLogModal(log){
        this.setState({
            upsertPortfolioLogModalOpen: true,
            upsertPortfolioLogId: log ? log.id : '',
            upsertPortfolioLog: log
        })
    }

    toggleUpsertPortfolioLogModal(){
        this.setState({upsertPortfolioLogModalOpen: !this.state.upsertPortfolioLogModalOpen});
    }

    toggleDeletePortfolioLogModal() {
        this.setState({ deletePortfolioLogModalOpen: !this.state.deletePortfolioLogModalOpen })
    }

    componentDidMount() {
        this.loadLogs();
    }

    deletePortfolioLog() {
        const request = {
            portfolioId: this.props.match.params.portfolioId,
            historyId: this.state.deletePortfolioLogId
        };
        new PortfolioService().deleteLog(request).then(
            response => {
                if (response.data) {
                    this.setState({
                        logs: response.data.items,
                        totalPages: response.data.totalPages,
                        hasPreviousPage: response.data.hasPreviousPage,
                        hasNextPage: response.data.hasNextPage,
                        deletePortfolioLogModalOpen: false,
                        deletePortfolioLogContent: '',
                        deletePortfolioLogId: ''
                    });
                }
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    upsertPortfolioLog(request){
        new PortfolioService().upsertPortfolioLog(request).then(
            response => {
                if (response.data) {
                    this.setState({
                        logs: response.data.items,
                        totalPages: response.data.totalPages,
                        hasPreviousPage: response.data.hasPreviousPage,
                        hasNextPage: response.data.hasNextPage,
                        upsertPortfolioLogModalOpen: false,
                        upsertPortfolioLog: null,
                        upsertPortfolioLogId: ''
                    });
                }
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    loadLogs() {
        let { match } = this.props;
        let page = parseInt(match.params.page) ?? 1;
        new PortfolioService().logs(match.params.portfolioId, page).then(
            response => {
                this.setState({
                    logs: response.data.items,
                    totalPages: response.data.totalPages,
                    hasPreviousPage: response.data.hasPreviousPage,
                    hasNextPage: response.data.hasNextPage
                });
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    render() {
        let { match } = this.props;
        let parentUrl = match.url.substring(0, match.url.lastIndexOf("/"));
        let page = parseInt(match.params.page) ?? 1;
        let { logs, hasNextPage, hasPreviousPage, totalPages, 
            deletePortfolioLogContent, deletePortfolioLogModalOpen, 
            upsertPortfolioLogModalOpen, upsertPortfolioLogId, upsertPortfolioLog } = this.state;
        return (
            <Fragment>
                <TransitionGroup>
                    <CSSTransition
                        component="div"
                        className="TabsAnimation"
                        appear={true}
                        timeout={0}
                        enter={false}
                        exit={false}>
                        <Row>
                            <Col lg="12">
                                <Card className="main-card mb-3">
                                    <CardHeader>
                                        <div className="card-header-title">
                                            <i className="header-icon lnr-sync icon-gradient bg-plum-plate"> </i>
                                            Nhật Ký Danh Mục
                                        </div>
                                        <div class="btn-actions-pane-right">
                                            <button class="btn btn-primary mb-2 me-2" onClick={() => this.openUpsertPortfolioLogModal(null)}>Thêm Nhật Ký</button>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <div class="table-responsive">
                                            <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">Ngày</th>
                                                        <th class="text-center">Nội Dung</th>
                                                        <th class="text-center">Ghi Chú</th>
                                                        <th class="text-center">...</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {logs && logs.map(l => (
                                                        <tr>
                                                            <td class="text-center">
                                                                {l.date}
                                                            </td>
                                                            <td dangerouslySetInnerHTML={{ __html: l.message }} class="text-center">
                                                            </td>
                                                            <td class="text-center">
                                                                {l.note ? (
                                                                    <button class="btn btn-secondary mb-2 me-2" data-toggle="tooltip" data-placement="top"
                                                                        title={l.note}>
                                                                        <i class="lnr-file-empty"></i>
                                                                    </button>
                                                                ) : (
                                                                    <>...</>
                                                                )}
                                                            </td>
                                                            <td class="text-center">
                                                                <button class="btn btn-primary mb-2 me-2" onClick={() => this.openUpsertPortfolioLogModal(l)}><i class="lnr-pencil"></i></button>
                                                                <button class="btn btn-primary mb-2 me-2" onClick={() => this.openDeletePortfolioLogModal(l)}><i class="lnr-trash"></i></button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                    <CardFooter className='justify-content-center'>
                                        <AppPagination totalPages={totalPages} parentUrl={parentUrl} page={page} hasNextPage={hasNextPage} hasPreviousPage={hasPreviousPage} />
                                    </CardFooter>
                                </Card>
                                <UpsertModal log={upsertPortfolioLog} logId={upsertPortfolioLogId} portfolioId={match.params.portfolioId}
                                    handleSubmit={this.upsertPortfolioLog} open={upsertPortfolioLogModalOpen} fade={false} toggle={() => this.toggleUpsertPortfolioLogModal()} />
                                <Modal isOpen={deletePortfolioLogModalOpen} fade={false} toggle={() => this.toggleDeletePortfolioLogModal()}>
                                    <ModalHeader toggle={() => this.toggleDeletePortfolioLogModal()}>Bạn muốn xóa system log này không ?</ModalHeader>
                                    <ModalBody>
                                        <div class="text-center" dangerouslySetInnerHTML={{ __html: deletePortfolioLogContent }}></div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-primary mb-2 me-2" onClick={this.deletePortfolioLog}>Có</button>
                                        <button class="btn btn-secondary mb-2 me-2" onClick={() => this.toggleDeletePortfolioLogModal()}>Không</button>
                                    </ModalFooter>
                                </Modal>
                            </Col>
                        </Row>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}

export default withRouter(PortfolioLog);
