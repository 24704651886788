import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';
import { toISOStringWithTimezone } from '../../../../common/utils';

class AddDepositRequestModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        value: 0,
        currency: 0,
        depositDate: toISOStringWithTimezone(new Date()),
        image: ''
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'file' ? target.files[0] : target.value;
        
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    addRequest(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            this.props.handleSubmit(this.state);
            this.setState(this.initialState);
        }
    }

    render() {
        let { open, toggle } = this.props;
        let { value, currency, depositDate } = this.state;
        return (
            <>
                <Modal isOpen={open} fade={false} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Thêm Yêu Cầu Nạp Tiền</ModalHeader>
                    <ModalBody>
                        <div class="row">
                            <div class="col-md-12">
                                <Form onSubmit={(e) => this.addRequest(e)}>
                                    <FormGroup>
                                        <Label for="value">Số Tiền</Label>
                                        <Input onChange={this.handleInputChange} value={value} type="number" id="value" min="1" name="value" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currency">Tiền Tệ</Label>
                                        <Input onChange={this.handleInputChange} value={currency} type="select" required name="currency" id="currency">
                                            <option value="0">VND</option>
                                            <option value="1">JPY</option>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="image">Hình Ảnh</Label>
                                        <Input type="file" onChange={this.handleInputChange} required id="image" name="image"></Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="depositDate">Ngày Nạp</Label>
                                        <Input onChange={this.handleInputChange} value={depositDate} type="datetime-local" required step="1" id="depositDate" name="depositDate" />
                                    </FormGroup>
                                    <Button className='mb-2 me-2' type="submit">Thêm</Button>
                                </Form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

export default AddDepositRequestModal;