import React, { Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import AppPagination from '../../../../Blocks/AppPagination';
import PortfolioService from '../../../../services/portfolio.service';
import PortfolioTransactionService from '../../../../services/portfolio.transaction.service';
import UpdateModal from './UpdateModal';

class PortfolioTransactionHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cashSources: [],
            histories: null,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false,
            updateTransaction: null,
            updateTransactionModalOpen: false
        };

        this.updateTransaction = this.updateTransaction.bind(this);

    }

    openUpdateTransactionModal(transaction) {
        if (transaction) {
            this.setState({
                updateTransaction: transaction,
                updateTransactionModalOpen: true
            });
        }
    }

    toggleUpdateTransactionModal() {
        this.setState({
            updateTransactionModalOpen: !this.state.updateTransactionModalOpen
        })
    }

    componentDidMount() {
        this.loadTranscationHistories();
        this.loadCashSources();
    }
    loadCashSources() {
        new PortfolioService().cashSources(this.props.match.params.portfolioId).then(response => {
            if (response) {
                this.setState({
                    cashSources: response.data
                })
            }
        });
    }

    updateTransaction(request) {
        new PortfolioTransactionService().updateTransaction(request).then(
            response => {
                if (response.data.success) {
                    this.props.updateOverview();
                    this.loadTranscationHistories();
                    this.setState({
                        updateTransaction: null,
                        updateTransactionModalOpen: false
                    });
                } else {
                    this.setState({
                        error: response.data.message,
                        errorModalOpen: true,
                        updateTransactionModalOpen: false
                    });
                }

            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    updateTransactionModalOpen: false
                });
            }
        );
    }

    loadTranscationHistories() {
        let { match } = this.props;
        let page = parseInt(match.params.page) ?? 1;
        new PortfolioService().transactionHistories(match.params.portfolioId, page).then(
            response => {
                this.setState({
                    histories: response.data.items,
                    totalPages: response.data.totalPages,
                    hasPreviousPage: response.data.hasPreviousPage,
                    hasNextPage: response.data.hasNextPage
                });
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    render() {
        let { match } = this.props;
        let parentUrl = match.url.substring(0, match.url.lastIndexOf("/"));
        let page = parseInt(match.params.page) ?? 1;
        let { histories, hasNextPage, hasPreviousPage, totalPages, updateTransaction, updateTransactionModalOpen, cashSources } = this.state;
        return (
            <Fragment>
                <TransitionGroup>
                    <CSSTransition
                        component="div"
                        className="TabsAnimation"
                        appear={true}
                        timeout={0}
                        enter={false}
                        exit={false}>
                        <Row>
                            <Col lg="12">
                                <Card className="main-card mb-3">
                                    <CardHeader>
                                        <div className="card-header-title">
                                            <i className="header-icon lnr-cart icon-gradient bg-plum-plate"> </i>
                                            Nhật Ký Giao Dịch Tài Sản
                                        </div>
                                        <div class="btn-actions-pane-right">

                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <div class="table-responsive">
                                            <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">Ngày</th>
                                                        <th class="text-center">Nguồn</th>
                                                        <th class="text-center">Hành Động</th>
                                                        <th class="text-center">Tài Sản</th>
                                                        <th class="text-center">Số lượng</th>
                                                        <th class="text-center">Giá</th>
                                                        <th class="text-center">Tổng Giá Trị</th>
                                                        <th class="text-center">Phí Giao Dịch</th>
                                                        <th class="text-center">...</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {histories && histories.map(h => (
                                                        <tr>
                                                            <td class="text-center">
                                                                {h.date}
                                                            </td>
                                                            <td class="text-center">
                                                                {h.source}
                                                            </td>
                                                            <td class="text-center">
                                                                {h.action}
                                                            </td>
                                                            <td class="text-center">
                                                                {h.asset}
                                                            </td>
                                                            <td class="text-center">
                                                                {h.quantity}
                                                            </td>
                                                            <td class="text-center">
                                                                {h.price}
                                                            </td>
                                                            <td class="text-center">
                                                                {h.total}
                                                            </td>
                                                            <td class="text-center">
                                                                {h.fee}
                                                            </td>
                                                            <td class="text-center">
                                                                <button class="btn btn-primary mb-2 me-2" onClick={() => this.openUpdateTransactionModal(h)}>Cập Nhật</button>
                                                                {h.note && (
                                                                    <button class="btn btn-secondary mb-2 me-2" data-toggle="tooltip" data-placement="top"
                                                                        title={h.note}>
                                                                        <i class="lnr-file-empty"></i>
                                                                    </button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                    <CardFooter className='justify-content-center'>
                                        <AppPagination totalPages={totalPages} parentUrl={parentUrl} page={page} hasNextPage={hasNextPage} hasPreviousPage={hasPreviousPage} />
                                    </CardFooter>
                                </Card>
                                {updateTransaction && (
                                    <UpdateModal cashSources={cashSources} portfolioId={this.props.match.params.portfolioId} handleSubmit={this.updateTransaction} open={updateTransactionModalOpen} transaction={updateTransaction} historyId={updateTransaction.id} toggle={() => this.toggleUpdateTransactionModal()} />
                                )}
                            </Col>
                        </Row>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}

export default withRouter(PortfolioTransactionHistory);
