import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, CardHeader, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import AppPagination from '../../../../Blocks/AppPagination';
import PortfolioService from '../../../../services/portfolio.service';
import PortfolioTransactionService from '../../../../services/portfolio.transaction.service';
import ApproveDepositRequestModal from './ApproveDepositRequestModal';

class PortfolioDepositRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requests: null,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false,
            imageModal: false,
            currentImageId: '',
            approveDepositRequestModalOpen: false,
            requestUserName: '',
            requestValue: 0,
            requestCurrency: 0,
            requestImage: '',
            requestId: '',
            isLoading: false,
            errorModalOpen: false,
            error: ''
        };
        this.approveDepositRequest = this.approveDepositRequest.bind(this);
    }

    getUserToken() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token) {
            return user.token;
        }

        return '';
    }


    openImageModal(imageId) {
        this.setState({
            currentImageId: imageId,
            imageModal: true
        })
    }

    toggleImageModal() {
        this.setState({
            imageModal: !this.state.imageModal
        })
    }

    toggleApproveDepositRequestModal() {
        this.setState({
            approveDepositRequestModalOpen: !this.state.approveDepositRequestModalOpen
        })
    }

    componentDidMount() {
        this.loadDepositRequests();
    }

    openApproveDepositRequestModal(request) {
        this.setState({
            requestUserName: request.userName,
            requestValue: request.decimalValue,
            requestCurrency: request.currency,
            requestImage: `/Image/Index/${request.imageId}?token=${this.getUserToken()}`,
            requestId: request.id,
            approveDepositRequestModalOpen: true
        });
    }

    approveDepositRequest(request) {
        this.setState({
            isLoading: true
        });
        new PortfolioTransactionService().approveDepositRequest(request).then(
            response => {
                if (response.data) {
                    this.setState({
                        approveDepositRequestModalOpen: false,
                        errorModalOpen: false,
                        error: ''
                    });
                    this.props.updateOverview();
                    this.loadDepositRequests();
                }
                else {
                    this.setState({
                        error: 'Lỗi server. Liên hệ lập trình viên',
                        errorModalOpen: true,
                        approveDepositRequestModalOpen: false,
                        isLoading: false
                    });
                }
            },
            error => {
                this.setState({
                    error: error,
                    errorModalOpen: true,
                    approveDepositRequestModalOpen: false,
                    isLoading: false
                });
            }
        );
    }

    denyDepositRequest(requestId){
        this.setState({
            isLoading: true
        });
        new PortfolioTransactionService().denydepositrequest(requestId).then(
            response => {
                if (response.data) {
                    this.props.updateOverview();
                    this.loadDepositRequests();
                }
                else {
                    this.setState({
                        error: 'Lỗi server. Liên hệ lập trình viên',
                        errorModalOpen: true,
                        isLoading: false
                    });
                }
            },
            error => {
                this.setState({
                    error: error,
                    errorModalOpen: true,
                    isLoading: false
                });
            }
        );
    }

    toggleErrorModal() {
        this.setState({ errorModalOpen: !this.state.errorModalOpen });
    }

    loadDepositRequests() {
        this.setState({
            isLoading: true
        });
        let { match } = this.props;
        let page = parseInt(match.params.page) ?? 1;
        new PortfolioService().depositRequests(match.params.portfolioId, page).then(
            response => {
                this.setState({
                    requests: response.data.items,
                    totalPages: response.data.totalPages,
                    hasPreviousPage: response.data.hasPreviousPage,
                    hasNextPage: response.data.hasNextPage,
                    isLoading: false
                });
            },
            error => {
                this.setState({
                    error: error.message,
                    isLoading: false
                })
            }
        );
    }

    render() {
        let { match } = this.props;
        let parentUrl = match.url.substring(0, match.url.lastIndexOf("/"));
        let page = parseInt(match.params.page) ?? 1;
        let { isLoading, requests, hasNextPage, hasPreviousPage, totalPages, imageModal, currentImageId,
            approveDepositRequestModalOpen, requestValue, requestCurrency, requestImage, requestUserName, requestId } = this.state;
        return (
            <Fragment>
                <TransitionGroup>
                    <CSSTransition
                        component="div"
                        className="TabsAnimation"
                        appear={true}
                        timeout={0}
                        enter={false}
                        exit={false}>
                        <Row>
                            {isLoading ? (
                                <div className="loader-container">
                                    <div className="loader-container-inner">
                                        <h6 className="mt-5">
                                            Hệ thống đang xử lý ...
                                        </h6>
                                    </div>
                                </div>
                            ) : (
                                <Col lg="12">
                                    <Card className="main-card mb-3">
                                        <CardHeader>
                                            <div className="card-header-title">
                                                <i className="header-icon lnr-enter-down icon-gradient bg-plum-plate"> </i>
                                                Yêu Cầu Xác Nhận Nạp Tiền
                                            </div>
                                            <div class="btn-actions-pane-right">

                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <div class="table-responsive">
                                                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">Người Dùng</th>
                                                            <th class="text-center">Tiền Nạp</th>
                                                            <th class="text-center">Ngày Nạp</th>
                                                            <th class="text-center">Hình Ảnh</th>
                                                            <th class="text-center">Tình Trạng</th>
                                                            <th class="text-center">...</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {requests && requests.map(r => (
                                                            <tr>
                                                                <td class="text-center">
                                                                    {r.userName}
                                                                </td>
                                                                <td class="text-center">
                                                                    {r.value}
                                                                </td>
                                                                <td class="text-center">
                                                                    {r.date}
                                                                </td>
                                                                <td class="text-center">
                                                                    <a onClick={() => this.openImageModal(r.imageId)}>
                                                                        <img src={`/Image/Index/${r.imageId}?token=${this.getUserToken()}`} width="250" height="100" />
                                                                    </a>
                                                                </td>
                                                                <td class="text-center">
                                                                    <div className={'badge ' + r.badgeStatus}>
                                                                        {r.status}
                                                                    </div>
                                                                </td>
                                                                <td class="text-center">
                                                                    {r.status === 'Đang chờ duyệt' && (
                                                                        <>
                                                                            <button class="btn btn-primary mb-2 me-2" onClick={() => this.openApproveDepositRequestModal(r)}>Duyệt</button>
                                                                            <button class="btn btn-secondary mb-2 me-2" onClick={() => this.denyDepositRequest(r.id)}>Từ chối</button>
                                                                        </>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                        <CardFooter className='justify-content-center'>
                                            <AppPagination totalPages={totalPages} parentUrl={parentUrl} page={page} hasNextPage={hasNextPage} hasPreviousPage={hasPreviousPage} />
                                        </CardFooter>
                                        <ApproveDepositRequestModal
                                            requestId={requestId}
                                            requestValue={requestValue}
                                            requestCurrency={requestCurrency}
                                            requestImage={requestImage}
                                            requestUserName={requestUserName}
                                            handleSubmit={this.approveDepositRequest}
                                            open={approveDepositRequestModalOpen}
                                            toggle={() => this.toggleApproveDepositRequestModal()} />
                                        <Modal isOpen={imageModal} toggle={() => this.toggleImageModal()}>
                                            <ModalHeader toggle={() => this.toggleImageModal()}>Hình Ảnh</ModalHeader>
                                            <ModalBody>
                                                <img className='img-fluid' src={`/Image/Index/${currentImageId}?token=${this.getUserToken()}`} />
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="link" onClick={() => this.toggleImageModal()}>Tắt</Button>
                                            </ModalFooter>
                                        </Modal>
                                        <Modal isOpen={this.state.errorModalOpen} fade={false} toggle={() => this.toggleErrorModal()}>
                                            <ModalHeader className='text-danger' toggle={() => this.toggleErrorModal()}><i class="lnr-warning">&nbsp;</i>Lỗi</ModalHeader>
                                            <ModalBody>
                                                {this.state.error}
                                            </ModalBody>
                                        </Modal>
                                    </Card>
                                </Col>)}
                        </Row>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}

export default withRouter(PortfolioDepositRequests);

