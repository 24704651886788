import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

class DeleteUserBankAccountModal extends React.Component {
  constructor(props) {
    super(props);
  }

  deleteUserBankAccount(e) {
    e.preventDefault();
    this.props.handleSubmit(this.props.userId, this.props.accountNumber);
  }

  render() {
    let { open, toggle, accountNumber } = this.props;
    return (
      <>
        <Modal isOpen={open} fade={false} toggle={toggle}>
          <ModalHeader toggle={toggle}>Xóa tài khoản ngân hàng</ModalHeader>
          <ModalBody>
            <div class="row">
              <div class="col-md-12">
                <Form onSubmit={(e) => this.deleteUserBankAccount(e)}>
                  <FormGroup>
                    <Label>
                      Bạn có chắc muốn xóa số tài khoản {accountNumber}, hành
                      động này sẽ không thể hoàn tác!
                    </Label>
                  </FormGroup>
                  <Button className="mb-2 me-2 btn btn-danger" type="submit">
                    Đồng ý
                  </Button>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default DeleteUserBankAccountModal;
