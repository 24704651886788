import React, { Fragment } from "react";
import { connect } from "react-redux";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import AuthService from "../../services/auth.service";
import "../../assets/login.css";
import LoginImage from "../../assets/utils/images/logo_transparent72x72.png";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.changeUserPassword = this.changeUserPassword.bind(this);
    this.validateInput = this.validateInput.bind(this);
  }

  initialState = {
    userId: this.props.userId,
    email: "",
    newPassword: "",
    confirmNewPassword: "",
    emailError: "",
    newPasswordError: "",
    confirmNewPasswordError: "",
    isComplete: false,
  };

  validateInput(event) {
    let { newPassword, confirmNewPassword } = this.state;
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    switch (name) {
      case "confirmNewPassword":
        if (!value) {
          this.setState({
            confirmNewPasswordError: "Đây là trường bắt buộc nhập!",
          });
        } else {
          if (value != newPassword) {
            this.setState({
              confirmNewPasswordError: "Mật khẩu không trùng khớp",
            });
          } else {
            this.setState({ confirmNewPasswordError: null });
          }
        }
        break;

      case "newPassword":
        if (!value) {
          this.setState({
            newPasswordError: "Đây là trường bắt buộc nhập!",
          });
        } else {
          if (value != confirmNewPassword) {
            this.setState({
              confirmNewPasswordError: "Mật khẩu không trùng khớp",
            });
          } else {
            this.setState({
              confirmNewPasswordError: null,
              newPasswordError: null,
            });
          }
        }
        break;

      case "email":
        if (!value) {
          this.setState({
            emailError: "Đây là trường bắt buộc nhập!",
          });
        } else {
          this.setState({
            emailError: null,
          });
        }
        break;
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });

    this.validateInput(event);
  }

  changeUserPassword(e) {
    e.preventDefault();
    let { match } = this.props;
    this.setState({
      loading: true,
    });

    new AuthService()
      .resetPassword({
        email: this.state.email,
        password: this.state.newPassword,
        token: match.params.token,
      })
      .then((data) => {
        this.setState({ isComplete: true, loading: false });
      })
      .catch((e) => {
        this.setState({
          loading: false,
          message: e.response.data,
        });
      });
  }

  render() {
    let { emailError, newPasswordError, confirmNewPasswordError, isComplete } =
      this.state;
    let { match } = this.props;
    if (!match.params.token) return <Fragment></Fragment>;
    return (
      <Fragment>
        <div className="login">
          <div className="login-container">
            {!isComplete ? (
              <>
                <Form
                  className="form-signin text-center"
                  onSubmit={this.changeUserPassword}
                  ref={(c) => {
                    this.form = c;
                  }}
                >
                  <img src={LoginImage}></img>
                  <h4>Nhập đúng email để xác nhận thay đổi mật khẩu</h4>
                  <FormGroup>
                    <Label>Email</Label>
                    <Input
                      onChange={this.handleInputChange}
                      type="text"
                      required
                      placeholder="Nhập email"
                      name="email"
                    />
                    {emailError && (
                      <span className="text-danger">{emailError}</span>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Mật khẩu mới</Label>
                    <Input
                      onChange={this.handleInputChange}
                      type="password"
                      required
                      placeholder="Nhập mật khẩu mới"
                      name="newPassword"
                    />
                    {newPasswordError && (
                      <span className="text-danger">{newPasswordError}</span>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Nhập lại mật khẩu mới</Label>
                    <Input
                      onChange={this.handleInputChange}
                      type="password"
                      required
                      placeholder="Nhập lại mật khẩu mới"
                      name="confirmNewPassword"
                    />
                    {confirmNewPasswordError && (
                      <span className="text-danger">
                        {confirmNewPasswordError}
                      </span>
                    )}
                  </FormGroup>
                  <Button
                    className="mb-2 me-2 btn-primary"
                    type="submit"
                    disabled={
                      confirmNewPasswordError || newPasswordError || emailError
                    }
                  >
                    {this.state.loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    Đồng ý
                  </Button>
                </Form>
              </>
            ) : (
              <>
                <div class="text-center">
                  <img src={LoginImage}></img>
                  <h4>Bạn có thể đăng nhập bằng mật khẩu mới</h4>
                  <NavLink to={`/login`}>Trở về trang đăng nhập</NavLink>
                </div>
              </>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(ResetPassword);
