import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';

import { constantsCashSources } from '../../../../utils/constants';

import { toISOStringWithTimezone } from '../../../../common/utils';

class BuyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        cashSource: constantsCashSources.CASH,
        assetType: '0',
        stockName: '',
        assetName: '',
        fee: 0,
        price: 0,
        quantity: 0,
        transactionDate: toISOStringWithTimezone(new Date()),
        note: ''
    };

    // componentDidUpdate() {
    //     if (this.props.assetName !== '') {
    //         if (this.props.assetType === 'Khác') {
    //             if (this.props.assetName != this.state.assetName) {
    //                 this.setState({
    //                     assetName: this.props.assetName,
    //                     assetType: '1'
    //                 });
    //             }
    //         }
    //         else {
    //             if (this.props.assetName != this.state.stockName) {
    //                 this.setState({
    //                     stockName: this.props.assetName,
    //                     assetType: '0'
    //                 });
    //             }
    //         }
    //     }
    // }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    buyAsset(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            let { assetName, stockName, assetType, quantity, price, fee, transactionDate, cashSource, note } = this.state;
            let buyRequest = {
                portfolioId: this.props.portfolioId,
                name: assetType === '1' ? assetName : stockName,
                type: assetType === '1' ? 1 : 0,
                quantity: quantity,
                price: price,
                fee: fee,
                transactionDate: transactionDate,
                cashSource: cashSource,
                note: note
            };
            if (this.props.assetName !== '') {
                buyRequest.name = this.props.assetName;
                buyRequest.type = this.props.assetType === 'Khác' ? 1 : 0
            };
            this.props.handleSubmit(buyRequest);
            this.setState(this.initialState);
        }
    }

    render() {
        let { cashSources, open, toggle, stocks } = this.props;
        let { transactionDate, quantity, fee, price, cashSource, note, assetName, assetType, stockName } = this.state;
        return (
            <>
                <Modal isOpen={open} fade={false} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Mua Tài Sản</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={(e) => this.buyAsset(e)}>
                            <FormGroup>
                                <Label for="name">Loại</Label>
                                {this.props.assetName !== '' ? (
                                    <Input disabled={true} type="select" value={this.props.assetType === 'Khác' ? '1' : '0'} id="assetType" name="assetType">
                                        <option value="0">Cổ phiếu</option>
                                        <option value="1">Khác</option>
                                    </Input>
                                ) : (
                                    <Input onChange={this.handleInputChange} type="select" value={assetType} id="assetType" name="assetType">
                                        <option value="0">Cổ phiếu</option>
                                        <option value="1">Khác</option>
                                    </Input>
                                )}

                            </FormGroup>
                            <FormGroup>
                                <Label>Tên</Label>
                                {this.props.assetName !== '' ? (
                                    <>
                                        <Input onChange={this.handleInputChange} disabled={true} type="text" required={this.props.assetType === 'Khác'} hidden={this.props.assetType !== 'Khác'} value={this.props.assetName} id="assetName" name="assetName" />
                                        <Input onChange={this.handleInputChange} disabled={true} type="select" required={this.props.assetType !== 'Khác'} hidden={this.props.assetType === 'Khác'} value={this.props.assetName} id="stockName" name="stockName">
                                            {stocks && stocks.map((st) => (
                                                <option value={st}>{st}</option>
                                            ))}
                                        </Input>
                                    </>
                                ) : (
                                    <>
                                        <Input onChange={this.handleInputChange} type="text" required={assetType === '1'} hidden={assetType === '0'} value={assetName} id="assetName" name="assetName" />
                                        <Input onChange={this.handleInputChange} type="select" required={assetType === '0'} hidden={assetType === '1'} value={stockName} id="stockName" name="stockName">
                                            {stocks && stocks.map((st) => (
                                                <option value={st}>{st}</option>
                                            ))}
                                        </Input>
                                    </>
                                )}



                            </FormGroup>
                            <FormGroup>
                                <Label for="quantity">Số Lượng</Label>
                                <Input onChange={this.handleInputChange} value={quantity} type="number" min="1" max="" required id="quantity" name="quantity" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="price">Giá Bán</Label>
                                <InputGroup>
                                    <Input onChange={this.handleInputChange} value={price} type="number" id="price" min="0" name="price" />
                                    <InputGroupText>đ</InputGroupText>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="fee">Phí Giao Dịch</Label>
                                <InputGroup>
                                    <Input onChange={this.handleInputChange} value={fee} type="number" id="fee" min="0" name="fee" />
                                    <InputGroupText>đ</InputGroupText>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="cashSource">Nguồn</Label>
                                <Input onChange={this.handleInputChange} value={cashSource} type="select" required name="cashSource" id="cashSource">
                                    {cashSources && cashSources.map((cs) => (
                                        <option value={cs.name}>{cs.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="transactionDate">Ngày Giao Dịch</Label>
                                <Input onChange={this.handleInputChange} value={transactionDate} type="datetime-local" required step="1" id="transactionDate" name="transactionDate" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="note">Ghi Chú</Label>
                                <Input onChange={this.handleInputChange} value={note} type="textarea" name="note" id="note" />
                            </FormGroup>
                            <Button type="submit">Mua</Button>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

export default BuyModal;