import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';

import {
    sortableContainer,
    sortableElement
} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';

const SortableItem = sortableElement(({ value }) => (
    <div class="mb-1 card card-body text-white bg-primary" style={{zIndex: 1060, cursor: 'pointer'}}>
        <i class="lnr-move"> {value}</i> 
    </div>
));

const SortableContainer = sortableContainer(({ children }) => {
    return <div class="col-md-12">{children}</div>;
});

class SortModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.items
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.items != this.props.items) {
            this.setState({
                items: this.props.items
            });
        }
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ items }) => ({
            items: arrayMoveImmutable(items, oldIndex, newIndex),
        }));
    };

    updateSortedItems(items){
        this.props.updateSortedItems(items);
    }

    render() {
        let { open, toggle, title } = this.props;
        let { items } = this.state;
        return (
            <Modal isOpen={open} fade={false} toggle={toggle} scrollable>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    <div class="row">
                        {items && items.length > 0 && (
                            <SortableContainer onSortEnd={this.onSortEnd}>
                                {items.map((a, index) => (
                                    <SortableItem key={`item-${a}`} index={index} value={a} />
                                ))}
                            </SortableContainer>
                        )}
                    </div>
                </ModalBody >
                <ModalFooter>
                    <button class="btn btn-primary mb-2 me-2" onClick={() => this.updateSortedItems(items)}>Lưu</button>
                </ModalFooter>
            </Modal >
        );
    }
}

export default SortModal;