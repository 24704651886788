import axios from 'axios';
import authHeader from './auth-header';
const API_URL = "/api/customerportfolio/";
export default class CustomerPortfolioService {
    list(){
        return axios.get(API_URL, {headers: authHeader()});
    }

    detail(id){
        return axios.get(API_URL + id, {headers: authHeader()});
    }

    overview(id){
        return axios.get(API_URL + 'overview/' + id, {headers: authHeader()});
    }

    navCCQChart(id, period){
        return axios.get(API_URL + 'navccqchart/' + id + '?period=' + period, {headers: authHeader()});
    }

    growthRateChart(id, period){
        return axios.get(API_URL + 'growthRateChart/' + id + '?period=' + period, {headers: authHeader()});
    }

    assetHistoriesChart(id, period){
        return axios.get(API_URL + 'assetHistoriesChart/' + id + '?period=' + period, {headers: authHeader()});
    }

    allocationChart(id, historyId){
        return axios.get(API_URL + 'allocationChart/' + id + '/' + historyId, {headers: authHeader()})
    }

    navccqHistories(id,page){
        return axios.get(API_URL + 'navCCQHistory/' + id + "/" +page, {headers: authHeader()})
    }

    logs(id,page){
        return axios.get(API_URL + 'logs/' + id + "/" +page, {headers: authHeader()})
    }

    depositRequests(id, page){
        return axios.get(API_URL + 'depositRequest/' + id + "/" +page, {headers: authHeader()})
    }

    withdrawRequests(id,page){
        return axios.get(API_URL + 'withdrawRequest/' + id + "/" +page, {headers: authHeader()})
    }

    addWithdrawRequest(request){
        return axios.post(API_URL + 'addWithdrawRequest', request, {
            headers: authHeader()
        });
    }

    addDepositRequest(request){
        var header = authHeader();
        header = {...header, "Content-Type": "multipart/form-data"};
        return axios.post(API_URL + 'addDepositRequest', request, {
            headers: header
        });
    }
}