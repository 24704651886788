import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { Form, Input } from "reactstrap";
import AuthService from "../../services/auth.service";
import "../../assets/login.css";
import LoginImage from "../../assets/utils/images/logo_transparent72x72.png";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.state = {
      email: "",
      loading: false,
      isComplete: false,
      message: "",
    };
  }
  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  handleForgotPassword(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    new AuthService()
      .forgotPassword({ email: this.state.email })
      .then((data) => {
        this.setState({ isComplete: true, loading: false });
      })
      .catch((e) => {
        this.setState({
          loading: false,
          message: e.response.data,
        });
      });
  }
  render() {
    const { message, isComplete } = this.state;
    return (
      <Fragment>
        <div className="login">
          <div className="login-container">
            {!isComplete ? (
              <>
                <Form
                  className="form-signin text-center"
                  onSubmit={this.handleForgotPassword}
                  ref={(c) => {
                    this.form = c;
                  }}
                >
                  <img src={LoginImage}></img>
                  <h4>Nhập đúng email để xác nhận thay đổi mật khẩu</h4>
                  <div className="form-group mb-3">
                    <Input
                      type="text"
                      className="form-control"
                      name="email"
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                      required
                    />
                  </div>
                  {message && (
                    <div className="form-group mb-3">
                      <div className="alert alert-danger" role="alert">
                        {message}
                      </div>
                    </div>
                  )}

                  <button
                    className="btn btn-lg btn-primary btn-block"
                    disabled={this.state.loading}
                  >
                    {this.state.loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Gửi yêu cầu</span>
                  </button>
                </Form>
              </>
            ) : (
              <>
                <div class="text-center">
                  <img src={LoginImage}></img>
                  <h4>Vui lòng kiểm tra email để thay đổi lại mật khẩu</h4>
                </div>
              </>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(ForgotPassword);
