import React, { Fragment } from 'react';
import { Route, withRouter, NavLink, Redirect, Switch } from 'react-router-dom';
import { Nav, NavItem, Table, Row, Col, Card, CardBody, CardHeader, Button } from 'reactstrap';
import CustomerPortfolioService from '../../../services/customer.portfolio.service';
import CustomerPortfolioOverview from './Overview';
import CustomerPortfolioLog from './PortfolioLog';
import CustomerPortfolioNAVCCQHistory from './NAVCCQHistory';
import CustomerPortfolioDepositRequests from './DepositRequests';
import CustomerPortfolioWithdrawRequests from './WithdrawRequests';
import PrivateRoute from '../../../common/private-route';
import CensoredSwitch from '../../../common/censored-switch';

class CustomerPortfolioDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            portfolioDetail: null,
            error: "",
            censored: true
        };
        this.updateOverview = this.updateOverview.bind(this);
    }

    toggleCensored(){
        this.setState({
            censored: !this.state.censored
        });
    }

    componentDidMount() {
        this.updateOverview();
    }

    updateOverview() {
        let { match } = this.props;
        new CustomerPortfolioService().detail(match.params.portfolioId).then(
            response => {
                this.setState({
                    portfolioDetail: response.data
                });
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    render() {
        let { match } = this.props;
        let { portfolioDetail, censored } = this.state;
        let subheading = (<></>);
        let heading = 'Portfolio Name';
        let pendingDepositRequests = 0;
        let pendingWithdrawRequests = 0;
        if (portfolioDetail) {
            subheading = (
                <>
                    NAVCCQ: {portfolioDetail.summary.navccq} <br />
                    Số lượng CCQ: {portfolioDetail.summary.numberOfCertificates} <br />
                    Tiền chưa phân phối: {portfolioDetail.summary.cash}
                </>
            );
            heading = portfolioDetail.summary.name;
            pendingDepositRequests = portfolioDetail.pendingDepositRequests;
            pendingWithdrawRequests = portfolioDetail.pendingWithdrawRequests;
        }
        return (
            <Fragment>
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div
                                className="page-title-icon">
                                <i className="pe-7s-portfolio icon-gradient bg-amy-crisp" />
                            </div>
                            <div>
                                {heading} <CensoredSwitch censored={censored} toggle={() => this.toggleCensored()} />
                                <div
                                    className="page-title-subheading">
                                    {subheading}
                                </div>
                            </div>
                        </div>
                        <div className="page-title-actions">

                        </div>
                    </div>
                </div>
                <Nav pills>
                    <NavItem>
                        <NavLink className="nav-link" to={`${match.url}/overview`}>
                            Tổng Quan
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="nav-link" to={`${match.url}/depositrequest`}>
                            Yêu Cầu Xác Nhập Nạp Tiền {(pendingDepositRequests > 0) && (
                                <span class="badge bg-warning">{pendingDepositRequests}</span>
                            )}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="nav-link" to={`${match.url}/withdrawrequest`}>
                            Yêu Cầu Rút Tiền {(pendingWithdrawRequests > 0) && (
                                <span class="badge bg-warning">{pendingWithdrawRequests}</span>
                            )}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="nav-link" to={`${match.url}/log`}>
                            Nhật Ký
                        </NavLink>
                    </NavItem>
                </Nav>
                <PrivateRoute path={`${match.path}/overview`} render={(props) => (
                    <CustomerPortfolioOverview censored={censored}/>
                )}/>

                <PrivateRoute path={`${match.path}/log`}>
                    <Redirect to={`${match.url}/log/1`} />
                </PrivateRoute>
                <PrivateRoute path={`${match.path}/log/:page`} render={(props) => (
                    <CustomerPortfolioLog key={props.match.params.page} {...props} />)
                } />
                {portfolioDetail && (
                    <>
                        <PrivateRoute path={`${match.path}/depositrequest`}>
                            <Redirect to={`${match.url}/depositrequest/1`} />
                        </PrivateRoute>
                        <PrivateRoute path={`${match.path}/depositrequest/:page`} render={(props) => (
                            <CustomerPortfolioDepositRequests updateOverview={this.updateOverview} key={props.match.params.page} {...props} />)
                        } />
                        <PrivateRoute path={`${match.path}/withdrawrequest`}>
                            <Redirect to={`${match.url}/withdrawrequest/1`} />
                        </PrivateRoute>
                        <PrivateRoute path={`${match.path}/withdrawrequest/:page`} render={(props) => (
                            <CustomerPortfolioWithdrawRequests maxValue={portfolioDetail.summary.totalValueDecimal} fee={portfolioDetail.summary.requestFeeRate} updateOverview={this.updateOverview} key={props.match.params.page} {...props} />)
                        } />
                    </>
                )}

                <PrivateRoute path="/">
                    <Redirect to={`${match.url}/overview`} />
                </PrivateRoute>
            </Fragment>
        );
    }
}

export default withRouter(CustomerPortfolioDetail);