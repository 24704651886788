import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';
import { toISOStringWithTimezone } from '../../../../common/utils';

function removeCurrencyStr(value) {
    return value.replace(" đ", "").replace(".", "");
}

class UpdateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        quantity: 0,
        price: 0,
        fee: 0,
        cashSource: '',
        transactionDate: ''
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    componentDidMount() {
        if (this.props.transaction) {
            let { transaction } = this.props;
            this.setState({
                quantity: transaction.quantity,
                price: parseInt(removeCurrencyStr(transaction.price)),
                fee: parseInt(removeCurrencyStr(transaction.fee)),
                cashSource: transaction.source,
                transactionDate: toISOStringWithTimezone(new Date(transaction.isoFormatDate))
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.historyId !== this.props.historyId) {
            let { transaction } = this.props;
            this.setState({
                quantity: transaction.quantity,
                price: parseInt(removeCurrencyStr(transaction.price)),
                fee: parseInt(removeCurrencyStr(transaction.fee)),
                cashSource: transaction.source,
                transactionDate: toISOStringWithTimezone(new Date(transaction.isoFormatDate))
            });
        }
    }

    updateTransaction(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            const request = {
                id: this.props.historyId,
                portfolioId: this.props.portfolioId,
                quantity: this.state.quantity,
                price: this.state.price,
                fee: this.state.fee,
                source: this.state.cashSource,
                transactionDate: this.state.transactionDate
            };
            this.props.handleSubmit(request);
            this.setState(this.initialState);
        }
    }

    render() {
        const { transaction, open, toggle, cashSources } = this.props;
        let { quantity, price, fee, cashSource, transactionDate } = this.state;
        return (
            <Modal isOpen={open} fade={false} toggle={toggle}>
                <Form onSubmit={(e) => this.updateTransaction(e)}>
                    <ModalHeader toggle={toggle}>Cập Nhật Giao Dịch</ModalHeader>
                    <ModalBody>
                        <div class="row">
                            <div class="col-md-12">
                                <FormGroup>
                                    <Label for="action">Hành Động</Label>
                                    <Input value={transaction.action} type="text" readOnly={true} name="action" id="action" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="note">Tên Tài Sản</Label>
                                    <Input value={transaction.asset} type="text" readOnly={true} name="asset" id="asset" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="quantity">Số Lượng</Label>
                                    <Input onChange={this.handleInputChange} value={quantity} type="number" min="1" max="" required id="quantity" name="quantity" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="price">Giá Bán</Label>
                                    <InputGroup>
                                        <Input onChange={this.handleInputChange} value={price} type="number" id="price" min="0" name="price" />
                                        <InputGroupText>đ</InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="fee">Phí Giao Dịch</Label>
                                    <InputGroup>
                                        <Input onChange={this.handleInputChange} value={fee} type="number" id="fee" min="0" name="fee" />
                                        <InputGroupText>đ</InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="cashSource">Nguồn</Label>
                                    <Input onChange={this.handleInputChange} value={cashSource} type="select" required name="cashSource" id="cashSource">
                                        {cashSources && cashSources.map((cs) => (
                                            <option value={cs}>{cs}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="transactionDate">Ngày Giao Dịch</Label>
                                    <Input onChange={this.handleInputChange} value={transactionDate} type="datetime-local" required step="1" id="transactionDate" name="transactionDate" />
                                </FormGroup>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='mb-2 me-2' color='primary' type="submit">Cập Nhật</Button>
                        <Button className='mb-2 me-2' color='secondary' type="button" onClick={toggle}>Hủy</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

export default UpdateModal;