import React, { Fragment } from 'react';
import { Route, withRouter, NavLink, Redirect, Switch } from 'react-router-dom';
import { TabContent, Nav, NavItem, Button } from 'reactstrap';
import Tabs from 'react-responsive-tabs';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import PortfolioOverview from './Overview';
import PortfolioAssets from './Assets';
import PortfolioCCQ from './CCQDetails';
import PortfolioNAVCCQHistory from './NAVCCQHistory';
import PortfolioService from '../../../services/portfolio.service';
import NAVCCQHistory from './NAVCCQHistory';
import AssetHistory from './AssetHistory';
import DepositRequests from './DepositRequests';
import WithdrawRequests from './WithdrawRequests';
import TransactionHistory from './TransactionHistory';
import PortfolioLog from './PortfolioLog';
import UpdatePorfolioRateTool from './UpdatePorfolioRateTool';
import CalculateFinalTool from './CalculateFinalTool';
import PrivateRoute from '../../../common/private-route';
import { authRoles } from '../../../utils/constants';
import CensoredSwitch from '../../../common/censored-switch';
import CustomAssetHistory from './CCQDetails/CustomerAssetHistory';

class PortfolioDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            portfolioDetail: null,
            error: "",
            censored: true
        };
        this.updateOverview = this.updateOverview.bind(this);
    }

    toggleCensored(){
        this.setState({
            censored: !this.state.censored
        });
    }

    updateOverview() {
        let { match } = this.props;
        new PortfolioService().detail(match.params.portfolioId).then(
            response => {
                this.setState({
                    portfolioDetail: response.data
                });
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    componentDidMount() {
        this.updateOverview();
    }

    render() {
        let { match } = this.props;
        let { portfolioDetail, censored } = this.state;
        let subheading = (<></>);
        let heading = 'Portfolio Name';
        let pendingDepositRequests = 0;
        let pendingWithdrawRequests = 0;
        if (portfolioDetail) {
            subheading = (
                <>
                    NAVCCQ: {portfolioDetail.summary.navccq} <br />
                    NAVCCQ đầu kỳ: {portfolioDetail.navccqInfo?.navccqDauKy} <br />
                    NAVCCQ sau phí: {portfolioDetail.navccqInfo?.navccqAfterFee} <br />
                    Số lượng CCQ: {portfolioDetail.summary.numberOfCertificates} <br />
                    Hạn Mức: {portfolioDetail.hanMuc} <br />
                    Hiệu Quả: {portfolioDetail.hieuQua} <br />
                    Phí Rút Tiền: {portfolioDetail.phiRutTien}
                </>
            );
            heading = portfolioDetail.summary.name;
            pendingDepositRequests = portfolioDetail.pendingDepositRequests;
            pendingWithdrawRequests = portfolioDetail.pendingWithdrawRequests;
        }
        return (
            <Fragment>
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div
                                className="page-title-icon">
                                <i className="pe-7s-portfolio icon-gradient bg-amy-crisp" />
                            </div>
                            <div>
                                {heading} <CensoredSwitch censored={censored} toggle={() => this.toggleCensored()} />
                                <div
                                    className="page-title-subheading">
                                    {subheading}
                                </div>
                            </div>
                        </div>
                        <div className="page-title-actions">
                            <NavLink className="btn btn-primary mb-2 me-2" to={`${match.url}/calculatefinal`}>
                                Tính Toán Cuối Kỳ
                            </NavLink>
                            <NavLink className="btn btn-secondary mb-2 me-2" to={`${match.url}/updateportfoliorate`}>
                                Cập Nhật Hạn Mức Hiệu Quả Và Phí
                            </NavLink>
                        </div>
                    </div>
                </div>
                <Nav pills>
                    <NavItem>
                        <NavLink className="nav-link" to={`${match.url}/overview`}>
                            Tổng Quan
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="nav-link" to={`${match.url}/assets`}>
                            Tài Sản
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="nav-link" to={`${match.url}/ccqdetails`}>
                            Quản lý CCQ
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="nav-link" to={`${match.url}/navccqhistory`}>
                            Lịch sử NAVCCQ
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="nav-link" to={`${match.url}/assethistory`}>
                            Lịch sử Tài Sản
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="nav-link" to={`${match.url}/depositrequest`}>
                            Yêu Cầu Nạp Tiền {(pendingDepositRequests > 0) && (
                                <span class="badge bg-warning">{pendingDepositRequests}</span>
                            )}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="nav-link" to={`${match.url}/withdrawrequest`}>
                            Yêu Cầu Rút Tiền {(pendingWithdrawRequests > 0) && (
                                <span class="badge bg-warning">{pendingWithdrawRequests}</span>
                            )}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="nav-link" to={`${match.url}/transactionhistory`}>
                            Nhật Ký Giao Dịch Tài Sản
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="nav-link" to={`${match.url}/portfoliolog`}>
                            Nhật Ký Danh Mục
                        </NavLink>
                    </NavItem>
                </Nav>
                {this.state.error &&
                    <div>{this.state.error}</div>
                }
                <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/overview`} component={PortfolioOverview} />
                <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/assets`} render={(props) => (
                    <PortfolioAssets updateOverview={this.updateOverview} censored={censored} />)} />
                <PrivateRoute roles={[authRoles.ADMIN]} exact path={`${match.path}/ccqdetails`} render={(props) => (
                    <PortfolioCCQ updateOverview={this.updateOverview} censored={censored} />
                )} />
                <PrivateRoute roles={[authRoles.ADMIN]} exact path={`${match.path}/ccqdetails/:userId/assethistories`} render={(props) => (
                    <Redirect to={`${match.url}/ccqdetails/${props.match.params.userId}/assethistories/1`} />
                )} />

                <PrivateRoute roles={[authRoles.ADMIN]} exact path={`${match.path}/ccqdetails/:userId/assethistories/:page`} render={(props) => (
                    <CustomAssetHistory censored={censored} key={props.match.params.page} {...props} />
                )} />
                <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/navccqhistory`}>
                    <Redirect to={`${match.url}/navccqhistory/1`} />
                </PrivateRoute>
                <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/navccqhistory/:page`} render={(props) => (
                    <NAVCCQHistory updateOverview={this.updateOverview} key={props.match.params.page} censored={censored} {...props} />)
                } />
                <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/assethistory`}>
                    <Redirect to={`${match.url}/assethistory/1`} />
                </PrivateRoute>
                <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/assethistory/:page`} render={(props) => (
                    <AssetHistory updateOverview={this.updateOverview} key={props.match.params.page} censored={censored} {...props} />)
                } />
                <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/depositrequest`}>
                    <Redirect to={`${match.url}/depositrequest/1`} />
                </PrivateRoute>
                <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/depositrequest/:page`} render={(props) => (
                    <DepositRequests updateOverview={this.updateOverview} key={props.match.params.page} censored={censored} {...props} />)
                } />
                <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/withdrawrequest`}>
                    <Redirect to={`${match.url}/withdrawrequest/1`} />
                </PrivateRoute>
                <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/withdrawrequest/:page`} render={(props) => (
                    <WithdrawRequests updateOverview={this.updateOverview} key={props.match.params.page} censored={censored} {...props} />)
                } />
                <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/transactionhistory`}>
                    <Redirect to={`${match.url}/transactionhistory/1`} />
                </PrivateRoute>
                <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/transactionhistory/:page`} render={(props) => (
                    <TransactionHistory updateOverview={this.updateOverview} key={props.match.params.page} censored={censored} {...props} />)
                } />
                <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/portfoliolog`}>
                    <Redirect to={`${match.url}/portfoliolog/1`} />
                </PrivateRoute>
                <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/portfoliolog/:page`} render={(props) => (
                    <PortfolioLog updateOverview={this.updateOverview} key={props.match.params.page} censored={censored} {...props} />)
                } />

                {portfolioDetail && (
                    <>
                        <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/updateportfoliorate`} render={(props) => (
                            <UpdatePorfolioRateTool updateOverview={this.updateOverview} {...props} hanMuc={portfolioDetail.hanMuc} hieuQua={portfolioDetail.hieuQua} phiRutTien={portfolioDetail.phiRutTien} />
                        )} />

                        <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/calculatefinal`} render={(props) => (
                            <CalculateFinalTool updateOverview={this.updateOverview} {...props} />
                        )} />
                    </>
                )}

                <PrivateRoute roles={[authRoles.ADMIN]} path="/">
                    <Redirect to={`${match.url}/overview`} />
                </PrivateRoute>

            </Fragment>
        );
    }
}

export default withRouter(PortfolioDetail);