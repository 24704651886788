import React, { Fragment, useEffect, useState } from "react";
import { ToastContainer, toast, Bounce } from "react-toastify";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import ScheduledJobService from "../../../../services/scheduledjob.service";

const ScheduledJobNotification = ({ scheduledJobName }) => {
  const [notification, setnotification] = useState("");
  const [isRunning, setisRunning] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      new ScheduledJobService().notification(scheduledJobName).then(
        (response) => {
          setnotification(response.data.notification);
          setisRunning(response.data.isRunning);
        },
        (error) => {}
      );
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return isRunning ? (
    <div className="badge bg-warning">{notification}</div>
  ) : (
    ""
  );
};

class ScheduledJobForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduledJob: null,
      errorModalOpen: false,
      error: "",
      intervalType: ["Minute", "Hour", "Day", "Week", "Month"],
      notification: "",
      isRunning: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleErrorModal = this.toggleErrorModal.bind(this);
    this.buildScheduledJobUpdatePayload =
      this.buildScheduledJobUpdatePayload.bind(this);

    this.update = this.update.bind(this);
    this.stop = this.stop.bind(this);
    this.startManually = this.startManually.bind(this);
  }

  notifySucess = (text) =>
    (this.toastId = toast(text, {
      transition: Bounce,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-center",
      type: "success",
    }));

  componentDidMount() {
    let { scheduledJobName } = this.props;
    new ScheduledJobService().detail(scheduledJobName).then(
      (response) => {
        this.setState({
          scheduledJob: response.data,
          nextExec: response.data.nextExec,
          scheduledJobInterval: response.data.scheduledJobInterval,
          scheduledJobIntervalType: response.data.scheduledJobIntervalType,
          isScheduledActive: response.data.isScheduledActive,
        });
        if (response.data.parameters != null) {
          response.data.parameters.forEach((element) => {
            this.setState({ [element.name]: element.value });
          });
        }
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
        });
      }
    );
  }

  toggleErrorModal() {
    this.setState({ errorModalOpen: !this.state.errorModalOpen });
  }

  buildScheduledJobUpdatePayload() {
    var payload = {};
    payload.nextExec = this.convertToUTC(this.state.nextExec);
    payload.scheduledJobInterval = this.state.scheduledJobInterval;
    payload.scheduledJobIntervalType = this.state.scheduledJobIntervalType;
    payload.isScheduledActive = this.state.isScheduledActive;
    if (this.state.scheduledJob.parameters) {
      payload.parameters = this.state.scheduledJob.parameters.map(
        (element) => ({
          name: element.name,
          value: this.state[element.name].toString(),
        })
      );
    }
    return payload;
  }

  update() {
    let { scheduledJobName } = this.props;
    var payload = this.buildScheduledJobUpdatePayload();
    new ScheduledJobService().update(scheduledJobName, payload).then(
      (response) => {
        this.notifySucess("Update job successfully!");
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
        });
      }
    );
  }

  startManually() {
    let { scheduledJobName } = this.props;
    new ScheduledJobService().startManually(scheduledJobName).then(
      (response) => {
        this.notifySucess("Start job manually successfully!");
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
        });
      }
    );
  }

  stop() {
    let { scheduledJobName } = this.props;
    new ScheduledJobService().stop(scheduledJobName).then(
      (response) => {
        this.notifySucess("Stop job successfully!");
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
        });
      }
    );
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  convertToLocalTime = (utcDateTimeString) => {
    const date = new Date(utcDateTimeString);
    return this.formatDate(date);
  };

  convertToUTC(dateTime) {
    const date = new Date(dateTime);
    return date.toISOString();
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  formatDate(date) {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join("-") +
      " " +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  }

  render() {
    let { scheduledJob, intervalType, notification } = this.state;
    if (scheduledJob == null) return <Fragment></Fragment>;
    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            component="div"
            className="TabsAnimation"
            appear={true}
            timeout={0}
            enter={false}
            exit={false}
          >
            <Row>
              <Col className="col-6">
                <Card>
                  <CardHeader>
                    <div className="card-header-title">Parameters</div>
                    {notification ? (
                      <div className="bg-sunny-morning">{notification}</div>
                    ) : (
                      ""
                    )}
                    <ScheduledJobNotification
                      scheduledJobName={this.props.scheduledJobName}
                    />
                  </CardHeader>
                  <CardBody>
                    {scheduledJob.parameters &&
                      scheduledJob.parameters.map((entry, i) => {
                        return (
                          <FormGroup>
                            <Label>{entry.displayName}</Label>
                            <Input
                              type="text"
                              required
                              value={this.state[entry.name]}
                              name={entry.name}
                              onChange={this.handleInputChange}
                            />
                          </FormGroup>
                        );
                      })}
                    {scheduledJob.parameters && (
                      <>
                        <hr style={{ marginTop: 2 + "rem" }} />
                      </>
                    )}
                    <FormGroup>
                      <Label>Next excecute in:</Label>
                      <Input
                        onChange={this.handleInputChange}
                        type="datetime-local"
                        required
                        value={this.convertToLocalTime(this.state.nextExec)}
                        name="nextExec"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Scheduled job interval</Label>
                      <Row>
                        <Col className="col-8">
                          <Input
                            type="text"
                            required
                            value={this.state.scheduledJobInterval}
                            name="scheduledJobInterval"
                            onChange={this.handleInputChange}
                          />
                        </Col>
                        <Col className="col-4">
                          <select
                            name="scheduledJobIntervalType"
                            class="form-control"
                            onChange={this.handleInputChange}
                          >
                            {intervalType.map((i) =>
                              i == this.state.scheduledJobIntervalType ? (
                                <option value={i} selected>
                                  {i}
                                </option>
                              ) : (
                                <option value={i}>{i}</option>
                              )
                            )}
                          </select>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="checkbox"
                        name="isScheduledActive"
                        onChange={this.handleInputChange}
                        checked={this.state.isScheduledActive}
                      />
                      <Label>&nbsp;Active</Label>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    <button
                      class="btn btn-warning mb-2 me-2"
                      onClick={() => this.update()}
                    >
                      Save
                    </button>
                    <button
                      class="btn btn-primary mb-2 me-2"
                      onClick={() => this.startManually()}
                    >
                      Start Manually
                    </button>
                    <button
                      class="btn btn-danger mb-2 me-2"
                      onClick={() => this.stop()}
                    >
                      Stop
                    </button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </CSSTransition>
        </TransitionGroup>
        <Modal
          isOpen={this.state.errorModalOpen}
          fade={false}
          toggle={() => this.toggleErrorModal()}
        >
          <ModalHeader
            className="text-danger"
            toggle={() => this.toggleErrorModal()}
          >
            <i class="lnr-warning">&nbsp;</i>Lỗi
          </ModalHeader>
          <ModalBody>{this.state.error}</ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default ScheduledJobForm;
