import React, { Fragment } from "react";
import { ToastContainer, toast, Bounce } from "react-toastify";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import CustomPagination from "../../../../Blocks/CustomPagination";
import ScheduledJobService from "../../../../services/scheduledjob.service";

class ScheduledJobHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      histories: null,
      errorModalOpen: false,
      error: "",
      totalPages: 0,
      hasPreviousPage: false,
      hasNextPage: false,
      pageSize: 10,
    };

    this.toggleErrorModal = this.toggleErrorModal.bind(this);
    this.changePage = this.changePage.bind(this);
    this.loadHistories = this.loadHistories.bind(this);
  }

  notifySucess = (text) =>
    (this.toastId = toast(text, {
      transition: Bounce,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-center",
      type: "success",
    }));

  componentDidMount() {
    let { pageSize } = this.state;
    this.loadHistories(1, pageSize);
  }

  loadHistories(page, pageSize) {
    let { scheduledJobName } = this.props;
    new ScheduledJobService().history(scheduledJobName, page, pageSize).then(
      (response) => {
        this.setState({
          page: page,
          histories: response.data.items,
          totalPages: response.data.totalPages,
          hasPreviousPage: response.data.hasPreviousPage,
          hasNextPage: response.data.hasNextPage,
        });
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
        });
      }
    );
  }

  clearAllHistory() {
    let { scheduledJobName } = this.props;
    new ScheduledJobService().clearAllHistory(scheduledJobName).then(
      (response) => {
        this.setState({
          page: 1,
          histories: [],
          totalPages: 0,
          hasPreviousPage: false,
          hasNextPage: false,
        });
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
        });
      }
    );
  }

  toggleErrorModal() {
    this.setState({ errorModalOpen: !this.state.errorModalOpen });
  }

  convertToLocalTime = (utcDateTimeString) => {
    const date = new Date(utcDateTimeString);
    return this.formatDate(date);
  };

  padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  formatDate(date) {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join("-") +
      " " +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  }

  differentInMinute(dateString1, dateString2) {
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);
    return (date2 - date1) / (1000 * 60);
  }

  changePage(pageNumber) {
    let { pageSize } = this.state;
    this.loadHistories(pageNumber, pageSize);
  }

  render() {
    let { histories, totalPages, page, hasNextPage, hasPreviousPage } =
      this.state;
    if (histories == null) return <Fragment></Fragment>;
    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            component="div"
            className="TabsAnimation"
            appear={true}
            timeout={0}
            enter={false}
            exit={false}
          >
            <Row>
              <Col className="col-6">
                <Card>
                  <CardHeader>
                    <div className="card-header-title">History</div>
                    <div className="btn-actions-pane-right">
                      <button
                        class="btn btn-danger mb-2 me-2"
                        onClick={() => this.clearAllHistory()}
                      >
                        <i className="lnr-file-add"> </i>
                        Clear all
                      </button>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div class="table-responsive">
                      <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                        <thead>
                          <tr>
                            <th class="text-center">#</th>
                            <th>Date & Time</th>
                            <th>Duration</th>
                            <th>Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {histories.map((history, i) => {
                            return (
                              <tr>
                                <td class="text-center" scope="row">
                                  {i + 1}
                                </td>
                                <td>
                                  {this.convertToLocalTime(
                                    history.startJobTime
                                  )}
                                </td>
                                <td>
                                  {this.differentInMinute(
                                    history.startJobTime,
                                    history.endJobTime
                                  )}
                                </td>
                                <td>{history.message}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <CustomPagination
                      totalPages={totalPages}
                      page={page}
                      hasNextPage={hasNextPage}
                      hasPreviousPage={hasPreviousPage}
                      changePage={this.changePage}
                    />
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </CSSTransition>
        </TransitionGroup>

        <Modal
          isOpen={this.state.errorModalOpen}
          fade={false}
          toggle={() => this.toggleErrorModal()}
        >
          <ModalHeader
            className="text-danger"
            toggle={() => this.toggleErrorModal()}
          >
            <i class="lnr-warning">&nbsp;</i>Lỗi
          </ModalHeader>
          <ModalBody>{this.state.error}</ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default ScheduledJobHistory;
