import React, { Fragment, Suspense } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, CardHeader, ModalBody, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import PortfolioService from '../../../../services/portfolio.service';
import PortfolioTransactionService from '../../../../services/portfolio.transaction.service';
import AddMoneyModal from './AddMoneyModal';
import SellCertificateModal from './SellCertificateModal';
import AddUserModal from './AddUserModal';
import UpdateDepositAndWithdrawModal from './UpdateDepositAndWithdrawModal';
import CensoredInfo from '../../../../common/censored-info';
import PrivateRoute from '../../../../common/private-route'
import AssetHistory from '../AssetHistory';
import { authRoles } from "../../../../utils/constants";

class PortfolioCCQ extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ccq: null,
            addMoneyUserId: '',
            addMoneyUserName: '',
            addMoneyModalOpen: false,
            error: '',
            errorModalOpen: false,
            buyCertificateModalOpen: false,
            buyCertificateUserId: '',
            buyCertificateUserName: '',
            sellCertificateModalOpen: false,
            sellCertificateUserId: '',
            sellCertificateUserName: '',
            sellCertificateMaxQuantity: 0,
            addUserModalOpen: false,
            updateDepositAndWithdrawUserId: '',
            updateDepositAndWithdrawUserName: '',
            updateDepositAndWithdrawModalOpen: false,
            userInfos: null,
            isLoading: false
        };
        this.loadCCQInfos = this.loadCCQInfos.bind(this);
        this.addMoneyForUser = this.addMoneyForUser.bind(this);
        this.buyCertificate = this.buyCertificate.bind(this);
        this.sellCertificate = this.sellCertificate.bind(this);
        this.addUser = this.addUser.bind(this);
        this.updateDepositAndWithdraw = this.updateDepositAndWithdraw.bind(this);
    }

    componentDidMount() {
        this.loadCCQInfos();
    }

    loadCCQInfos() {
        let { match } = this.props;
        new PortfolioService().ccqDetails(match.params.portfolioId).then(
            response => {
                this.setState({
                    ccq: response.data.ccq,
                    userInfos: response.data.userInfos
                });
            },
            error => {
                this.setState({
                    error: error.message
                })
            }
        );
    }

    openAddMoneyModal(userId, userName) {
        this.setState({
            addMoneyUserId: userId,
            addMoneyUserName: userName,
            addMoneyModalOpen: true
        });
    }

    openBuyCertificateMoneyModal(userId, userName) {
        this.setState({
            buyCertificateModalOpen: true,
            buyCertificateUserId: userId,
            buyCertificateUserName: userName
        });
    }

    openSellCertificateMoneyModal(userId, userName, maxQuantity) {
        if (maxQuantity <= 0) {
            this.setState({
                errorModalOpen: true,
                error: 'Người dùng không có CCQ để bán'
            });
        } else {
            this.setState({
                sellCertificateModalOpen: true,
                sellCertificateUserId: userId,
                sellCertificateUserName: userName,
                sellCertificateMaxQuantity: maxQuantity
            });
        }
    }

    openUpdateDepositAndWithdrawModal(userId, userName, deposit, withdraw) {
        this.setState({
            updateDepositAndWithdrawModalOpen: true,
            updateDepositAndWithdrawUserId: userId,
            updateDepositAndWithdrawUserName: userName,
            updateDepositAndWithdrawTotalDeposit: deposit,
            updateDepositAndWithdrawTotalWithdraw: withdraw
        });
    }

    toggleAddMoneyModal() {
        this.setState({ addMoneyModalOpen: !this.state.addMoneyModalOpen });
    }

    toggleErrorModal() {
        this.setState({ errorModalOpen: !this.state.errorModalOpen });
    }

    toggleBuyCertificateModal() {
        this.setState({ buyCertificateModalOpen: !this.state.buyCertificateModalOpen });
    }

    toggleSellCertificateModal() {
        this.setState({ sellCertificateModalOpen: !this.state.sellCertificateModalOpen });
    }

    toggleAddUserModal() {
        this.setState({ addUserModalOpen: !this.state.addUserModalOpen });
    }

    toggleUpdateDepositAndWithdrawModal() {
        this.setState({ updateDepositAndWithdrawModalOpen: !this.state.updateDepositAndWithdrawModalOpen });
    }

    addMoneyForUser(request) {
        this.setState({
            isLoading: true
        });
        new PortfolioTransactionService().addMoneyForUser(request).then(
            response => {
                if (response.data.success) {
                    this.props.updateOverview();
                    this.loadCCQInfos();
                    this.setState({
                        errorModalOpen: false,
                        addMoneyModalOpen: false,
                        addMoneyUserId: '',
                        addMoneyUserName: '',
                        isLoading: false
                    });
                } else {
                    this.setState({
                        error: response.data.message,
                        errorModalOpen: true,
                        addMoneyModalOpen: false,
                        isLoading: false
                    });
                }
            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    addMoneyModalOpen: false,
                    isLoading: false
                });
            }
        );
    }

    buyCertificate() {
        this.setState({
            isLoading: true
        });
        let request = {
            portfolioId: this.props.match.params.portfolioId,
            userId: this.state.buyCertificateUserId
        };
        new PortfolioTransactionService().buyCertificate(request).then(
            response => {
                if (response.data.success) {
                    this.props.updateOverview();
                    this.loadCCQInfos();
                    this.setState({
                        errorModalOpen: false,
                        buyCertificateModalOpen: false,
                        buyCertificateUserId: '',
                        buyCertificateUserName: '',
                        isLoading: false
                    });
                } else {
                    this.setState({
                        error: response.data.message,
                        errorModalOpen: true,
                        buyCertificateModalOpen: false,
                        isLoading: false
                    });
                }

            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    buyCertificateModalOpen: false,
                    isLoading: false
                });
            }
        );
    }

    sellCertificate(request) {
        this.setState({
            isLoading: true
        });
        new PortfolioTransactionService().sellCertificate(request).then(
            response => {
                if (response.data.success) {
                    this.props.updateOverview();
                    this.loadCCQInfos();
                    this.setState({
                        errorModalOpen: false,
                        sellCertificateModalOpen: false,
                        sellCertificateUserId: '',
                        sellCertificateUserName: '',
                        isLoading: false
                    });
                } else {
                    this.setState({
                        error: response.data.message,
                        errorModalOpen: true,
                        sellCertificateModalOpen: false,
                        isLoading: false
                    });
                }

            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    sellCertificateModalOpen: false,
                    isLoading: false
                });
            }
        );
    }

    addUser(request) {
        this.setState({
            isLoading: true
        });
        new PortfolioTransactionService().addUser(request).then(
            response => {
                if (response.data.success) {
                    this.props.updateOverview();
                    this.loadCCQInfos();
                    this.setState({
                        errorModalOpen: false,
                        addUserModalOpen: false,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        error: response.data.message,
                        errorModalOpen: true,
                        addUserModalOpen: false,
                        isLoading: false
                    });
                }
            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    addUserModalOpen: false,
                    isLoading: false
                });
            }
        );
    }

    updateDepositAndWithdraw(request) {
        this.setState({
            isLoading: true
        });
        new PortfolioTransactionService().updateDepositAndWithdraw(request).then(
            response => {
                if (response.data.success) {
                    this.props.updateOverview();
                    this.loadCCQInfos();
                    this.setState({
                        errorModalOpen: false,
                        updateDepositAndWithdrawModalOpen: false,
                        updateDepositAndWithdrawUserId: '',
                        updateDepositAndWithdrawUserName: '',
                        isLoading: false
                    });
                } else {
                    this.setState({
                        error: response.data.message,
                        errorModalOpen: true,
                        updateDepositAndWithdrawModalOpen: false,
                        isLoading: false
                    });
                }
            },
            error => {
                this.setState({
                    error: error.message,
                    errorModalOpen: true,
                    updateDepositAndWithdrawModalOpen: false,
                    isLoading: false
                });
            }
        );
    }

    render() {
        const { censored, match } = this.props;
        let { ccq, addMoneyUserId, addMoneyUserName, addMoneyModalOpen, userInfos, addUserModalOpen,
            sellCertificateUserId, isLoading, sellCertificateModalOpen, sellCertificateUserName, sellCertificateMaxQuantity,
            updateDepositAndWithdrawUserId, updateDepositAndWithdrawUserName, updateDepositAndWithdrawModalOpen, updateDepositAndWithdrawTotalDeposit, updateDepositAndWithdrawTotalWithdraw } = this.state;
        return (
            <Fragment>
                <Row>
                    {isLoading ? (
                        <div className="loader-container">
                            <div className="loader-container-inner">
                                <h6 className="mt-5">
                                    Hệ thống đang xử lý ...
                                </h6>
                            </div>
                        </div>
                    ) : (
                        <Col lg="12">
                            <Card className="main-card mb-3">
                                <CardHeader>
                                    <div className="card-header-title">
                                        <i className="header-icon lnr-user icon-gradient bg-plum-plate"> </i>
                                        Chi Tiết CCQ
                                    </div>
                                    <div class="btn-actions-pane-right">
                                        <button class="btn btn-primary mb-2 me-2" onClick={() => this.toggleAddUserModal()}>Thêm Người Dùng</button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div class="table-responsive">
                                        <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">Người Dùng</th>
                                                    <th class="text-center">Số lượng CCQ</th>
                                                    <th class="text-center">Tiền chưa phân phối</th>
                                                    <th class="text-center">Tổng Nạp</th>
                                                    <th class="text-center">Tổng Rút</th>
                                                    <th class="text-center">Ngày cuối giao dịch</th>
                                                    <th class="text-center">...</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ccq && ccq.map(c => (
                                                    <tr>
                                                        <td class="text-center">
                                                            {c.userName}
                                                        </td>
                                                        <td class="text-center">
                                                            <CensoredInfo content={c.numberOfCertificates} censored={censored} /> {c.userName === 'Fee' ? '(dự kiến)' : ''}
                                                        </td>
                                                        <td class="text-center">
                                                            <CensoredInfo content={c.cash} censored={censored} />
                                                        </td>
                                                        <td class="text-center">
                                                            <CensoredInfo content={c.totalDeposit} censored={censored} />
                                                        </td>
                                                        <td class="text-center">
                                                            <CensoredInfo content={c.totalWithdraw} censored={censored} />
                                                        </td>
                                                        <td class="text-center">
                                                            {c.lastUpdated}
                                                        </td>
                                                        <td class="text-center">
                                                            <button class="btn btn-primary mb-2 me-2" onClick={() => this.openAddMoneyModal(c.userId, c.userName)}>Nạp</button>
                                                            <button class="btn btn-primary mb-2 me-2" onClick={() => this.openBuyCertificateMoneyModal(c.userId, c.userName)}>Mua CCQ</button>
                                                            <button class="btn btn-primary mb-2 me-2" onClick={() => this.openSellCertificateMoneyModal(c.userId, c.userName, c.numberOfCertificates)}>Bán CCQ</button>
                                                            <button class="btn btn-primary mb-2 me-2" onClick={() => this.openUpdateDepositAndWithdrawModal(c.userId, c.userName, c.totalDepositValue, c.totalWithdrawValue)}>Cập Nhật Tổng Rút Và Nạp</button>
                                                            {c.userName !== 'Fee' && (
                                                                <NavLink className="btn btn-primary mb-2 me-2" to={`${match.url}/${c.userId}/assethistories`}>Lịch Sử Tài Sản</NavLink>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <AddMoneyModal handleSubmit={this.addMoneyForUser} userId={addMoneyUserId} userName={addMoneyUserName} portfolioId={this.props.match.params.portfolioId} toggle={() => this.toggleAddMoneyModal()} open={addMoneyModalOpen} />
                                    <AddUserModal handleSubmit={this.addUser} userInfos={userInfos} portfolioId={this.props.match.params.portfolioId} toggle={() => this.toggleAddUserModal()} open={addUserModalOpen} />
                                    <Modal isOpen={this.state.buyCertificateModalOpen} fade={false} toggle={() => this.toggleBuyCertificateModal()}>
                                        <ModalHeader toggle={() => this.toggleBuyCertificateModal()}>Xác Nhận</ModalHeader>
                                        <ModalBody>
                                            Bạn có chắc muốn mua CCQ cho người dùng <strong>{this.state.buyCertificateUserName}</strong> ?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button class="btn btn-primary mb-2 me-2" onClick={this.buyCertificate}>Có</button>
                                            <button class="btn btn-secondary mb-2 me-2" onClick={() => this.toggleBuyCertificateModal()}>Không</button>
                                        </ModalFooter>
                                    </Modal>
                                    <SellCertificateModal handleSubmit={this.sellCertificate} userId={sellCertificateUserId} userName={sellCertificateUserName} portfolioId={this.props.match.params.portfolioId} toggle={() => this.toggleSellCertificateModal()} open={sellCertificateModalOpen} maxQuantity={sellCertificateMaxQuantity} />
                                    <UpdateDepositAndWithdrawModal
                                        totalDeposit={updateDepositAndWithdrawTotalDeposit}
                                        totalWithdraw={updateDepositAndWithdrawTotalWithdraw}
                                        handleSubmit={this.updateDepositAndWithdraw}
                                        userId={updateDepositAndWithdrawUserId}
                                        userName={updateDepositAndWithdrawUserName}
                                        portfolioId={this.props.match.params.portfolioId}
                                        toggle={() => this.toggleUpdateDepositAndWithdrawModal()}
                                        open={updateDepositAndWithdrawModalOpen} />
                                    <Modal isOpen={this.state.errorModalOpen} fade={false} toggle={() => this.toggleErrorModal()}>
                                        <ModalHeader className='text-danger' toggle={() => this.toggleErrorModal()}><i class="lnr-warning">&nbsp;</i>Lỗi</ModalHeader>
                                        <ModalBody>
                                            {this.state.error}
                                        </ModalBody>
                                    </Modal>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                </Row>
            </Fragment>
        );
    }
}

export default withRouter(PortfolioCCQ);
