import axios from 'axios';
import authHeader from './auth-header';
const API_URL = "/api/settings/";
export default class SettingsService {
    getcurrent(){
        return axios.get(API_URL + "getcurrentservice", {headers: authHeader()});
    }

    update(service){
        return axios.post(API_URL + "updateservice?service=" + service, {}, {headers: authHeader()});
    }
}