import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText
} from 'reactstrap';

class ApproveDepositRequestModal extends React.Component {
    constructor(props){
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        exchangeRate: 0,
        exchangeFee: 0
    };

    closeModal(){
        this.setState(this.initialState);
        this.props.toggle();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(e){
        e.preventDefault();
        if (e.target.checkValidity()) {
            const request = {
                requestId : this.props.requestId,
                exchangeRate: this.state.exchangeRate,
                exchangeFee: this.state.exchangeFee
            };
            this.props.handleSubmit(request);
            this.setState(this.initialState);
        }
    }

    render(){
        const {open, toggle, requestValue, requestCurrency, requestImage, requestUserName, requestId } = this.props;
        let { exchangeRate, exchangeFee } = this.state;
        return (
            <Modal isOpen={open} fade={false} toggle={toggle}>
                <ModalHeader toggle={toggle}>Duyệt Yêu Cầu Nạp Tiền</ModalHeader>
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <ModalBody>
                        <FormGroup>
                            <Label>Người Dùng</Label>
                            <Input readOnly type="text" id="requestUserName" name="requestUserName" value={requestUserName} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="value">Giá Trị</Label>
                            <InputGroup>
                                <Input readOnly value={requestValue} required type="number" id="requestValue" min="0" name="requestValue" />
                                <InputGroupText>{requestCurrency === 0 ? 'đ' : '¥'}</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label>Hình Ảnh</Label> <br />
                            <a href={requestImage} target="_blank">
                                <img src={requestImage} class="img-fluid" />
                            </a>
                        </FormGroup>
                        {requestCurrency === 1 && (
                            <>
                                <FormGroup>
                                    <Label for="exchangeRate">Mức Chuyển Đổi</Label>
                                    <InputGroup>
                                        <Input onChange={this.handleInputChange} value={exchangeRate} required type="number" id="exchangeRate" min="1" step=".01" name="exchangeRate" />
                                        <InputGroupText>đ / ¥</InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="exchangeFee">Phí Chuyển Đổi</Label>
                                    <InputGroup>
                                        <Input onChange={this.handleInputChange} value={exchangeFee} required type="number" step=".01" id="exchangeFee" min="0" max="1" name="exchangeFee" />
                                        <InputGroupText>%</InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                            </>
                        )}
                        <Input type='hidden' value={requestId} name="requestId" />
                        <ModalFooter>
                            <button class="btn btn-primary mb-2 me-2" type="submit">Duyệt</button>
                            <button class="btn btn-secondary mb-2 me-2" type="button" onClick={() => this.closeModal()}>Thoát</button>
                        </ModalFooter>
                    </ModalBody>
                </Form>
            </Modal>
        );
    }
}

export default ApproveDepositRequestModal;