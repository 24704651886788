import React, { Fragment } from "react";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { NavLink, Redirect } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  ModalBody,
  Modal,
  ModalHeader,
} from "reactstrap";
import UserService from "../../../services/user.service";
import ChangePhoneNumberModal from "./ChangePhoneNumberModal";
import ChangeUserNameModal from "./ChangeUserNameModal";
import ChangePasswordModal from "./ChangePasswordModal";
import ChangeEmailModal from "./ChangeEmailModal";
import { faL } from "@fortawesome/free-solid-svg-icons";
import BankAccountListing from "./BankAccount/Listing";
import EmergencyContactListing from "./EmergencyContact/Listing";
import { ToastContainer, toast, Bounce } from "react-toastify";
import { authRoles } from "../../../utils/constants";
import { parseJwt } from "../../../common/utils";

class UserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: null,
      changePhoneNumberModelOpen: false,
      changeUsernameModelOpen: false,
      changePasswordModelOpen: false,
      changeEmailModelOpen: false,
      errorModalOpen: false,
      error: "",
    };

    this.toggleUserStatus = this.toggleUserStatus.bind(this);

    this.changePhoneNumber = this.changePhoneNumber.bind(this);
    this.toggleChangePhoneNumberModal =
      this.toggleChangePhoneNumberModal.bind(this);

    this.changePassword = this.changePassword.bind(this);
    this.toggleChangePasswordModal = this.toggleChangePasswordModal.bind(this);

    this.changeUsername = this.changeUsername.bind(this);
    this.toggleChangeUserNameModal = this.toggleChangeUserNameModal.bind(this);

    this.changeEmail = this.changeEmail.bind(this);
    this.toggleChangeEmailModal = this.toggleChangeEmailModal.bind(this);
  }

  notifySucess = (text) =>
    (this.toastId = toast(text, {
      transition: Bounce,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-center",
      type: "success",
    }));

  componentDidMount() {
    let isAdmin = false;
    let user = JSON.parse(localStorage.user);
    if (user && user.token) {
      const identity = parseJwt(user.token);
      if (identity && identity.role === authRoles.ADMIN) {
        isAdmin = true;
      }
    }

    let { match } = this.props;

    if (!isAdmin && match.params.userId != "me")
      return <Redirect to={`/user/me`} />;

    new UserService().detail(match.params.userId).then(
      (response) => {
        this.setState({
          userDetail: response.data,
        });
      },
      (error) => {
        this.setState({
          error: error.message,
        });
      }
    );
  }

  toggleUserStatus(id) {
    new UserService().togglestatus(id).then(
      (response) => {
        this.notifySucess("Change user status successfully!");
        this.setState((prevState) => {
          let userDetail = Object.assign({}, prevState.userDetail);
          userDetail.isActive = !userDetail.isActive;
          return { userDetail };
        });
      },
      (error) => {
        this.setState({
          error: error.message + ":" + error.response.data,
        });
      }
    );
  }

  toggleErrorModal() {
    this.setState({ errorModalOpen: !this.state.errorModalOpen });
  }

  toggleChangePhoneNumberModal() {
    this.setState({
      changePhoneNumberModelOpen: !this.state.changePhoneNumberModelOpen,
    });
  }

  toggleChangeUserNameModal() {
    this.setState({
      changeUsernameModelOpen: !this.state.changeUsernameModelOpen,
    });
  }

  toggleChangePasswordModal() {
    this.setState({
      changePasswordModelOpen: !this.state.changePasswordModelOpen,
    });
  }

  toggleChangeEmailModal() {
    this.setState({
      changeEmailModelOpen: !this.state.changeEmailModelOpen,
    });
  }

  changePhoneNumber(request) {
    new UserService().changephonenumber(request).then(
      (response) => {
        this.notifySucess("Change phone number successfully!");
        this.setState((prevState) => {
          let userDetail = Object.assign({}, prevState.userDetail);
          userDetail.phoneNumber = request.phoneNumber;
          return { userDetail };
        });
        this.setState({
          changePhoneNumberModelOpen: false,
        });
      },
      (error) => {
        this.setState({
          error: error.message + ":" + error.response.data,
          errorModalOpen: true,
          changePhoneNumberModelOpen: false,
        });
      }
    );
  }

  changeUsername(request) {}

  changePassword(request) {
    new UserService().changepassword(request).then(
      (response) => {
        this.notifySucess("Change password successfully!");
        this.setState({
          changePasswordModelOpen: false,
        });
      },
      (error) => {
        this.setState({
          error: error.message + ":" + error.response.data,
          errorModalOpen: true,
          changePasswordModelOpen: false,
        });
      }
    );
  }
  changeEmail(request) {
    new UserService().changeemail(request).then(
      (response) => {
        this.notifySucess("Change email successfully!");
        this.setState((prevState) => {
          let userDetail = Object.assign({}, prevState.userDetail);
          userDetail.email = request.email;
          return { userDetail };
        });
        this.setState({
          changeEmailModelOpen: false,
        });
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
          changeEmailModelOpen: false,
        });
      }
    );
  }

  render() {
    let { match } = this.props;
    let {
      userDetail,
      changePhoneNumberModelOpen,
      changeUsernameModelOpen,
      changePasswordModelOpen,
      changeEmailModelOpen,
    } = this.state;

    return (
      <Fragment>
        <PageTitle
          heading="Thông tin người dùng"
          icon="pe-7s-portfolio icon-gradient bg-amy-crisp"
        />
        <Row>
          <Col className="col-8">
            <Card>
              <div class="widget-content">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left mr-3">
                          <div class="widget-content-left">
                            <img
                              width="120"
                              class="rounded-circle"
                              src="default-user.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="widget-content-left flex2">
                          <div class="widget-heading">
                            <h3>{userDetail != null && userDetail.username}</h3>
                          </div>
                          <div class="widget-subheading opacity-7">
                            {userDetail != null && userDetail.roleName}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <Button color="primary">
                      <i className="lnr-file-add"> </i>
                      Đổi ảnh đại diện
                    </Button>
                  </div>
                </div>
              </div>
              <div class="widget-content">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Tên đăng nhập</div>
                    <div class="widget-subheading">
                      {userDetail != null && userDetail.username}
                    </div>
                  </div>
                  <div class="widget-content-right"></div>
                </div>
              </div>
              <div class="widget-content">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Email</div>
                    <div class="widget-subheading">
                      {userDetail != null && userDetail.email}
                    </div>
                  </div>
                  <div class="widget-content-right"></div>
                </div>
              </div>
              <div class="widget-content">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Số điện thoại</div>
                    <div class="widget-subheading">
                      {userDetail != null && userDetail.phoneNumber != null
                        ? userDetail.phoneNumber
                        : ""}
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <button
                      class="btn btn-secondary mb-2 me-2"
                      onClick={() => this.toggleChangePhoneNumberModal()}
                    >
                      <i className="pe-7s-note"> </i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="widget-content" style={{display:'none'}}>
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Tình trạng</div>
                    <div class="widget-subheading">
                      {userDetail != null && userDetail.isActive ? (
                        <div className="badge bg-success">Đang hoạt động</div>
                      ) : (
                        <div className="badge bg-warning">Chưa hoạt động</div>
                      )}
                    </div>
                  </div>
                  <div class="widget-content-right">
                    {match.params.userId == "me" ? (
                      ""
                    ) : userDetail != null && userDetail.isActive ? (
                      <button
                        class="btn btn-warning mb-2 me-2"
                        onClick={() => this.toggleUserStatus(userDetail.id)}
                      >
                        <i className="pe-7s-close"> </i>
                      </button>
                    ) : (
                      <button
                        class="btn btn-success mb-2 me-2"
                        onClick={() => this.toggleUserStatus(userDetail.id)}
                      >
                        <i className="pe-7s-check"> </i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col className="col-8 mt-2">
            <Card>
              <CardHeader>
                <div className="card-header-title">
                  <i className="header-icon lnr-history icon-gradient bg-plum-plate"></i>
                  Mật khẩu và xác thực
                </div>
              </CardHeader>
              <CardBody>
                <button
                  class="btn btn-primary mb-2 me-2"
                  onClick={() => this.toggleChangePasswordModal()}
                >
                  <i className="pe-7s-note"> </i> Đổi mật khẩu
                </button>
              </CardBody>
            </Card>
          </Col>
          <Col className="col-8 mt-2">
            <EmergencyContactListing userId={match.params.userId} />
          </Col>
          <Col className="col-8 mt-2">
            <BankAccountListing userId={match.params.userId} />
          </Col>
        </Row>
        <ChangePhoneNumberModal
          handleSubmit={this.changePhoneNumber}
          open={changePhoneNumberModelOpen}
          userId={match.params.userId}
          phoneNumber={userDetail != null && userDetail.phoneNumber}
          toggle={() => this.toggleChangePhoneNumberModal()}
        />
        <ChangeUserNameModal
          handleSubmit={this.changeUsername}
          open={changeUsernameModelOpen}
          userId={match.params.userId}
          username={userDetail != null && userDetail.username}
          toggle={() => this.toggleChangeUserNameModal()}
        />
        <ChangePasswordModal
          handleSubmit={this.changePassword}
          open={changePasswordModelOpen}
          userId={match.params.userId}
          toggle={() => this.toggleChangePasswordModal()}
        />
        <ChangeEmailModal
          handleSubmit={this.changeEmail}
          open={changeEmailModelOpen}
          userId={match.params.userId}
          email={userDetail != null && userDetail.email}
          toggle={() => this.toggleChangeEmailModal()}
        />
        <Modal
          isOpen={this.state.errorModalOpen}
          fade={false}
          toggle={() => this.toggleErrorModal()}
        >
          <ModalHeader
            className="text-danger"
            toggle={() => this.toggleErrorModal()}
          >
            <i class="lnr-warning">&nbsp;</i>Lỗi
          </ModalHeader>
          <ModalBody>{this.state.error}</ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default UserDetail;
