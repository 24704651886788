import React, { Fragment } from "react";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import RoleService from "../../../services/role.service";

class RoleListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      error: "",
      errorModalOpen: false,
      roleName: "",
    };

    this.loadRoles = this.loadRoles.bind(this);
    this.deleteRole = this.deleteRole.bind(this);
    this.createRole = this.createRole.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  loadRoles() {
    new RoleService().list().then(
      (response) => {
        this.setState({
          roles: response.data,
          error: "",
        });
      },
      (error) => {
        this.setState({
          roles: [],
          error: error.message,
          errorModalOpen: true,
        });
      }
    );
  }

  createRole(e) {
    e.preventDefault();
    new RoleService().create(this.state.roleName).then(
      (response) => {
        this.loadRoles();
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
        });
      }
    );
    this.setState({
      roleName: "",
    });
  }

  deleteRole(id) {
    new RoleService().delete(id).then(
      (response) => {
        this.setState({
          roles: this.state.roles.filter((role) => role.id !== id),
        });
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
        });
      }
    );
  }

  componentDidMount() {
    this.loadRoles();
  }

  toggleErrorModal() {
    this.setState({ errorModalOpen: !this.state.errorModalOpen });
  }

  handleInputChange(event) {
    const target = event.target;
    this.setState({
      roleName: target.value,
    });
  }

  render() {
    let { match } = this.props;
    return (
      <Fragment>
        <PageTitle
          heading="Danh sách chức vụ"
          icon="pe-7s-portfolio icon-gradient bg-amy-crisp"
        />
        <Row>
          <Col className="col-12">
            <Card>
              <CardHeader>
                <div className="card-header-title">Danh mục</div>
                <div className="btn-actions-pane-right">
                  <Form onSubmit={(e) => this.createRole(e)}>
                    <div class="input-group position-relative mr-2">
                      <Input
                        onChange={this.handleInputChange}
                        name="roleName"
                        type="text"
                        className="form-control rounded-10"
                      />
                      <div class="input-group-append">
                        <Button className="btn btn-primary" type="submit">
                          <span class="btn-icon-wrapper pr-2 opacity-7">
                            <i class="fa fa-plus fa-w-20"></i>
                          </span>
                          Thêm
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </CardHeader>
              <CardBody>
                <div class="table-responsive">
                  <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                      <tr>
                        <th class="text-center">#</th>
                        <th>Tên</th>
                        <th class="text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.roles &&
                        this.state.roles.map((role, i) => {
                          return (
                            <tr>
                              <td class="text-center" scope="row">
                                {i + 1}
                              </td>
                              <td class="text-center">
                                <NavLink to={`${match.url}/` + role.id}>
                                  {role.name}
                                </NavLink>
                              </td>
                              <td class="text-center">
                                <button
                                  class="btn btn-danger mb-2 me-2"
                                  onClick={() => this.deleteRole(role.id)}
                                >
                                  <i className="lnr-trash"> </i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.errorModalOpen}
          fade={false}
          toggle={() => this.toggleErrorModal()}
        >
          <ModalHeader
            className="text-danger"
            toggle={() => this.toggleErrorModal()}
          >
            <i class="lnr-warning">&nbsp;</i>Lỗi
          </ModalHeader>
          <ModalBody>{this.state.error}</ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default RoleListing;
