import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText, Card, CardHeader, CardBody
} from 'reactstrap';
import PortfolioService from '../../../../services/portfolio.service';

class ApproveWithdrawRequestModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initialState = {
        withdrawFeeRate: 0,
        withdrawValue: 0,
        withdrawUserName: 0,
        ccqUserInfo: null
    };

    componentDidMount() {
        this.loadWithdrawRequestInfo(this.props.requestId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.requestId != this.props.requestId) {
            const requestId = this.props.requestId;
            if (requestId === '') {
                this.setState(this.initialState)
            } else {
                this.loadWithdrawRequestInfo(requestId);
            }
        }
    }

    loadWithdrawRequestInfo(requestId) {
        if (requestId !== '') {
            new PortfolioService().loadWithdrawRequestInfo(requestId).then(
                response => {
                    if (response.data) {
                        this.setState({
                            withdrawValue: response.data.value,
                            withdrawUserName: response.data.userName,
                            withdrawFeeRate: response.data.withdrawFeeRate,
                            ccqUserInfo: response.data.ccqUserInfo
                        });
                    }
                },
                error => {
                    this.props.toggle();
                }
            );
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            const request = {
                requestId: this.props.requestId,
                withdrawFeeRate: this.state.withdrawFeeRate
            };
            this.props.handleSubmit(request);
            this.setState(this.initialState);
        }
    }

    render() {
        const { requestId, open, toggle } = this.props;
        let { withdrawFeeRate, withdrawValue, withdrawUserName, ccqUserInfo } = this.state;
        return (
            <Modal isOpen={open} fade={false} toggle={toggle}>
                <ModalHeader toggle={toggle}>Duyệt Yêu Cầu Rút Tiền</ModalHeader>
                <ModalBody>
                    <div class="row">
                        <div class="col-md-12">
                            <Form onSubmit={(e) => this.handleSubmit(e)}>
                                <FormGroup>
                                    <Label>Người Dùng</Label>
                                    <Input readOnly type="text" id="withdrawUserName" name="withdrawUserName" value={withdrawUserName} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="withdrawFeeRate">Giá Trị</Label>
                                    <InputGroup>
                                        <Input readOnly value={withdrawValue} required type="number" id="withdrawValue" min="0" name="withdrawValue" />
                                        <InputGroupText>đ</InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="withdrawFeeRate">Phí Rút Tiền (%)</Label>
                                    <InputGroup>
                                        <Input value={withdrawFeeRate} onChange={this.handleInputChange} required type="number" id="withdrawFeeRate" min="0" name="withdrawFeeRate" />
                                        <InputGroupText>%</InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="withdrawFeeRate">Phí Rút Tiền (đ)</Label>
                                    <InputGroup>
                                        <Input readOnly value={withdrawFeeRate * withdrawValue / 100} required type="number" id="withdrawFeeValue" min="0" name="withdrawFeeValue" />
                                        <InputGroupText>đ</InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                                <Input type='hidden' value={requestId} name="requestId" />
                                <ModalFooter>
                                    <button class="btn btn-primary mb-2 me-2" type="submit">Duyệt</button>
                                    <button class="btn btn-secondary mb-2 me-2" type="button" onClick={toggle}>Thoát</button>
                                </ModalFooter>
                            </Form>
                        </div>
                        {ccqUserInfo !== null && (
                            <div class="col-md-12">
                                <Card>
                                    <CardHeader>Thông Tin CCQ User</CardHeader>
                                    <CardBody>
                                        <div class="table-responsive">
                                            <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Thông Tin</th>
                                                        <th>Giá Trị</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>NAVCCQ sau phí</td>
                                                        <td>{ccqUserInfo.navccqAfterFee}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Số CCQ</td>
                                                        <td>{ccqUserInfo.userNumberOfCertificates}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tiền Chưa Phân Phối</td>
                                                        <td>{ccqUserInfo.userCash}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tổng Tài Sản</td>
                                                        <td>{ccqUserInfo.userTotalValue}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        )}
                    </div>
                </ModalBody>
            </Modal>);
    }
}

export default ApproveWithdrawRequestModal;