export const constantsCashSources = {
  CASH: "Tiền chưa phân phối",
};

export const authRoles = {
  ADMIN: "admin",
};

export const authPermissions = {
  User: {
    Admin: "Permissions.User.Admin",
    MyProfile: "Permissions.User.MyProfile",
    List: "Permissions.User.List",
  },
  SupportTicket: {
    List: "Permissions.SupportTicket.List",
    SendEmail: "Permissions.SupportTicket.SendEmail",
  },
  ScheduledJob: {
    List: "Permissions.ScheduledJob.List",
  },
  Portfolio: {
    List: "Permissions.Portfolio.List",
    MyPortfolio: "Permissions.Portfolio.MyPortfolio",
  },
  LinksList: {
    List: "Permissions.LinksList.List",
    Edit: "Permissions.LinksList.Edit",
  },
};
