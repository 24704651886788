import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Collapse,
  CardHeader,
  ModalBody,
  Modal,
  ModalHeader,
} from "reactstrap";
import UserService from "../../../../../services/user.service";
import AddUserEmergencyContactModal from "./AddUserEmergencyContactModal";
import DeleteUserEmergencyContactModal from "./DeleteUserEmergencyContactModal";

class EmergencyContactListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmergencyContacts: [],
      error: "",
      addUserEmergencyContactModelOpen: false,
      deleteEmergencyContactModelOpen: false,
      errorModalOpen: false,
      emergencyContactToBeDeleted: "",
    };

    this.loadUserEmergencyContacts = this.loadUserEmergencyContacts.bind(this);
    this.createUserEmergencyContact =
      this.createUserEmergencyContact.bind(this);
    this.toggleAddUserEmergencyContactModal =
      this.toggleAddUserEmergencyContactModal.bind(this);

    this.deleteEmergencyContact = this.deleteEmergencyContact.bind(this);
    this.toggleDeleteUserEmergencyContactModal =
      this.toggleDeleteUserEmergencyContactModal.bind(this);
  }

  loadUserEmergencyContacts(userId) {
    new UserService().listEmergencyContacts(userId).then(
      (response) => {
        this.setState({
          userEmergencyContacts: response.data,
          error: "",
        });
      },
      (error) => {
        this.setState({
          userEmergencyContacts: [],
          error: error.message,
        });
      }
    );
  }

  createUserEmergencyContact(request) {
    new UserService().createEmergencyContact(request).then(
      (response) => {
        this.loadUserEmergencyContacts(request.userId);
        this.setState({
          addUserEmergencyContactModelOpen: false,
        });
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
          addUserEmergencyContactModelOpen: false,
        });
      }
    );
  }

  componentDidMount() {
    let { userId } = this.props;
    this.loadUserEmergencyContacts(userId);
  }

  toggleAddUserEmergencyContactModal() {
    this.setState({
      addUserEmergencyContactModelOpen:
        !this.state.addUserEmergencyContactModelOpen,
    });
  }

  toggleErrorModal() {
    this.setState({ errorModalOpen: !this.state.errorModalOpen });
  }

  deleteEmergencyContact(id, fullName) {
    new UserService().deleteEmergencyContact(id, fullName).then(
      (response) => {
        this.setState({
          userEmergencyContacts: this.state.userEmergencyContacts.filter(
            (contact) => contact.fullName !== fullName
          ),
          deleteEmergencyContactModelOpen: false,
        });
      },
      (error) => {
        this.setState({
          error: error.message,
          errorModalOpen: true,
          deleteEmergencyContactModelOpen: false,
        });
      }
    );
  }

  toggleDeleteUserEmergencyContactModal(fullName) {
    this.setState({
      emergencyContactToBeDeleted: fullName,
      deleteEmergencyContactModelOpen:
        !this.state.deleteEmergencyContactModelOpen,
    });
  }

  render() {
    let { match, userId } = this.props;
    let {
      addUserEmergencyContactModelOpen,
      deleteEmergencyContactModelOpen,
      emergencyContactToBeDeleted,
    } = this.state;
    return (
      <Fragment>
        <Card>
          <CardHeader>
            <div className="card-header-title">Thông tin người thân</div>
            <div className="btn-actions-pane-right">
              <button
                class="btn btn-primary mb-2 me-2"
                onClick={() => this.toggleAddUserEmergencyContactModal()}
              >
                <i className="lnr-file-add"> </i>
                Thêm
              </button>
            </div>
          </CardHeader>
          <CardBody>
            <div class="table-responsive">
              <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                <thead>
                  <tr>
                    <th class="text-center">#</th>
                    <th class="text-center">Họ và tên</th>
                    <th class="text-center">Email</th>
                    <th class="text-center">Số điện thoại</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.userEmergencyContacts &&
                    this.state.userEmergencyContacts.map((contact, i) => {
                      return (
                        <tr>
                          <td class="text-center" scope="row">
                            {i + 1}
                          </td>
                          <td class="text-center">{contact.fullName}</td>
                          <td class="text-center">{contact.email}</td>
                          <td class="text-center">{contact.phoneNumber}</td>
                          <td class="text-center">
                            <button
                              class="btn btn-danger mb-2 me-2"
                              onClick={() =>
                                this.toggleDeleteUserEmergencyContactModal(
                                  contact.fullName
                                )
                              }
                            >
                              <i className="lnr-trash"> </i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
        <AddUserEmergencyContactModal
          handleSubmit={this.createUserEmergencyContact}
          open={addUserEmergencyContactModelOpen}
          toggle={() => this.toggleAddUserEmergencyContactModal()}
          userId={userId}
        />
        <DeleteUserEmergencyContactModal
          handleSubmit={this.deleteEmergencyContact}
          open={deleteEmergencyContactModelOpen}
          fullName={emergencyContactToBeDeleted}
          userId={userId}
          toggle={() => this.toggleDeleteUserEmergencyContactModal("")}
        />
        <Modal
          isOpen={this.state.errorModalOpen}
          fade={false}
          toggle={() => this.toggleErrorModal()}
        >
          <ModalHeader
            className="text-danger"
            toggle={() => this.toggleErrorModal()}
          >
            <i class="lnr-warning">&nbsp;</i>Lỗi
          </ModalHeader>
          <ModalBody>{this.state.error}</ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default EmergencyContactListing;
