import React, { Fragment } from "react";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  ModalBody,
  Modal,
  ModalHeader,
} from "reactstrap";

import ScheduledJobService from "../../../services/scheduledjob.service";
import { NavLink } from "react-router-dom";
class ScheduledJobListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduledJobs: [],
      error: "",
      errorModalOpen: false,
    };

    this.load = this.load.bind(this);
  }

  load() {
    new ScheduledJobService().list().then(
      (response) => {
        this.setState({
          scheduledJobs: response.data,
        });
      },
      (error) => {}
    );
  }

  componentDidMount() {
    this.load();
  }

  render() {
    let { match } = this.props;
    return (
      <Fragment>
        <PageTitle
          heading="Danh sách scheduled jobs"
          icon="pe-7s-portfolio icon-gradient bg-amy-crisp"
        />
        <Row>
          <Col className="col-12">
            <Card>
              <CardHeader>
                <div className="card-header-title">Danh sách</div>
              </CardHeader>
              <CardBody>
                <div class="table-responsive">
                  <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                      <tr>
                        <th class="text-center">#</th>
                        <th>Tên</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.scheduledJobs &&
                        this.state.scheduledJobs.map((scheduledJob, i) => {
                          return (
                            <tr>
                              <td class="text-center" scope="row">
                                {i + 1}
                              </td>
                              <td class="text-center">
                                <NavLink
                                  to={
                                    `${match.url}/` +
                                    scheduledJob.name +
                                    "/form"
                                  }
                                >
                                  {scheduledJob.displayName}
                                </NavLink>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.errorModalOpen}
          fade={false}
          toggle={() => this.toggleErrorModal()}
        >
          <ModalHeader
            className="text-danger"
            toggle={() => this.toggleErrorModal()}
          >
            <i class="lnr-warning">&nbsp;</i>Lỗi
          </ModalHeader>
          <ModalBody>{this.state.error}</ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default ScheduledJobListing;
