import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "/api/auth/";
export default class UserImpersonateService {
  startImpersonating(request) {
    return axios.post(API_URL + "impersonate", request, {
      headers: authHeader(),
    });
  }

  stopImpersonating(request) {
    return axios.post(API_URL + "stop_impersonate", request, {
      headers: authHeader(),
    });
  }
}
