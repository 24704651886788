import React, { Fragment } from "react";
import { Switch, NavLink, withRouter, Redirect } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import PrivateRoute from "../../../common/private-route";
import MarketChart from './Chart';
import MarketData from './Data';
import MarketService from "../../../services/market.service";
import { authRoles } from "../../../utils/constants";

class MarketDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: ''
        };
    }

    componentDidMount() {
        this.loadName();
    }

    loadName(){
        const { match } = this.props;
        if (match.params.marketId !== '') {
            new MarketService().name(match.params.marketId).then(
                response => {
                    if (response.data) {
                        this.setState({
                            name: response.data
                        })
                    }
                },
                error => {
                    this.setState({
                        error: error.message,
                        errorModalOpen: true
                    });
                }
            )
        }
    }

    render() {
        const { match } = this.props;
        const { name } = this.state;
        return (
            <Fragment>
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div
                                className="page-title-icon">
                                <i className="pe-7s-portfolio icon-gradient bg-amy-crisp" />
                            </div>
                            <div>
                                {name}
                            </div>
                        </div>
                        <div className="page-title-actions">
                            <NavLink className="btn btn-primary mb-2 me-2" to={'/market'}>
                                <i className="lnr-arrow-left"></i>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <Nav pills>
                    <NavItem>
                        <NavLink className="nav-link" to={`${match.url}/data`}>
                            Dữ Liệu
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="nav-link" to={`${match.url}/charts`}>
                            Biểu Đồ
                        </NavLink>
                    </NavItem>
                </Nav>
                <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/data`} render={() => (
                    <Redirect to={`${match.url}/data/1`} />
                )} />

                <PrivateRoute roles={[authRoles.ADMIN]} path={`${match.path}/data/:page`} render={(props) => (
                    <MarketData key={props.match.params.page} {...props} />
                )} />
                <PrivateRoute roles={[authRoles.ADMIN]} exact path={`${match.path}/charts`} component={MarketChart} />
                <PrivateRoute roles={[authRoles.ADMIN]} path="/" render={() => (
                    <Redirect to={`${match.url}/data/1`} />
                )} />
            </Fragment>
        );
    }
}

export default withRouter(MarketDetails);